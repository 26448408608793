import React from 'react';
import SvgIcon from '@src/components/SvgIcon';
import {useTranslation} from 'react-i18next';

import styles from './maximum-users-warning.module.scss';

export function MaximumUsersWarning() {
    const {t} = useTranslation();

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>{t('IMPORT_USERS.ATTENTION')}</h3>
            <p className={styles.description}>{t('IMPORT_USERS.MAXIMUM_USERS')}</p>
            <SvgIcon
                name='Notify'
                className={styles.icon}
            />
        </div>
    );
}

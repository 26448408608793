import {apiSlice} from '@src/store/apiSlice';

const accountsDashboardTags = 'ACCOUNTS_DASHBOARD';

export const accountsDashboardApi = apiSlice
    .enhanceEndpoints({addTagTypes: Object.values(accountsDashboardTags)})
    .injectEndpoints({
        endpoints: (builder) => ({
            getUsersCount: builder.query({
                query: ({id}) => ({url: `/accounts/${id}/users/count`}),
                providesTags: (_result, _error, {id}) => [
                    {type: accountsDashboardTags, id},
                ],
            }),
            getFeedbacksCount: builder.query({
                query: ({id}) => ({url: `/accounts/${id}/feedbacks/count`}),
                providesTags: (_result, _error, {id}) => [
                    {type: accountsDashboardTags, id},
                ],
            }),
            getReprimandsCount: builder.query({
                query: ({id}) => ({url: `/accounts/${id}/reprimands/count`}),
                providesTags: (_result, _error, {id}) => [
                    {type: accountsDashboardTags, id},
                ],
            }),
            getCoachingRegistersCount: builder.query({
                query: ({id}) => ({url: `/accounts/${id}/coaching-registers/count`}),
                providesTags: (_result, _error, {id}) => [
                    {type: accountsDashboardTags, id},
                ],
            }),
            getSuspensionsCount: builder.query({
                query: ({id}) => ({url: `/accounts/${id}/suspensions/count`}),
                providesTags: (_result, _error, {id}) => [
                    {type: accountsDashboardTags, id},
                ],
            }),
            getEvaluationsCount: builder.query({
                query: ({id}) => ({url: `/accounts/${id}/evaluations/count`}),
                providesTags: (_result, _error, {id}) => [
                    {type: accountsDashboardTags, id},
                ],
            }),
            getTrainingsCount: builder.query({
                query: ({id}) => ({url: `/accounts/${id}/trainings/count`}),
                providesTags: (_result, _error, {id}) => [
                    {type: accountsDashboardTags, id},
                ],
            }),
            getUsersAccessCount: builder.query({
                query: ({id, from, to, groupBy = 'day'}) => ({
                    url: `/accounts/${id}/sessions/count`,
                    params: {
                        from,
                        to,
                        groupBy,
                    },
                }),
                providesTags: (_result, _error, {id}) => [
                    {type: accountsDashboardTags, id},
                ],
            }),
            getLastActive: builder.query({
                query: ({id}) => ({url: `/accounts/${id}/sessions/lastActive`}),
                providesTags: (_result, _error, {id}) => [
                    {type: accountsDashboardTags, id},
                ],
            }),
            getSectors: builder.query({
                query: ({id}) => ({url: `/accounts/${id}/sectors/count`}),
                providesTags: (_result, _error, {id}) => [
                    {type: accountsDashboardTags, id},
                ],
            }),
        }),
    });

export const {
    useGetUsersCountQuery,
    useGetFeedbacksCountQuery,
    useGetReprimandsCountQuery,
    useGetCoachingRegistersCountQuery,
    useGetSuspensionsCountQuery,
    useGetEvaluationsCountQuery,
    useGetTrainingsCountQuery,
    useGetUsersAccessCountQuery,
    useGetLastActiveQuery,
    useGetSectorsQuery,
} = accountsDashboardApi;

import cx from 'classnames';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import {VideoPlayer as TokyoPlayer} from 'react-tokyo';
import React, {useEffect, useRef, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import {Button, Container, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import {FormikTextField} from '@src/components/FormikFields';
import {convertToConstant, generateCaptionFormData, generateUniqueId} from '@src/modules/trainings/utils';
import {
    invalidateTopics,
    useCreateVideoCaptionMutation,
    useCreateVideoContentMutation,
    useDeleteTopicMutation,
    useDeleteVideoCaptionMutation,
    useEditContentMutation,
    useEditTrainingTopicMutation,
    useLazyGetVideoCaptionsQuery,
} from '@src/modules/trainings/trainings.api';
import {uploadVideo} from '@src/modules/uploads/uploadVideo';
import {topicVideoSchema} from '@src/modules/trainings/schema';
import {ConfirmationModal} from '@src/components/ConfirmationModal';

import {NewCaptionModal} from '../NewCaptionModal';

import styles from './video-edit-form.module.scss';

export function VideoEditForm({
    trainingId,
    content,
    topic,
    setActiveTopic,
}) {
    const [videoUrl, setVideoUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [captionToDelete, setCaptionToDelete] = useState(null);
    const [isCaptionsModalOpen, setIsCaptionsModalOpen] = useState(false);
    const [isDeleteTopicModalOpen, setIsDeleteModalOpen] = useState(false);

    const [editTopic] = useEditTrainingTopicMutation();
    const [createVideo] = useCreateVideoContentMutation();
    const [editVideoContent] = useEditContentMutation();
    const [createCaption] = useCreateVideoCaptionMutation();
    const [deleteCaption, {isLoading: deleteCaptionLoading}] = useDeleteVideoCaptionMutation();
    const [deleteTopic, {isLoading: deleteTopicLoading}] = useDeleteTopicMutation();
    const [getCaptions] = useLazyGetVideoCaptionsQuery();

    const videoRef = useRef(null);
    const inputVideoRef = useRef(null);
    
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const initialValues = {
        title: '',
        video: '',
        captions: [],
    };

    const formik = useFormik({
        validationSchema: topicVideoSchema(t),
        initialValues,
        onSubmit: handleSubmit,
    });

    function addFileInInput({file}) {
        if (file) {
            formik.setFieldValue('video', file);
            const videoUrl = URL.createObjectURL(file);

            setVideoUrl(videoUrl);
        } else {
            formik.setFieldValue('video', '');
            setVideoUrl('');
        }
    }

    async function handleUploadVideo({video, uploadData, setUploadPercentage}) {
        try {
            await uploadVideo({
                video, 
                uploadData,
                setUploadPercentage,
            });
        } catch {
            throw new Error('TUTORIALS_PAGE.UPLOAD_ERROR');
        }
    }
    
    async function handleDeleteCaption(languageToDelete) {
        const existingCaption = languageToDelete.id === languageToDelete.file;
        
        if (existingCaption) {
            await deleteCaption({
                trainingId, 
                topicId: topic.id,
                contentId: content.id,
                captionId: languageToDelete.id,
            }).unwrap();
        } 
        
        const currentCaptions = formik.values.captions;
        const captionsUpdated = currentCaptions.filter(caption => caption.id !== languageToDelete.id);
            
        formik.setFieldValue('captions', captionsUpdated);

        setCaptionToDelete(null);
    }

    function addCaptionItem(caption) {
        const currentCaptions = formik.values.captions;
        const newCaptions = [
            ...currentCaptions,
            {
                id: generateUniqueId(),
                language: caption.language,
                file: caption.file,
            },
        ];

        formik.setFieldValue('captions', newCaptions);
    }

    async function uploadCaptions({captions, trainingId, topicId, contentId, addCaption}) {
        try {
            const captionsPromises = captions.map(async (caption) => {
                const captionsFormData = generateCaptionFormData({file: caption.file, language: caption.language});
    
                return addCaption({
                    trainingId,
                    topicId,
                    contentId,
                    body: captionsFormData,
                }).unwrap();
            });
    
            await Promise.all(captionsPromises);
        } catch {
            throw new Error('TRAININGS_PAGE.CAPTION_ERROR');
        }
    }

    async function handleDeleteTopic() {
        try {
            await deleteTopic({
                trainingId,
                topicId: topic.id,
            }).unwrap();

            enqueueSnackbar(t('TRAININGS_PAGE.DELETE_TOPIC_SUCCESS'), {variant: 'success'});

            invalidateTopics();

            setIsDeleteModalOpen(false);
            setActiveTopic(null);
        } catch (error) {
            enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.DELETE_TOPIC_ERROR'), {variant: 'error'});
        } 
    } 

    function formSubmit(e)  {
        e.preventDefault();
        formik.handleSubmit();
    }

    async function handleSubmit(form) {
        setIsLoading(true);
        setIsEditing(false);
        try {
            const {title, video} = form;

            const topicResponse = await editTopic({
                trainingId,
                title,
                topicId: topic.id,
            }).unwrap();

            let newContent = content;
            
            if (typeof video !== 'string') {
                const videoResponse = await createVideo({
                    trainingId,
                    topicId: topicResponse.id,
                }).unwrap();
                
                await handleUploadVideo({
                    video,
                    uploadData: videoResponse,
                    setUploadPercentage,
                });
                
                newContent = await editVideoContent({
                    trainingId, 
                    topicId: topic.id,
                    contentId: content.id,
                    body: {
                        type: 'video',
                        value: videoResponse.id,
                    },
                }).unwrap();
            }

            const newCaptions = formik.values.captions.filter(caption => caption.id !== caption?.file);

            await uploadCaptions({
                captions: newCaptions,
                trainingId,
                topicId: topic.id,
                contentId: newContent.id,
                addCaption: createCaption,
            });
   
            invalidateTopics();

            enqueueSnackbar(t('TRAININGS_PAGE.EDIT_VIDEO_SUCCESS'), {variant: 'success'});
        } catch (error) {
            enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.EDIT_VIDEO_ERROR'), {variant: 'error'});
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {    
        videoRef.current?.load();
    }, [videoUrl]);

    useEffect(() => {
        if (content) {
            setVideoUrl(content.value);
            formik.setValues({
                title: topic.title,
                video: content.value,
            });

            if (trainingId && topic && content.status === 'uploaded') {
                getCaptions({
                    trainingId, 
                    topicId: topic.id,
                    contentId: content.id,
                }).then((response) => {
                    if (response) {
                        let currentCaptions = [];
    
                        if (response?.data?.length > 0) {
                            currentCaptions = response.data.map(caption => {
                                return {
                                    id: caption.id,
                                    language: caption.language,
                                    file: caption.id,
                                };
                            });
                        }
    
                        formik.setFieldValue('captions', currentCaptions);
                    }
                }).catch(error => {
                    enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.GET_CAPTION_ERROR'), {variant: 'error'});
                });
            }
        }
    }, [content]);
    
    return (
        <Container maxWidth='md'>
            <form onSubmit={formSubmit} className={styles.form}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={styles.titleContainer}>
                            <FormikTextField
                                fullWidth
                                name='title'
                                label={t('TRAININGS_PAGE.TOPIC_TITLE')}
                                formik={formik}
                                disabled={!isEditing}
                            />

                            <Tooltip title={t(isEditing ? 'TRAININGS_PAGE.CANCEL' : 'TRAININGS_PAGE.EDIT_TOPIC')}>
                                <IconButton
                                    disabled={content.status !== 'uploaded' || isLoading}
                                    onClick={() => setIsEditing(prev => !prev)}
                                >
                                    {isEditing ? <CloseIcon /> : <ModeEditIcon />}   
                                </IconButton>
                            </Tooltip>

                            {!isEditing && (
                                <Tooltip title={t('TRAININGS_PAGE.DELETE_TOPIC')}>
                                    <IconButton  onClick={() => setIsDeleteModalOpen(true)}>
                                        <DeleteIcon /> 
                                    </IconButton>
                                </Tooltip>
                            )}

                            {isDeleteTopicModalOpen && (
                                <ConfirmationModal
                                    title={t('TRAININGS_PAGE.DELETE_TOPIC')}
                                    description={t('TRAININGS_PAGE.DELETE_TOPIC_DESCRIPTION')}
                                    cancelText={t('TRAININGS_PAGE.CANCEL')}
                                    confirmText={t('TRAININGS_PAGE.DELETE_TOPIC')}
                                    handleClose={() => setIsDeleteModalOpen(null)}
                                    handleSubmit={handleDeleteTopic}
                                    loading={deleteTopicLoading}
                                />
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={styles.videoContainer}>
                            {content.status === 'uploaded' ? (
                                <>
                                    {isEditing ? (
                                        <>
                                            {videoUrl ? (
                                                <div className={styles.videoPreviewContainer}>
                                                    <Tooltip title={t('TRAININGS_PAGE.CHANGE_VIDEO')}>
                                                        <IconButton 
                                                            type='button'
                                                            className={styles.changeVideoButton}
                                                            onClick={() => inputVideoRef.current.click()}
                                                        >
                                                            <SwapVertOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
            
                                                    {String(videoUrl).startsWith('https') ? (
                                                        <TokyoPlayer key={videoUrl} decodeUrl={videoUrl} />
                                                    ) : (
                                                        <video controls className={styles.videoPreview} ref={videoRef}>
                                                            <source src={videoUrl} type='video/mp4' />
                                                        </video>
                                                    )}
                                                </div>
                                            ) : (
                                                <label htmlFor='video' className={styles.videoLabel}>
                                                    <PlayCircleFilledWhiteOutlinedIcon sx={{fontSize: '2rem'}} /> 
                                                    {t('TRAININGS_PAGE.SELECT_VIDEO')}
                                                </label>
                                            )}
                                        </>
                                    ) : (
                                        <div className={styles.videoPreview}>
                                            <TokyoPlayer key={videoUrl} decodeUrl={content.value} />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className={styles.uploadingVideo}>
                                    <Typography>{t('TRAININGS_PAGE.UPLOADING_VIDEO')}</Typography>
                                </div>
                            )}
                        </div>

                        <input
                            ref={inputVideoRef}
                            className={styles.videoInput}
                            type='file'
                            id='video'
                            name='video'
                            accept='.mp4, .mov, .mkv'
                            onChange={(event) => {
                                const file = event.currentTarget.files[0];
                                addFileInInput({file});
                            }}
                        />
                    </Grid>

                    {content.status === 'uploaded' && (
                        <Grid item xs={12}>
                            <div className={styles.captionsContainer}>
                                <label>{t('TRAININGS_PAGE.CAPTIONS')}</label>
                                
                                {formik.values.captions?.length > 0 ? 
                                    <ul className={styles.captionsList}>
                                        {formik.values.captions.map((caption) => (
                                            <li key={caption.id} className={cx([styles.captionsItemContainer, {[styles.disabled]: !isEditing}])}>
                                                <div className={styles.captionsItem}>
                                                    <p>{t(`LANGUAGES.${convertToConstant(caption.language)}`)}</p>
                                            
                                                    <IconButton 
                                                        onClick={() => setCaptionToDelete(caption)}
                                                        disabled={!isEditing}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </li>
                                        ))}
                                    </ul> : 
                                    <p className={styles.noCaptions}>{t('TRAININGS_PAGE.NO_CAPTIONS')}</p>
                            }

                                <Button 
                                    variant='contained'
                                    type='button'
                                    size='small'
                                    onClick={() => setIsCaptionsModalOpen(true)}
                                    disabled={!isEditing}
                                >
                                    {t('TRAININGS_PAGE.ADD_NEW_CAPTION')}
                                </Button>
                            </div>
                        </Grid>
                    )}
                    
                    <Grid item xs={12}>
                        {isLoading  && (
                            <Typography variant='caption'>
                                {t('TRAININGS_PAGE.UPLOADING_VIDEO')} {uploadPercentage}%
                            </Typography>
                        )}

                        <LoadingButton 
                            fullWidth
                            type='submit'
                            variant='contained'
                            loading={isLoading}
                            disabled={!isEditing}
                        >
                            {t('TRAININGS_PAGE.EDIT_TOPIC')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>

            {isCaptionsModalOpen && 
                <NewCaptionModal
                    handleClose={() => setIsCaptionsModalOpen(false)}
                    captions={formik.values.captions}
                    submitFunction={addCaptionItem}
                />
            }

            {!!captionToDelete && (
                <ConfirmationModal
                    title={t('TRAININGS_PAGE.DELETE_CAPTION')}
                    description={t('TRAININGS_PAGE.DELETE_CAPTION_DESCRIPTION')}
                    cancelText={t('TRAININGS_PAGE.CANCEL')}
                    confirmText={t('TRAININGS_PAGE.DELETE_CAPTION')}
                    handleClose={() => setCaptionToDelete(null)}
                    handleSubmit={() => handleDeleteCaption(captionToDelete)}
                    loading={deleteCaptionLoading}
                />
            )}
        </Container>
    );
}

import React, {useState, useEffect, useMemo} from 'react';
import {LoadingButton} from '@mui/lab';
import {
    Box,
    CircularProgress,
    Container,
    Grid,
    Button,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    FormHelperText,
} from '@mui/material';
import {FormikTextField} from '@src/components/FormikFields';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {useParams, useNavigate} from 'react-router-dom';
import {Edit} from '@mui/icons-material';
import {
    useEditTutorialMutation,
    useEditTutorialVideoMutation,
    useGetCaptionsQuery,
    useGetTutorialQuery,
    useGetTutorialsQuery,
    useLazyGetUploadDataQuery,
} from '@src/modules/tutorials/tutorials.api';
import {routes} from '@src/utils/routes';
import PageHeader from '@src/components/PageHeader';
import {tutorialSchema} from '@src/modules/tutorials/schema';
import {EditCaptionsList} from '@src/modules/tutorials/components/EditCaptionsList';
import {EditTutorialVideo} from '@src/modules/tutorials/components/EditTutorialVideo';
import {uploadVideo} from '@src/modules/uploads/uploadVideo';
import {uploadImage} from '@src/modules/uploads/uploadImage';
import {EditTutorialThumbnail} from '@src/modules/tutorials/components/EditTutorialThumbnail';
import {CategoryTagsField} from '@src/components/CategoryTagsField';
import {rolesOptions} from '@src/modules/tutorials/utils';

import styles from './tutorials-edit.module.scss';

export default function TutorialsEdit(){
    const [tags, setTags] = useState([]);
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {tutorialId} = useParams();
    const {data, isLoading: isDataLoading} = useGetTutorialQuery({tutorialId}, {skip: !tutorialId});
    const {data: captions} = useGetCaptionsQuery({videoId: tutorialId}, {skip: !tutorialId});
    const [editTutorial] = useEditTutorialMutation();
    const [editTutorialVideo] = useEditTutorialVideoMutation();
    const [getUploadData] = useLazyGetUploadDataQuery();
    const {data: tutorialsData} = useGetTutorialsQuery();

    const listCategories = tutorialsData?.map(value => value.tags);
    const tagsArrayMerge = useMemo(() => {
        if (listCategories){
            return listCategories.flat();
        }
    }, [listCategories]);

    const tagsNames = {};

    tagsArrayMerge?.forEach(item => {
        const lowerItem = item?.toLowerCase();
        const formattedName = lowerItem?.charAt(0)?.toUpperCase() + lowerItem?.slice(1);

        tagsNames[formattedName] = true;
    });
    const tagsOptions = Object.keys(tagsNames);
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const formik = useFormik({
        validationSchema: tutorialSchema(t),
        initialValues: {
            thumbnail: '',
            video: '',
            title: '',
            tags: [],
            roles: [],
        },
        onSubmit: async (form) => {
            setIsLoading(true);
            try {
                const thumbnailUrl = await uploadThumbnail(form.thumbnail, getUploadData);

                const payload = {
                    title: form.title,
                    thumbnail: thumbnailUrl,
                    tags: form.tags,
                    roles: form.roles,
                };

                await editTutorial({tutorialId, body: payload}).unwrap();
                 
                enqueueSnackbar(t('TUTORIALS_PAGE.EDIT_SUCCESS'), {variant: 'success'});
                navigate(routes.TUTORIALS);
                
                await editVideo(form.video, tutorialId, editTutorialVideo);

            } catch (error) {
                enqueueSnackbar(t(error.message || 'TUTORIALS_PAGE.EDIT_ERROR'), {variant: 'error'});
            } finally {
                setIsLoading(false);
            }
        },
    });

    const formSubmit = (e) => {
        e.preventDefault();
        formik.handleSubmit();
    };

    useEffect(() => {
        if (data) {
            formik.setValues({
                thumbnail: data.thumbnail,
                video: data.url,
                title: data.title,
                disabled: data.disabled,
                tags: data.tags,
                captions,
                roles: data.roles ?? [],
            });
            setTags(data.tags);
        }
    }, [data, captions]);

    return (
        <>
            <Container maxWidth='xl'>
                <PageHeader title={editMode ? 'TUTORIALS_PAGE.EDIT' : 'TUTORIALS_PAGE.DETAILS'} textTooltip={t('TUTORIALS_PAGE.BACK')} toBack={() => navigate(routes.TUTORIALS)} />
                {isDataLoading ? 
                    <Box sx={{height: 120, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <CircularProgress color='inherit' />
                    </Box> :
                    <form onSubmit={formSubmit} autoComplete='off'>
                        <Grid container spacing={2}>
                    
                            <div className={styles.mediasContainer}>
                                <EditTutorialThumbnail 
                                    setThumbnail={(file) => formik.setFieldValue('thumbnail', file)}
                                    editMode={editMode} 
                                    thumbnailUrl={formik.values.thumbnail}
                                />

                                <EditTutorialVideo 
                                    setVideo={(file) => formik.setFieldValue('video', file)}
                                    editMode={editMode} 
                                    videoUrl={formik.values.video}
                                />
                            </div>

                            <Grid item xs={12}>
                                <FormikTextField
                                    fullWidth
                                    name='title'
                                    label={t('TUTORIALS_PAGE.TITLE')}
                                    formik={formik}
                                    disabled={!editMode}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CategoryTagsField
                                    name='tags'
                                    label={t('TUTORIALS_PAGE.CATEGORY_TAGS')}
                                    formik={formik}
                                    tags={tags}
                                    setTags={setTags}
                                    tagsOptions={tagsOptions}
                                    disabled={!editMode}
                                />
                            </Grid>
                    
                            <Grid item xs={12}>
                                <EditCaptionsList 
                                    videoId={tutorialId}
                                    captions={formik.values.captions}
                                    editMode={editMode}
                                />
                            </Grid>
                        
                            <Grid item xs={12}>
                                <FormControl fullWidth error={Boolean(formik.touched.roles && formik.errors.roles)}>
                                    <InputLabel disabled={!editMode} id='roles'>{t('TUTORIALS_PAGE.ROLES')}</InputLabel>
                                    <Select
                                        labelId='roles'
                                        multiple
                                        value={formik.values.roles}
                                        onChange={(e) => formik.setFieldValue('roles', e.target.value)}
                                        input={<OutlinedInput label={t('TUTORIALS_PAGE.ROLES')} />}
                                        fullWidth
                                        disabled={!editMode}
                                    >
                                        {rolesOptions.map((item) => (
                                            <MenuItem
                                                key={item.value}
                                                value={item.value}
                                        >
                                                {t(item.label)}
                                            </MenuItem>
                                ))}
                                    </Select>
                                    {formik.errors.roles && formik.touched.roles && <FormHelperText>{formik.errors.roles}</FormHelperText>}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                {editMode ?
                                    <div className={styles.buttons}>
                                        <Button
                                            variant='outlined'
                                            type='button'
                                            fullWidth
                                            onClick={() => setEditMode(false)}
                                        >
                                            {t('TUTORIALS_PAGE.CANCEL')}
                                        </Button>
                                        <LoadingButton
                                            type='submit'
                                            loading={isLoading}
                                            variant='contained'
                                            fullWidth
                                        >
                                            {t('TUTORIALS_PAGE.SAVE')}
                                        </LoadingButton>
                                    </div> :
                                    <Button 
                                        startIcon={<Edit />} 
                                        variant='outlined' 
                                        color='secondary' 
                                        onClick={() => setEditMode(true)} 
                                        fullWidth
                                    >
                                        {t('FAQ.EDIT_MODE')}
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </form>
                }
            </Container>
        </>
    );
}

const editVideo = async (video, tutorialId, editTutorialVideo) => {
    try {
        if (typeof video !== 'string') {
            const response = await editTutorialVideo({videoId: tutorialId}).unwrap();
            
            await uploadVideo({
                video: video, 
                uploadData: response,
            });
        }
    } catch {
        throw new Error('TUTORIALS_PAGE.UPLOAD_ERROR');
    }
};

const uploadThumbnail = async (thumbnail, getUploadData) => {
    let thumbnailUrl = thumbnail;
    if (typeof thumbnail !== 'string') {

        try {
            const response = await getUploadData({
                ContentType: thumbnail.type,
                ContentLength: thumbnail.size,                
            }).unwrap();
            
            await uploadImage({
                url: response.post.url,
                fields: response.post.fields,
                file: thumbnail,
            });

            thumbnailUrl = response.get;

        } catch (error) {
            throw new Error('TUTORIALS_PAGE.THUMBNAIL_ERROR');
        }
    }

    return thumbnailUrl;
};

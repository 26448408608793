import React from 'react';
import {Avatar} from '@mui/material';
import Users from '@assets/imgs/Logos.svg';

export default function LogoAvatar({avatar, className, ...props}) {
    return (
        <Avatar alt='avatar' src={avatar || Users} className={className} {...props} />
    );

}

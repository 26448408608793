import React from 'react';
import {Autocomplete, Box, MenuItem, TextField, Tooltip} from '@mui/material';
import AccountDisabledSwitch from '@src/modules/accounts/components/AccountDisabledSwitch';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {FormikTextField} from '@src/components/FormikFields';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {accountsType, handleOnWheel, numberFilter, timezonesAll} from '@src/modules/accounts/utils';
import ResponsibleDetails from '@src/modules/accounts/components/ResponsibleDetails';
import {accountTypes} from '@src/modules/accounts/schema';
import {FormikSelect} from '@src/components/FormikFields/FormikSelect';

import styles from './accountCreateTab.module.scss';

export function AccountCreateTab({formik, isLoading}) {
    const {t} = useTranslation();
    const {accountType} = useParams();

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='name'
                        label={t('ACCOUNTS_PAGE.FORM.NAME')}
                        formik={formik}
                        onChange={(e) => formik.setFieldValue('name', e.target.value)}
                        readOnly={isLoading}
                        disabled={isLoading}
                        data-qa='create-account-name'
                        />
                </div>
                <div className={styles.item}>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <FormikTextField
                            fullWidth
                            name='subdomain'
                            data-qa='create-account-subdomain'
                            label={t('ACCOUNTS_PAGE.FORM.SUBDOMAIN')}
                            formik={formik}
                            readOnly={isLoading}
                            InputProps={{
                                    endAdornment: (
                                        <Tooltip
                                            className={styles.tooltip}
                                            title={
                                                <ul>
                                                    <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.LOWER_CASE')}</li>
                                                    <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.SPACES')}</li>
                                                    <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.HYPHENS')}</li>
                                                    <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.SPECIAL_CHARACTER')}</li>
                                                    <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.MARKERS')}</li>
                                                </ul>
                                            }
                                        >
                                            <InfoOutlinedIcon />
                                        </Tooltip>
                                    ),
                            }}
                            disabled={isLoading}
                            onKeyUp={() => formik.setFieldValue('subdomain', formik.values.subdomain.toLowerCase())}
                            />

                    </Box>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.item}>
                    <FormikTextField
                        name='mu'
                        data-qa='create-account-max-users'
                        label={t('ACCOUNTS_PAGE.MAX_NUMBER_USERS')}
                        formik={formik}
                        readOnly={isLoading}
                        disabled={isLoading}
                        type='number'
                        InputProps={{inputProps: {min: 1}}}
                        onKeyPress={(e) => numberFilter(e)}
                        onWheel={handleOnWheel}
                        fullWidth
                    />
                </div>
                <div className={styles.item}>
                    <FormikSelect
                        id={'type'}
                        name='type'
                        data-qa='create-account-type'
                        formik={formik}
                        label={t('ACCOUNTS_PAGE.TYPE')}
                        value={formik?.values.type}
                        onChange={(_, value) => {
                                formik.setFieldValue('type', value.props.value);
                        }}>
                        {accountsType.map((i) =>
                            <MenuItem value={i.value} key={i.value}>{t(i.label)}</MenuItem>,
                            )}
                    </FormikSelect>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.soloItem}>
                    <Autocomplete
                        options={timezonesAll}
                        getOptionLabel={(option) => option.label}
                        value={formik.values.timezone}
                        onChange={(_, value) => {
                                formik.setFieldValue('timezone', value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                data-qa='create-account-timezone'
                                label={t('ACCOUNTS_PAGE.FORM.TIMEZONE')}
                                error={Boolean(formik.touched.timezone && formik.errors.timezone)}
                                helperText={formik.touched.timezone && formik.errors.timezone}
                                />
                        )}
                        />
                </div>
            </div>
            <ResponsibleDetails
                formik={formik}
                showPasswordConfirmation={true}
                readOnly={isLoading}
                disabled={isLoading}
                />
            <div className={styles.row}>
                <div className={styles.item}>
                    {accountType === accountTypes.commercials &&
                        <AccountDisabledSwitch formik={formik} data-qa='create-account-disabled-switch' />}
                </div>
            </div>
        </div>
    );
}

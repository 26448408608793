import React, {useCallback, useEffect, useState} from 'react';
import {Grid, Paper, Container, Typography, Button} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import logo from '@assets/imgs/logo.svg';
import {useFormik} from 'formik';
import CodeInput from '@src/components/CodeInput';
import {insertCodeSchema} from '@modules/auth/schema';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import {useInsertCodeMutation, useResendCodeMutation} from '@src/modules/auth/auth.api';
import {useDispatch, useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';
import {setSession} from '@src/modules/auth/auth.reducer';
import Timer from '@src/modules/auth/components/Timer';

import styles from './code.module.scss';

const RESEND_PASSWORD_TIME = 30;

export default function Code() {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const session = useSelector((state) => state.auth.session);

    const [insertCode, {isLoading, isSuccess, data, error}] = useInsertCodeMutation();

    const handleSendCode = useCallback((data) => {
        if (!session) {
            navigate(routes.FORGET_PASSWORD);
            enqueueSnackbar(t('SESSION_EXPIRED'), {variant: 'error'});
        } else {
            insertCode({session, ...data});
        }
    }, [session]);

    useEffect(() => {
        if (isSuccess) {
            if (data.state === 'set-password') {
                dispatch(setSession(data.session));
                navigate(routes.INSERT_PASSWORD);
            }
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            switch (error.status) {
            case 400:
                enqueueSnackbar(t('INVALID_CODE'), {variant: 'error'});
                dispatch(setSession(error?.data?.session));
                break;
            case 404:
                enqueueSnackbar(t('SESSION_EXPIRED'), {variant: 'error'});
                navigate(routes.FORGET_PASSWORD);
                break;
            default:
                enqueueSnackbar(t('CHANGE_PASSWORD_ERROR'), {variant: 'error'});
            }
        }
    }, [error]);

    const formik = useFormik({
        validationSchema: insertCodeSchema(t),
        initialValues: {
            code: '',
        },
        onSubmit: handleSendCode,
    });

    const formSubmit = e => {
        e.preventDefault();
        formik.handleSubmit();
    };

    return (
        <Container maxWidth='xs' className={styles.container}>
            <img src={logo} />
            <Paper className={styles.paper}>
                <Typography textAlign='center' className={styles.title}>
                    {t('FORGET_PASSWORD')}
                </Typography>
                <Typography textAlign='center' color='textSecondary' className={styles.subtitle}>
                    {t('INSERT_VERIFICATION_CODE')}
                </Typography>
                <form onSubmit={formSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CodeInput
                                value={formik.values.code}
                                error={formik.errors.code && formik.touched.code && formik.errors.code}
                                onChange={val => formik.setFieldValue('code', val)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ResendCode />
                            <LoadingButton
                                type='submit'
                                loading={isLoading}
                                variant='contained'
                            >
                                {t('SEND')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>  
    );
}

const ResendCode = () => {
    const [timer, setTimer] = useState(RESEND_PASSWORD_TIME);
    const [resendCode, {isSuccess, error, data}] = useResendCodeMutation();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const session = useSelector((state) => state.auth.session);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const handleResendCode = () => {
        setTimer(RESEND_PASSWORD_TIME);
        if (!session) {
            navigate(routes.FORGET_PASSWORD);
            enqueueSnackbar(t('SESSION_EXPIRED'), {variant: 'error'});
        } else {
            resendCode({session});
        }
        
    };

    useEffect(() => {
        if (isSuccess) {
            if (data.state === 'validate-code') {
                dispatch(setSession(data.session));
                navigate(routes.INSERT_CODE);
                enqueueSnackbar(t('CODE_SENT'), {variant: 'success'});
            }
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            switch (error.status) {
            case 404:
                enqueueSnackbar(t('SESSION_EXPIRED'), {variant: 'error'});
                navigate(routes.FORGET_PASSWORD);
                break;
            default:
                enqueueSnackbar(t('CHANGE_PASSWORD_ERROR'), {variant: 'error'});
            }
        }
    }, [error]);

    return (
        <>
            {(timer > 0) && <Timer counter={timer} setCounter={(value) => setTimer(value)} />}
            {timer === 0 && <Button onClick={handleResendCode} variant='text' className={styles.resendCode}>{t('RESEND_CODE')}</Button>}
        </>
    );
};

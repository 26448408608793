import React, {useState, useRef, useEffect} from 'react';

import styles from './code-input.module.scss';
const INIT_CODE = {
    i1: '',
    i2: '',
    i3: '',
    i4: '',
    i5: '',
    i6: '',
};
const CODE_SIZE = 6;
export default function CodeInput({
    onChange,
    error,
    title,
    value,
}) {
    const [code, setCode] = useState(INIT_CODE);
    const refs = {
        i1: useRef(null),
        i2: useRef(null),
        i3: useRef(null),
        i4: useRef(null),
        i5: useRef(null),
        i6: useRef(null),
    };
    useEffect(() => {
        if (!value) {
            setCode(INIT_CODE);
        }
    }, [value]);
    const handleChange = (e, position, nextRef) => {
        const inputValue = e.target.value;
        let newCode = '';
        if (e.target.value.length <= 1) {
            newCode = {...code, [`i${position}`]: inputValue};
        } else if (position < CODE_SIZE) {
            newCode = {...code, [`i${position + 1}`]: inputValue.charAt(inputValue.length - 1)};
        }
        if (newCode) {
            onChange(Object.values(newCode).reduce((acc, v) => acc.concat(v), ''));
            setCode(newCode);
        }
        if (nextRef && e.target.value !== '') {
            nextRef.current.focus();
        }
    };
    const handleKeyUp = (e, previous) => {
        if (e.keyCode === 8 && previous) {
            previous.current.focus();
        }
    };
    return (
        <div className={styles.container}>
            <p>{title}</p>
            <div className={styles.inputs}>
                {Array(CODE_SIZE).fill({}).map((_, idx) => (
                    <input
                        key={idx}
                        className={error ? styles.errorInput : ''}
                        autoFocus={!idx}
                        value={code[`i${idx + 1}`]}
                        type='text'
                        ref={refs[`i${idx + 1}`]}
                        onKeyUp={(e) => handleKeyUp(e, refs[`i${idx}`])}
                        onChange={(e) => handleChange(e, idx + 1, refs[`i${idx + 2}`])} />
        ))
        }
            </div>
            {error && <div className={styles.error}>
                <span>{error}</span>
            </div>}
        </div>
    );
}

import {apiSlice} from '@src/store/apiSlice';
export const importUsersTags = {
    importUsersTag: 'IMPORT_USERS',
};
export const importUsersApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({getUsersImportUrl: builder.query({
        query: ({id, params}) => ({url: `/accounts/trainings/${id}/managers/many/url`, params}),
    }),
    confirmUsersImport: builder.mutation({
        query: ({id, body}) => {
            return {
                url: `/accounts/trainings/${id}/managers/many/url`,
                method: 'POST',
                body,
            };
        },
    }),
    getUsersTemplate: builder.query({
        query: (accountId) => ({url: `/accounts/trainings/${accountId}/managers/many/template`}),
    })}),
});

export const {
    useGetUsersImportUrlQuery,
    useLazyGetUsersImportUrlQuery, 
    useConfirmUsersImportMutation,
    useGetUsersTemplateQuery,
} = importUsersApi;

import {LoadingButton} from '@mui/lab';
import {Button} from '@mui/material';
import {useCan} from '@src/hooks/useCan';
import {permissionResources, permissionTypes} from '@src/hooks/usePrivateRoutes';
import FinancialData from '@src/modules/accounts/components/FinancialData';
import FinancialResponsible from '@src/modules/accounts/components/FinancialResponsible';
import UsersData from '@src/modules/accounts/components/UsersData';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';

import styles from './financeEditTab.module.scss';

export function FinanceEditTab({formik, file, setFile, isLoading}) {
    const {t} = useTranslation();
   
    const can = useCan();
    const navigate = useNavigate();
    const permissionResource = permissionResources.commercialAccounts;

    return (
        <form
            onSubmit={formik.handleSubmit}
            autoComplete='off'>
            <UsersData
                formik={formik}
                readOnly={true}
                disabled={true}
            />
            <FinancialData
                values={formik.values}
                setFieldValue={formik.setFieldValue}
                formik={formik}
                file={file}
                setFile={setFile}
            />
            <FinancialResponsible
                formik={formik}
                readOnly={isLoading}
                disabled={isLoading}
            />
            {can(permissionResource, permissionTypes.edit) && (
                <div className={styles.buttonsContainer}>
                    <Button
                        variant='outlined'
                        disabled={isLoading}
                        onClick={() => navigate(routes.ACCOUNTS)}
                         >
                        {t('ACCOUNTS_PAGE.CANCEL')}
                    </Button>

                    <LoadingButton
                        type='submit'
                        loading={isLoading}
                        disabled={isLoading || !formik.dirty}
                        variant='contained'
                         >
                        {t('ACCOUNTS_PAGE.SAVE')}
                    </LoadingButton>
                </div>
                 )}
        </form>
    );
}

import React from 'react';
import ContentLoader from 'react-content-loader';
import {useGetByIdQuery} from '@src/modules/accounts/accounts.api';

const AccountName = ({accountData, className = ''}) => {
    const {data, isLoading} = useGetByIdQuery({id: accountData}, {skip: !accountData});
    if (isLoading || !accountData) {
        return <Loading />;
    }
    return (
        <div className={className}>
            <span>{data?.name}</span>
        </div>
    );
};

const Loading = () => {
    return (
        <ContentLoader
            speed={2}
            width={'100%'}
            height='30px'
            backgroundColor={'var(--theme-skeleton-background'}
            foregroundColor={'var(--theme-skeleton-foreground'}
        >
            <rect x='0' y='0' rx='5' ry='5' width='100%' height='50px' />,
        </ContentLoader>
    );
};

export default AccountName;

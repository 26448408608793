import AccountData from '@src/modules/accounts/components/AccountData';
import {normalizeString} from '@src/modules/accounts/utils';
import {useEffect, useState} from 'react';
import React from 'react';

export function ClientCreateTab({formik, isLoading}) {
    const [dataCep, setDataCep] = useState();
    useEffect(() => {
        if (dataCep) {
            formik.setFieldValue('address.postal_code', dataCep?.erro ? '' : normalizeString(dataCep?.cep));
            formik.setFieldValue('address.street', dataCep?.erro ? '' : normalizeString(dataCep?.logradouro));
            formik.setFieldValue('address.neighborhood', dataCep?.erro ? '' : normalizeString(dataCep?.bairro));
            formik.setFieldValue('address.city', dataCep?.erro ? '' : normalizeString(dataCep?.localidade));
            formik.setFieldValue('address.state', dataCep?.erro ? '' : normalizeString(dataCep?.uf));
        }
    }, [dataCep]);

    return (
        <AccountData
            formik={formik}
            setFieldValue={formik.setFieldValue}
            setDataCep={setDataCep}
            readOnly={isLoading}
            disabled={isLoading}
        />
    );
}

import {apiSlice} from '@src/store/apiSlice';
export const notesTags = {
    notesTag: 'NOTES',
    userTag: 'USER',
};
export const notesApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        listAllNotes: builder.query({
            query: ({page = 0, pageSize = 10} = {}) => {
                return {
                    url: '/notes',
                    method: 'GET',
                    params: {page, pageSize, order: 'DESC', orderBy: 'created_at'},
                };
            },
            providesTags: [notesTags.notesTag],
        }),
        getUser: builder.query({
            query: ({accountId, userId}) => ({url: `/accounts/${accountId}/users/${userId}`}),
            providesTags: (_result, _error, {userId}) => [
                {type: notesTags.userTag, id: userId},
            ],

        }),
        getNote: builder.query({
            query: ({noteId}) => ({url: `/notes/${noteId}`}),
            providesTags: (_result, _error, {noteId}) => [
                {type: notesTags.notesTag, id: noteId},
            ],
        }),
    }),
});

export const {useListAllNotesQuery, useGetUserQuery, useGetNoteQuery} = notesApi;

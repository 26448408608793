import React from 'react';
import {useTranslation} from 'react-i18next';
import {acceptedFiles} from '@modules/importUsers/utils';
import {Box} from '@mui/material';
import Dropzone from 'react-dropzone';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import {Tips} from '../../Tips';

import styles from './import-spreadsheet.module.scss';

export function ImportSpreadsheet({importData}) {
    const {t} = useTranslation();

    return (
        <Box className={styles.main}>
            <h1 className={styles.title}>{t('IMPORT_USERS.SPREADSHEET_IMPORT')}</h1>
            <div className={styles.importContent}>
                <label htmlFor='file-input' className={styles.inputWrapper}>
                    <span className={styles.dropIndicator}>
                        <FileUploadOutlinedIcon />
                        {t('IMPORT_USERS.TO_LOAD')}
                    </span>
                    <span className={styles.dropText}>{t('IMPORT_USERS.ADD_FILE')}</span>
                    <span className={styles.fileTypesText}>{t('IMPORT_USERS.ACCEPTED_FILES')}</span>
                </label>
                <Dropzone onDrop={importData} accept={acceptedFiles} maxFiles={1}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()}>
                            <input id='file-input' {...getInputProps()} />
                        </div>
                  )}
                </Dropzone>
                <Tips />
            </div>
        </Box>
    );
}

import {Button, ButtonGroup, Container, Skeleton, Typography} from '@mui/material';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {InfoCard} from '@src/screens/GeneralDashboard/InfoCard';
import {useGetDashboardInfoQuery} from '@src/modules/generalDashboard/dashboard.api';

import styles from './general-dashboard.module.scss';
export default function GeneralDashboard(){
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState('users');

    const {data: accountData, isLoading: dataIsLoading} = useGetDashboardInfoQuery();
    const usersInfo = useMemo(() => [
        {
            iconName: 'WarningCircle',
            name: 'GENERAL_DASHBOARD.NUMBER_OF_ACTIVE_USERS_OF_PAID_ACCOUNTS',
            total: accountData?.Paid.totalUsers || 0,
        }, {
            iconName: 'WarningCircle',
            name: 'GENERAL_DASHBOARD.NUMBER_OF_ACTIVE_USERS_OF_FREE_ACCOUNTS',
            total: accountData?.Free.totalUsers || 0,
        }, {
            iconName: 'Users',
            name: 'GENERAL_DASHBOARD.TOTAL_ACTIVE_USERS_ON_BUSINESS_ACCOUNTS',
            total: accountData?.Commercial.totalUsers || 0,
        }, {
            iconName: 'WarningCircle',
            name: 'GENERAL_DASHBOARD.NUMBER_OF_ACTIVE_USERS_OF_EXPERIMENTAL_ACCOUNTS',
            total: accountData?.Experimental.totalUsers || 0,
        }], [accountData]);

    const accountsInfo = useMemo(() => [{
        iconName: 'Users',
        name: 'GENERAL_DASHBOARD.NUMBER_OF_PAID_ACCOUNT',
        total: accountData?.Paid.totalAccounts || 0,
    }, {
        iconName: 'Users',
        name: 'GENERAL_DASHBOARD.NUMBER_OF_TRAINING_ACCOUNTS',
        total: accountData?.Training.totalAccounts || 0,
    },  {
        iconName: 'Users',
        name: 'GENERAL_DASHBOARD.NUMBER_OF_TRIAL_ACCOUNTS',
        total: accountData?.Experimental.totalAccounts || 0,
    },  {
        iconName: 'Users',
        name: 'GENERAL_DASHBOARD.NUMBER_OF_FREE_ACCOUNTS',
        total: accountData?.Free.totalAccounts || 0,
    }], [accountData]);

    return (
        <Container maxWidth='xl'>
            <header className={styles.generalDashboardHeader}>
                <Typography variant='h4' component='h2'>
                    {t('GENERAL_DASHBOARD.GENERAL_DASHBOARD')}
                </Typography>
            </header>
            <main className={styles.main}>
                <ButtonGroup variant='outlined' className={styles.tabContainer}>
                    <Button
                        variant={activeTab === 'users' ? 'contained' : 'outlined'}
                        onClick={() => setActiveTab('users')}
                    >
                        {t('GENERAL_DASHBOARD.USERS')}
                    </Button>
                    <Button
                        variant={activeTab === 'accounts' ? 'contained' : 'outlined'}
                        onClick={() => setActiveTab('accounts')}
                    >
                        {t('GENERAL_DASHBOARD.ACCOUNTS')}
                    </Button>
                </ButtonGroup>
                {activeTab === 'users' ? (
                    <div className={styles.gridContainer}>
                        {dataIsLoading ? (
                            <>
                                {Array.from(new Array(usersInfo?.length)).map((_, index) => (
                                    <Skeleton
                                        main
                                        key={index}
                                        variant='rounded'
                                        className={styles.gridItem}
                                        width='100%'
                                        height={114}
                                    />
                                ))}
                            </>
                        ) : (

                            usersInfo.map((card) => (
                                <InfoCard
                                    key={card.name}
                                    className={styles.gridItem}
                                    name={t(card.name)}
                                    total={card.total}
                                />
                            ))
                        )}
                    </div>
                ) :
                (
                    <div className={styles.gridContainer}>
                        {accountsInfo.map((card) => (
                            <InfoCard
                                key={card.name}
                                className={styles.gridItem}
                                name={t(card.name)}
                                total={card.total}
                            />
                        ))}
                    </div>
                )}
            </main>
        </Container>
    );
}

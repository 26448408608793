import React, {useState, useEffect, useRef} from 'react';
import {VideoPlayer as TokyoPlayer} from 'react-tokyo';
import {useTranslation} from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import {Button} from '@mui/material';

import {generateFileUrl} from '../../utils';

import styles from './edit-tutorial-video.module.scss';

function VideoComponent({url}) {
    const videoRef = useRef();
  
    useEffect(() => {    
        videoRef.current?.load();
    }, [url]);
  
    return (
        <video controls ref={videoRef}>
            <source src={url} />
        </video>
    );
}

export function EditTutorialVideo({editMode, videoUrl, setVideo}) {
    const [currentVideoUrl, setCurrentVideoUrl] = useState('');
    const [previewUrl, setPreviewUrl] = useState(null);

    const inputRef = useRef(null);
    
    const {t} = useTranslation();

    function handleChangeVideo() {
        inputRef.current.click();
    }

    function addFileInInput({file}) {
        if (file) {
            setVideo(file);
            setPreviewUrl(generateFileUrl(file));
        } else {
            setVideo(videoUrl);
            setPreviewUrl(generateFileUrl(videoUrl));
        }
    }

    useEffect(() => {
        if (typeof videoUrl === 'string') {
            setCurrentVideoUrl(videoUrl);
        }

        return () => {
            setCurrentVideoUrl(null);
        };
    }, [videoUrl]);

    return (
        <div className={styles.container}>
            <div 
                className={`
                    ${styles.mediaInputContainer}
                    ${!editMode && styles.mediaInputContainerDisabled}
                `}
            >
             
                <label htmlFor='video'>
                    {currentVideoUrl && !previewUrl && <TokyoPlayer decodeUrl={currentVideoUrl} />}

                    {previewUrl && (
                        <VideoComponent url={previewUrl} />
                    )}
                </label>
              
                <input
                    ref={inputRef}
                    type='file'
                    id='video'
                    name='video'
                    accept='.mp4, .mov, .mkv'
                    disabled={!editMode}
                    onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        addFileInInput({file});
                    }}
                />
            </div>

            <Button
                endIcon={<EditIcon />}
                size='small'
                color='warning'
                disabled={!editMode}
                onClick={handleChangeVideo}
            >
                {t('TUTORIALS_PAGE.CHANGE_SELECTED_VIDEO')}
            </Button>
        </div>
    );
}

import React, {useState} from 'react';
import {Button, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from 'react-i18next';
import {ConfirmationModal} from '@src/components/ConfirmationModal';

import {convertToConstant, generateCaptionFormData} from '../../utils';
import {useAddCaptionMutation, useDeleteCaptionMutation} from '../../tutorials.api';
import {NewCaptionModal} from '../NewCaptionModal';

import styles from './edit-captions-list.module.scss';

export function EditCaptionsList({videoId, captions, editMode}) {
    const [captionToDelete, setCaptionToDelete] = useState(null);
    const [isCaptionsModalOpen, setIsCaptionsModalOpen] = useState(false);
    
    const {t} = useTranslation();
    const [deleteCaption, {isLoading: deleteCaptionLoading}] = useDeleteCaptionMutation();
    const [addCaption, {isLoading: addCaptionLoading}] = useAddCaptionMutation();

    async function handleAddCaption(selectedCaptionData) {
        const captionFormData = generateCaptionFormData(selectedCaptionData);

        await addCaption({
            videoId, 
            body: captionFormData,
        });
    }

    async function handleDeleteCaption(captionId) {
        await deleteCaption({videoId, captionId});
        setCaptionToDelete(null);
    }

    return (
        <>
            <div 
                className={styles.captionsContainer} 
                style={{
                    opacity: editMode ? 1 : 0.5,
                }}
                                >
                <label>{t('TUTORIALS_PAGE.CAPTIONS')}</label>
                {captions?.length > 0 ? (
                    <ul className={styles.captionsList}>
                        {captions.map((caption) => (
                            <li key={caption.id}>
                                <div className={styles.captionsItem}>
                                    <p>{t(`LANGUAGES.${convertToConstant(caption.language)}`)}</p>
                                            
                                    <IconButton
                                        onClick={() => setCaptionToDelete(caption.id)}
                                        disabled={!editMode}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </li>
                        ))}
                    </ul> 
                ) : (
                    <p className={styles.noCaptions}>{t('TUTORIALS_PAGE.NO_CAPTIONS')}</p>
                )}

                <Button
                    variant='contained'
                    type='button'
                    size='small'
                    disabled={!editMode}
                    onClick={() => setIsCaptionsModalOpen(true)}
                >
                    {t('TUTORIALS_PAGE.ADD_NEW_CAPTION')}
                </Button>
            </div>

            {isCaptionsModalOpen && 
                <NewCaptionModal 
                    handleClose={() => setIsCaptionsModalOpen(false)}
                    captions={captions}
                    loading={addCaptionLoading}
                    submitFunction={handleAddCaption}
                />
            }

            {captionToDelete &&
                <ConfirmationModal
                    title={t('TUTORIALS_PAGE.DELETE_CAPTION')}
                    description={t('TUTORIALS_PAGE.DELETE_CAPTION_DESCRIPTION')}
                    cancelText={t('TUTORIALS_PAGE.CANCEL')}
                    confirmText={t('TUTORIALS_PAGE.DELETE_CAPTION')}
                    handleClose={() => setCaptionToDelete(null)}
                    handleSubmit={() => handleDeleteCaption(captionToDelete)}
                    loading={deleteCaptionLoading}
                />
            }
        </>
    );
}

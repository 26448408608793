import React, {useCallback, useMemo, useState} from 'react';
import {Switch, Tooltip, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import AlertDialog from '@src/components/AlertDialog';
import {useParams} from 'react-router-dom';
import * as moment from 'moment';
import {useDispatch} from 'react-redux';
import {useSnackbar} from 'notistack';

import {invalidateAccount, useToggleMutation, useUpdateExpiryDateMutation} from '../../accounts.api';

import styles from './accountDisabledSwitch.module.scss';

export default function AccountDisabledSwitch({formik}){
    const {t} = useTranslation();
    const [showConfirmDialog, setShowConfirmDialog] = useState();
    const [toggle, {isLoading: isToggleLoading}] = useToggleMutation();
    const [updateExpiryDate] = useUpdateExpiryDateMutation();
    const {accountType, id} = useParams();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const accountStatusTitle = useMemo(() => t(`ACCOUNTS_PAGE.${formik?.values?.disabled  ? 'ENABLE' : 'DISABLE'}_ALERT.TITLE`), [formik?.values?.disabled]);
    const accountStatusDescription = useMemo(() => t(`ACCOUNTS_PAGE.${formik?.values?.disabled  ? 'ENABLE' : 'DISABLE'}_ALERT.DESCRIPTION`), [formik?.values?.disabled]);

    const handleClose = () => {
        setShowConfirmDialog(false);
    };

    const handleConfirm = useCallback(async () => {
        if (formik?.values?.is_demo && formik?.values?.disabled) {
            const isExpired = moment(formik?.values?.expiry_date).isBefore(moment());

            if (isExpired) {
                await updateExpiryDate({id: id, body: ''});
            }
        }

        await toggle({id: `${accountType}/${id}`, body: !formik?.values?.disabled}).unwrap();

        enqueueSnackbar(t('ACCOUNTS_PAGE.EDIT_SUCCESS'), {variant: 'success'});
        dispatch(invalidateAccount());
        setShowConfirmDialog(false);
    }, [id, formik?.values?.disabled]);

    const handleSwitch = () => {
        setShowConfirmDialog(true);
    };

    return (
        <>
            <AlertDialog
                open={!!showConfirmDialog}
                title={accountStatusTitle}
                description={accountStatusDescription}
                isLoading={isToggleLoading}
                onClose={handleClose}
                onConfirm={handleConfirm}
                confirmColor={formik?.values?.disabled ? 'success' : 'error'}
            />
            <div className={styles.container}>
                <Typography variant='h6' component='h6' mt={1} mb={2}>
                    {t('ACCOUNTS_PAGE.ENABLE_ALERT.TITLE')}
                </Typography>
                <div className={styles.tooltip}>
                    <Tooltip title={accountStatusTitle}>
                        <Switch 
                            onChange={handleSwitch}
                            name='disabled'
                            checked={!formik?.values?.disabled}
                            formik={formik}
                        />
                    </Tooltip>
                    <div>
                        <span>{accountStatusTitle}</span>
                    </div>
                </div>
            </div>
        </>
    );
        
}

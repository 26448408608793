import FinancialData from '@src/modules/accounts/components/FinancialData';
import FinancialResponsible from '@src/modules/accounts/components/FinancialResponsible';
import React from 'react';

export function FinanceCreateTab({formik, file, setFile, isLoading}) {
    return (
        <>
            <FinancialData
                values={formik.values}
                setFieldValue={formik.setFieldValue}
                formik={formik}
                file={file}
                setFile={setFile}
            />
            <FinancialResponsible
                formik={formik}
                readOnly={isLoading}
                disabled={isLoading}
            />
        </>
    );
}

import React, {useMemo, useState, useCallback, useEffect} from 'react';
import {IconButton, Switch, Tooltip, Button, Container, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import Table from '@src/components/Table';
import {formatDate} from '@src/utils/moment-utils';
import {useGetTutorialsQuery, useToggleTutorialMutation} from '@src/modules/tutorials/tutorials.api';
import AlertDialog from '@src/components/AlertDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useSnackbar} from 'notistack';
import {convertToConstant} from '@src/modules/tutorials/utils';

import styles from './tutorials-list.module.scss';

export default function TutorialsList(){
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [videosList, setVideosList] = useState([{status: 'uploading'}]);
    const {data, isLoading} = useGetTutorialsQuery(null, {
        pollingInterval: videosList && videosList.some(item => item.status !== 'uploaded') ? 10000 : 0, 
        refetchOnMountOrArgChange: true,
    });
    
    const columns = useMemo(() => [
        {accessor: 'title', Header: t('TUTORIALS_PAGE.TITLE')},
        {accessor: 'thumbnail', Header: t('TUTORIALS_PAGE.THUMBNAIL'), Cell: ({cell: {value}}) => <img className={styles.thumbnailCellImage} src={value} />},
        {accessor: 'created_at', Header: t('TUTORIALS_PAGE.CREATED_AT'), Cell: ({cell: {value}}) => formatDate(value)},
        {accessor: 'updated_at', Header: t('TUTORIALS_PAGE.UPDATED_AT'), Cell: ({row}) =>  row?.values.created_at !== row?.values.updated_at ? formatDate(row?.values.updated_at) : ''},
        {accessor: 'status', Header: t('TUTORIALS_PAGE.STATUS'), Cell: ({row}) =>  t(`TUTORIALS_PAGE.${convertToConstant(row?.values.status)}`)},
    ], []);

    useEffect(() => {
        setVideosList(data);
    }, [data]);
    
    return (
        <Container maxWidth='xl'>
            <header className={styles.tutorialsListHeader}>
                <Typography variant='h4' component='h2'>
                    {t('TUTORIALS_PAGE.NAME')}
                </Typography>
                <Button variant='contained' onClick={() => navigate(routes.TUTORIALS_CREATE)}>
                    {t('TUTORIALS_PAGE.CREATED')}
                </Button>
            </header>
            <Table 
                columns={columns}
                data={data}
                notFoundText={t('TUTORIALS_PAGE.NO_DATA_FOUND')}
                paginated={false}
                loading={isLoading}
                Actions={Actions} 
            />       
        </Container>
    );
}  

const Actions = ({data, id}) => {
    const {t} = useTranslation();
    const [toggleId, setToggleId] = useState(null);
    const navigate = useNavigate();
    const [toggle, {isLoading: isToggleLoading}] = useToggleTutorialMutation();

    const {enqueueSnackbar} = useSnackbar();

    const onChange = () => {
        setToggleId(id);
    };
    
    const handleClose = () => {
        setToggleId(null);
    };

    const handleConfirm = useCallback(async () => {
        try {
            await toggle({videoId: id, disabled: !data?.disabled}).unwrap(); 
            setToggleId(null); 
            enqueueSnackbar(t('TUTORIALS_PAGE.TOGGLE_SUCCESS', {type: t(data?.disabled ? 'TUTORIALS_PAGE.ENABLED_TYPE' : 'TUTORIALS_PAGE.DISABLED_TYPE')}), {variant: 'success'});

        } catch {
            enqueueSnackbar(t('TUTORIALS_PAGE.TOGGLE_ERROR'), {variant: 'error'});
        }

    }, [toggleId]);

    if (data.status !== 'uploaded') {
        return null;
    }

    return (
        <div className={styles.actions}>
            <Tooltip title={t('TUTORIALS_PAGE.TOGGLE')}>
                <Switch color='success' size='small' checked={!data?.disabled} onClick={onChange} />
            </Tooltip>
            <Tooltip title={t('TUTORIALS_PAGE.TOOLTIP_VIEW')}>
                <IconButton size='small' onClick={() => navigate(routes.TUTORIALS_EDIT(id))}>
                    <VisibilityIcon />
                </IconButton>
            </Tooltip>
                
            <AlertDialog 
                open={!!toggleId}
                title={t(!data?.disabled ? 'TUTORIALS_PAGE.DISABLE_ALERT' : 'TUTORIALS_PAGE.ENABLE_ALERT')}
                description={t(!data?.disabled ? 'TUTORIALS_PAGE.DISABLE_ALERT_DESCRIPTION' : 'TUTORIALS_PAGE.ENABLE_ALERT_DESCRIPTION')}
                isLoading={isToggleLoading}
                onClose={handleClose}
                onConfirm={handleConfirm}
                confirmColor='success'
                className={styles.dialog}
            />
        </div>
    );
};

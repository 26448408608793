import React from 'react';
import {IconButton, Tooltip} from '@mui/material';
import {useTranslation} from 'react-i18next';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import styles from './page-header.module.scss';

export default function PageHeader({textTooltip, toBack, title}){
    const {t} = useTranslation();

    return (
        <header className={styles.header}>
            <Tooltip placement='top' title={t(textTooltip)}>
                <IconButton className={styles.iconButton} size='small' onClick={toBack}>
                    <ArrowBackIosNewIcon />
                </IconButton>
            </Tooltip>
            <div className={styles.divider} />
            <h1>{t(title)}</h1>
        </header>
    );
}

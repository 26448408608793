import * as yup from 'yup';

import {MAX_TITLE_LENGTH, MIN_TITLE_LENGTH} from './utils';

export const tutorialSchema = (t) => {
    return yup.object({
        thumbnail: yup.mixed()
            .required(t('REQUIRED_FIELD')),
        video: yup.mixed()
            .required(t('REQUIRED_FIELD')),
        title: yup.string().trim()
            .min(MIN_TITLE_LENGTH, t('MIN_LENGTH', {length: MIN_TITLE_LENGTH}))
            .max(MAX_TITLE_LENGTH, t('MAX_LENGTH', {length: MAX_TITLE_LENGTH}))
            .required(t('REQUIRED_FIELD')),
        captions: yup.array().of(
            yup.object({
                language: yup.string(),
                caption: yup.mixed(),
            }),
        ),
        tags: yup.array()
            .of(yup.string())
            .typeError(t('TUTORIALS_PAGE.SAVE_TAG_MESSAGE')),
        roles: yup.array()
            .of(yup.string())
            .min(1, t('REQUIRED_FIELD')),         
        disabled: yup.boolean()
            .required(t('REQUIRED_FIELD')),
    });
};

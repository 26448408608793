import React from 'react';
import SvgIcon from '@src/components/SvgIcon';
import {useTranslation, Trans} from 'react-i18next';
import {Button, Divider} from '@mui/material';
import {downloadFromLink} from '@src/utils/file-utils';
import {useParams} from 'react-router-dom';

import {useGetUsersTemplateQuery} from '../../importUsers.api';

import styles from './tips.module.scss';

const tips = [
    {
        text: 'IMPORT_USERS.TIP_ONE',
    },
    {
        text: 'IMPORT_USERS.TIP_TWO',
    },
];

export function Tips() {
    const {t} = useTranslation();
    const {accountId} = useParams();
    const {data: template} = useGetUsersTemplateQuery(accountId, {skip: !accountId});

    return (
        <div className={styles.container}>
            <SvgIcon className={styles.icon} name='Lightbulb' />
            <Divider className={styles.divider} orientation='vertical' variant='middle' flexItem />
            <div className={styles.tips}>
                <h1 className={styles.title}>{t('IMPORT_USERS.TIPS_TITLE')}</h1>
                <div className={styles.content}>
                    {tips.map((tip, index) => (
                        <div className={styles.tip} key={index}>
                            <Trans
                                i18nKey={tip.text}
                                components={{bold: <strong className={styles.bold} />, p: <p className={styles.text} />}}
                            />
                        </div>
                    ))}
                </div>
                <Button className={styles.button} variant='outlined' onClick={() => downloadFromLink(template?.url)}>
                    {t('IMPORT_USERS.DOWNLOAD_TEMPLATE')}
                </Button>
            </div>

        </div>
    );
}

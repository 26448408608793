import React, {useEffect, useMemo, useState} from 'react';
import {invalidateTopics, useDeleteTopicMutation, useGetContentQuery} from '@src/modules/trainings/trainings.api';
import {
    Box,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import {ConfirmationModal} from '@src/components/ConfirmationModal';
import {useSnackbar} from 'notistack';
import SvgIcon from '@src/components/SvgIcon';

import {TextEditForm} from '../TextEditForm';
import {VideoEditForm} from '../VideoEditForm';
import {AttachmentsEditForm} from '../AttachmentsEditForm';

import styles from './selected-content-type.module.scss';

export function SelectedTopicContent({trainingId, topic, contentId, setActiveTopic}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPolling, setIsPolling] = useState(false);

    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const {data: content, isFetching, refetch: refetchContent} = useGetContentQuery(
        {trainingId, topicId: topic.id, contentId},
        {
            skip: !trainingId || !topic || !contentId,
            refetchOnMountOrArgChange: true,
        },
    );
    const [deleteTopic, {isLoading: deleteTopicLoading}] = useDeleteTopicMutation();

    useEffect(() => {
        if (content && content.type === 'video' && content.status !== 'uploaded') {
            setIsPolling(true);
            const pollingInterval = 5 * 1000;
            
            const pollingTimer = setInterval(refetchContent, pollingInterval);
            
            return () => {
                clearInterval(pollingTimer);
                setIsPolling(false);
            };
        }
    }, [content]);
    
    const contentForms = useMemo(() => {
        return {
            text: <TextEditForm
                trainingId={trainingId}
                content={content}
                topic={topic}
                setActiveTopic={setActiveTopic}
            />,
            video: <VideoEditForm
                trainingId={trainingId}
                content={content}
                topic={topic}
                setActiveTopic={setActiveTopic}
            />,
            attachments: <AttachmentsEditForm
                trainingId={trainingId}
                content={content}
                topic={topic}
                setActiveTopic={setActiveTopic}
            />,
        };
    }, [content, topic.id]);

    async function handleDeleteTopic() {
        try {
            await deleteTopic({
                trainingId,
                topicId: topic.id,
            }).unwrap();

            enqueueSnackbar(t('TRAININGS_PAGE.DELETE_TOPIC_SUCCESS'), {variant: 'success'});

            invalidateTopics();

            setIsModalOpen(false);
            setActiveTopic(null);
        } catch (error) {
            enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.DELETE_TOPIC_ERROR'), {variant: 'error'});
        } 
    } 

    if (isFetching && !isPolling) {
        return (
            <Box className={styles.loadingContainer}>
                <CircularProgress color='inherit' />
            </Box>
        );
    }

    return (
        <>
            {contentId ? (
                <div className={styles.container}>{contentForms[content.type]}</div>
            ) : (
                <Container maxWidth='md' className={styles.noContentContainer}>
                    <Grid item xs={12}>
                        <div className={styles.titleContainer}>
                            <TextField
                                fullWidth
                                name='title'
                                value={topic.title}
                                label={t('TRAININGS_PAGE.TOPIC_TITLE')}
                                disabled={true}
                            />

                            <Tooltip title={t('TRAININGS_PAGE.DELETE_TOPIC')}>
                                <IconButton onClick={() => setIsModalOpen(true)}>
                                    <DeleteIcon /> 
                                </IconButton>
                            </Tooltip>
                        </div>

                        {isModalOpen && (
                            <ConfirmationModal
                                title={t('TRAININGS_PAGE.DELETE_TOPIC')}
                                description={t('TRAININGS_PAGE.DELETE_TOPIC_DESCRIPTION')}
                                cancelText={t('TRAININGS_PAGE.CANCEL')}
                                confirmText={t('TRAININGS_PAGE.DELETE_TOPIC')}
                                handleClose={() => setIsModalOpen(null)}
                                handleSubmit={handleDeleteTopic}
                                loading={deleteTopicLoading}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <div className={styles.dataErrorContainer}>
                            <SvgIcon name='DataError' className={styles.dataError} />
                            <Typography className={styles.dataErrorText}>
                                {t('TRAININGS_PAGE.NO_CONTENT_TEXT')}
                            </Typography>
                        </div>
                    </Grid>
                </Container>
            )}
        </>
    );
}

import {LoadingButton} from '@mui/lab';
import {Button} from '@mui/material';
import {useCan} from '@src/hooks/useCan';
import {permissionResources, permissionTypes} from '@src/hooks/usePrivateRoutes';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import AccountResources from '@src/modules/accounts/components/AccountResources';

import styles from './modulesEditTab.module.scss';

export function ModulesEditTab({formik, isLoading}) {
    const {t} = useTranslation();

    const can = useCan();
    const navigate = useNavigate();
    const permissionResource = permissionResources.commercialAccounts;

    return (
        <form
            onSubmit={formik.handleSubmit}
            autoComplete='off'>
            <AccountResources
                formik={formik}
                    />
            {can(permissionResource, permissionTypes.edit) && (
                <div className={styles.buttonsContainer}>
                    <Button
                        variant='outlined'
                        disabled={isLoading}
                        onClick={() => navigate(routes.ACCOUNTS)}
                         >
                        {t('ACCOUNTS_PAGE.CANCEL')}
                    </Button>

                    <LoadingButton
                        type='submit'
                        loading={isLoading}
                        disabled={isLoading || !formik.dirty}
                        variant='contained'
                         >
                        {t('ACCOUNTS_PAGE.SAVE')}
                    </LoadingButton>
                </div>
                 )}
        </form>
    );
}

import React from 'react';
import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {InfoCard} from '@src/modules/accountDashboard/components/InfoCard';

import styles from './usersData.module.scss';
export default function UsersData({formik}){
    const {t} = useTranslation();
    return (
        <div className={styles.container}>
            <Typography variant='h6' component='h6' mt={1} mb={2}>
                {t('ACCOUNTS_PAGE.USERS')}
            </Typography>
            <div className={styles.row}>
                <InfoCard
                    className={styles.itemValue}
                    iconName={'Users'}
                    name={t('ACCOUNTS_PAGE.CREATE')}
                    total={formik?.values?.created}
                />
                <InfoCard
                    className={styles.itemValue}
                    iconName={'Users'}
                    name={t('ACCOUNTS_PAGE.ACTIVE')}
                    total={formik?.values?.active}
                />
            </div>
        </div>
    );
}

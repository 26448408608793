import React from 'react';
import {InputAdornment} from '@mui/material';
import {FormikTextField} from '@src/components/FormikFields';

export function IconTextField({startIcon, endIcon, ...rest}) {

    return (
        <FormikTextField
            InputProps={{
                startAdornment: startIcon ? <Adornment icon={startIcon} position='start' /> : null,
                endAdornment: endIcon ? <Adornment icon={endIcon} position='end' /> : null,
            }}
            {...rest}
        />
    );
}

const Adornment = ({icon, position = 'start'}) => (
    <InputAdornment position={position}>
        {icon}
    </InputAdornment>
);

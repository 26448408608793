
import {createTheme} from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#6358eb',
            light: '#8279f2',
            dark: '#563ba4',
            contrastText: '#e9e4e4',
        },
        secondary: {
            main: '#F9AA33',
        },
        background: {
            default: '#181c23',
            paper: '#22262d',
            paperSecondary: '#505866',
        },
        text: {
            primary: '#e9e4e4',
            secondary: '#c5c5cb',
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: '#e9e4e4',
                },
            },
        },
        
        MuiAvatar: {
            styleOverrides: {
                root: {
                    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                },
            },
        },
    },
});

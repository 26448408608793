import {isRejectedWithValue} from '@reduxjs/toolkit';
import {authApi} from '@src/modules/auth/auth.api';
import {logout} from '@src/modules/auth/auth.reducer';
import {apiSlice} from '@src/store/apiSlice';
import CredentialsHelper from '@src/utils/credentialsHelper';

export const expiredTokenErrorHandler = (api) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        const authEndpoints = Object.keys(authApi.endpoints);
        if (action.payload.status === 401 && !authEndpoints.includes(action.meta.arg.endpointName)) {
            CredentialsHelper.clear();

            api.dispatch(logout());
            api.dispatch(apiSlice.util.resetApiState());
            
            return;
        }
    }

    return next(action);
};

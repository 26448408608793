import authReducer from '@modules/auth/auth.reducer';
import {authApi} from '@modules/auth/auth.api';

import {apiSlice} from './apiSlice';

const reducers = {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
};

export default reducers;

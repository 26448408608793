import * as React from 'react';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {IconButton, MenuItem, MenuList} from '@mui/material';

export default function AccountActions({items}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'account-actions' : undefined;

    return (
        <div>
            <IconButton aria-label={id} onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuList id='split-button-menu' autoFocusItem>
                    {items.map((item) => (
                        <MenuItem
                            key={item.id}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                            }}
                            onClick={() => {
                                item.onClick();
                                handleClose();
                            }}
                    >
                            {item.icon} {item.label}
                        </MenuItem>
                  ))}
                </MenuList>
            </Popover>
        </div>
    );
}

import React, {useEffect, useState} from 'react';
import Dropzone from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import Modal from '@src/components/Modal';
import {LoadingButton} from '@mui/lab';
import {InputLabel, MenuItem, Select} from '@mui/material';
import SubtitlesIcon from '@mui/icons-material/Subtitles';

import {captionLanguages, convertToConstant} from '../../utils';

import styles from './new-caption-modal.module.scss';

export function NewCaptionModal({handleClose, captions, submitFunction, loading = false}) {
    const {t} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [languageOptions, setLanguageOptions] = useState(captionLanguages);

    const enableButton = selectedLanguage && selectedFile;

    function onDrop(file) {
        setSelectedFile(file[0]);
    }

    async function handleSubmit() {
        await submitFunction({
            language: selectedLanguage,
            file: selectedFile,
        });
        setSelectedLanguage(null);
        setSelectedFile(null);
        handleClose();
    }

    useEffect(() => {
        const alreadySelectedLanguages = captions.map(caption => caption.language);
        const availableOptions = captionLanguages.filter(caption => !alreadySelectedLanguages.includes(caption));

        setLanguageOptions(availableOptions);
    }, []);

    return (
        <Modal
            title={t('TUTORIALS_PAGE.ADD_NEW_CAPTION')}
            modalWidth='500px'
            onClose={handleClose}
        >
            <div className={styles.modalContent}>
                <div className={styles.modalInputGroup}>
                    <InputLabel htmlFor='caption-language'>{t('TUTORIALS_PAGE.SELECT_CAPTION_LANGUAGE')}</InputLabel>
                    <Select
                        fullWidth
                        labelId='caption-language'
                        id='caption-language'
                        value={selectedLanguage}
                        onChange={({target}) => {
                            setSelectedLanguage(target.value);
                        }}
                    >
                        {languageOptions.map((language) => (
                            <MenuItem
                                key={language}
                                value={language}
                                >
                                {t(`LANGUAGES.${convertToConstant(language)}`)}
                            </MenuItem>
                            ))
                        }
                    </Select>
                </div>

                <div className={styles.modalInputGroup}>
                    <InputLabel htmlFor='caption-file'>{t('TUTORIALS_PAGE.SELECT_CAPTION_FILE')}</InputLabel>

                    <label htmlFor='caption-file' className={styles.captionDropzone}>
                        {selectedFile ? 
                            <p>{selectedFile.name}</p> :
                            <>
                                <SubtitlesIcon />
                                <p>{t('TUTORIALS_PAGE.SELECT_SRT_FILE')}</p>
                            </>
                            }
                    </label>

                    <Dropzone onDrop={onDrop} accept={{'text/plain': ['.srt']}} maxFiles={1}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()}>
                                <input id='caption-file' {...getInputProps()} />
                            </div>
                            )}
                    </Dropzone>
                </div>

                <LoadingButton
                    onClick={handleSubmit}
                    type='button'
                    variant='contained'
                    fullWidth
                    disabled={!enableButton}
                    loading={loading}
                >
                    {t('TUTORIALS_PAGE.ADD_CAPTION')}
                </LoadingButton>
            </div>
        </Modal>
    );
}

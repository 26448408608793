import {useFormik} from 'formik';
import React, {useState} from 'react';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {Container, Grid, Typography} from '@mui/material';
import {FormikTextField} from '@src/components/FormikFields';
import {useCreateContentMutation, useCreateTrainingTopicMutation} from '@src/modules/trainings/trainings.api';
import {topicTextSchema} from '@src/modules/trainings/schema';
import {LoadingButton} from '@mui/lab';

import TextEditor from '../../../../../components/TextEditor';

export function TextForm({trainingId, setActiveTopic}) {
    const [isLoading, setIsLoading] = useState(false);
    const [createTopic] = useCreateTrainingTopicMutation();
    const [createTextContent] = useCreateContentMutation();
    
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const initialValues = {
        title: '',
        value: '',
    };

    const formik = useFormik({
        validationSchema: topicTextSchema(t),
        initialValues,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(form) {
        setIsLoading(true);
        try {
            const {title, value} = form;

            const topicResponse = await createTopic({
                trainingId,
                title,
            }).unwrap();

            const contentResponse = await createTextContent({
                trainingId,
                topicId: topicResponse.id,
                body: {
                    type: 'text',
                    value,
                },
            }).unwrap();

            enqueueSnackbar(t('TRAININGS_PAGE.CREATE_TOPIC_SUCCESS'), {variant: 'success'});
            
            setActiveTopic({
                ...topicResponse,
                content: contentResponse.id,
            });
        } catch (error) {
            enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.CREATE_TOPIC_ERROR'), {variant: 'error'});
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Container maxWidth='md'>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormikTextField
                            fullWidth
                            name='title'
                            label={t('TRAININGS_PAGE.TOPIC_TITLE')}
                            formik={formik}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextEditor 
                            initialValue=''
                            name='value'
                            onChange={(value) => formik.setFieldValue('value', value)} 
                        />
                        
                        {formik?.errors?.value && formik?.touched?.value && (
                            <Typography variant='span' color='#D92F58'>
                                {formik.errors.value}
                            </Typography>
                        )}
                    </Grid>
                    
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth 
                            type='button'
                            variant='contained'
                            loading={isLoading}
                            onClick={formik.handleSubmit}
                        >
                            {t('TRAININGS_PAGE.CREATE_TOPIC')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}

import {apiSlice} from '@src/store/apiSlice';

const adminsTags = {
    adminsTags: 'ADMINS',
    listAbstractTag: {type: 'ADMINS', id: 'LIST'},
};

const adminsApiSlice = apiSlice
    .enhanceEndpoints({addTagTypes: Object.values(adminsTags)})
    .injectEndpoints({
        endpoints: (builder) => ({
            getAdmins: builder.query({
                query: () => {
                    return {
                        url: '/users',
                        method: 'GET',
                    };
                },
                providesTags: (result) => result
                    ? [...result.map(({id}) => ({type: adminsTags.adminsTags, id})), adminsTags.listAbstractTag]
                    : [adminsTags.listAbstractTag],
            }),
            addAdmins: builder.mutation({
                query: ({body}) => {
                    const {name, email, password, permissions, disabled} = body;
                    return {
                        url: '/users',
                        method: 'POST',
                        body: {
                            name,
                            email,
                            password,
                            permissions,
                            disabled,
                        },
                    };
                },
                invalidatesTags: [adminsTags.listAbstractTag],
            }),
            getAdmin: builder.query({
                query: ({adminId}) => ({url: `/users/${adminId}`}),
                providesTags: (result, _, {adminId}) => [{type: adminsTags.adminsTags, id: adminId}],
            }),
            editAdmin: builder.mutation({
                query: ({adminId, body}) => {
                    return {
                        url: `users/${adminId}`,
                        method: 'PUT',
                        body,
                    };
                },
                invalidatesTags: (result, _, {adminId}) => result ? [
                    {type: adminsTags.adminsTags, id: adminId}, adminsTags.listAbstractTag,
                ] : [],
            }),
            toggleDisabled: builder.mutation({
                query: ({id, body}) => ({
                    url: `/users/${id}/disabled`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: [adminsTags.listAbstractTag],
            }),
            getMe: builder.query({
                query: () => ({url: '/users/me'}),
            }),
        }),
        
        overrideExisting: false,
    });

export const {
    useAddAdminsMutation,
    useGetAdminsQuery,
    useGetAdminQuery,
    useGetMeQuery,
    useEditAdminMutation,
    useToggleDisabledMutation,
} = adminsApiSlice;

import {Save} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import React, {useState} from 'react';
import {Chip, Autocomplete, IconButton, Tooltip, Box} from '@mui/material';
import {FormikTextField} from '@src/components/FormikFields';

import styles from './category-tags-field.module.scss';

export function CategoryTagsField({
    formik,
    name,
    tagsOptions,
    tags,
    setTags,
    readOnly,
    disabled,
    label,
}) {
    const [tagToAdd, setTagToAdd] = useState('');
    const {t} = useTranslation();
    
    const transformArrayInLowerCase = (array) => {
        if (!array) {
            return [];
        } 
        return array.map((element) => element?.toLowerCase());
    };
    
    const handleAddTag = (_, value) => {
        const tagsValues = value?.map(tag => tag.trim()).filter(tag => tag != '');
    
        setTags(tagsValues || [...tags, tagToAdd]);

        formik.setFieldValue(name, tagsValues);
        setTagToAdd('');
    };

    const handleDeleteTag = (tagToDelete) => {
        setTags(tags.filter(tag => tag !== tagToDelete));
    };

    const handleButtonClick = () => {
        const enterEvent = new KeyboardEvent('keydown', {key: 'Enter', keyCode: 13, bubbles: true});
        const inputElement = document.querySelector('.MuiAutocomplete-inputRoot');
        inputElement.dispatchEvent(enterEvent); 
    };

    return (
        <div className={styles.container}>
            <Autocomplete
                multiple
                freeSolo
                options={transformArrayInLowerCase(tagsOptions)}
                onInputChange={(event, value) => setTagToAdd(value)}
                onChange={handleAddTag}
                value={transformArrayInLowerCase(tags)}
                readOnly={readOnly}
                disabled={disabled}
                disableClearable
                renderTags={(tagValue, getTagProps) => 
                    tagValue.map((tag, index) => (
                    (tag.trim() !== '') && 
                        <Chip
                            key={index}
                            label={tag.trim()}
                            onDelete={() => handleDeleteTag(tag)}
                            {...getTagProps({index})}
                        />
                    ))
                }
                renderInput={(params) => (
                    <Box className={styles.box}>
                        <FormikTextField
                            {...params}
                            fullWidth
                            label={label}
                            variant='outlined'
                            formik={formik}
                            name={name}
                        /> 
                        <IconButton
                            edge='end'
                            onClick={handleButtonClick}
                            sx={{height: '40px'}}
                            disabled={tagToAdd.trim() === ''}
                        >
                            <Tooltip title={t('TRAININGS_PAGE.SAVE')}>
                                <Save />
                            </Tooltip>
                        </IconButton>
                    </Box>
                )}
            />
        </div>
    );
}

import React, {useState} from 'react';
import {Lock, Visibility, VisibilityOff} from '@mui/icons-material';
import {IconButton, InputAdornment} from '@mui/material';
import {FormikTextField} from '@src/components/FormikFields';

export function PasswordTextField(props) {
    const [show, setShow] = useState();
    return (
        <FormikTextField
            type={show ? 'text' : 'password'}
            InputProps={{
                startAdornment: (
                    <InputAdornment position='start'>
                        <Lock />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => setShow(!show)}
                            edge='end'
                        >
                            {show ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
}

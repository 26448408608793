import {apiSlice} from '@src/store/apiSlice';

const asyncTasks = {
    asyncTasks: 'ASYNC_TASKS',
};

const asyncTasksApiSlice = apiSlice
    .enhanceEndpoints({addTagTypes: Object.values(asyncTasks)})
    .injectEndpoints({
        endpoints: (builder) => (
            {getAsyncTasks: builder.query({
                query: ({accountId, asyncTaskId}) => ({url: `/accounts/trainings/${accountId}/async-tasks/${asyncTaskId}`}),
                transformResponse: (response) => ({...response, data: response.data !== '' ? JSON.parse(response.data) : response.data}),
                providesTags: (_result, _error, {asyncTaskId}) => [{type: asyncTasks.asyncTasks, id: asyncTaskId}],
            })}),
        overrideExisting: false,
    });
export const {useGetAsyncTasksQuery} = asyncTasksApiSlice;

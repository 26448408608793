export const MIN_TITLE_LENGTH = 1;
export const MAX_TITLE_LENGTH = 80;

export const MIN_CAPTIONS_LENGTH = 1;
export const MAX_CAPTIONS_LENGTH = 8192;

export const MIN_TAGS_LENGTH = 1;

export const captionLanguages = [
    'Arabic',
    'Cantonese',
    'Catalan',
    'Chinese',
    'Danish',
    'Dutch',
    'English',
    'Finnish',
    'French',
    'German',
    'Greek',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Korean',
    'Malayalam',
    'Mandarin Chinese',
    'Norwegian',
    'Polish',
    'Portuguese',
    'Romanian',
    'Russian',
    'Spanish',
    'Swedish',
    'Taiwanese Mandarin',
    'Tamil',
    'Thai',
    'Turkish',
    'Ukrainian',
    'Urdu',
    'Vietnamese',
    'Welsh',
    'Zulu',
];

export function convertToConstant(text) {
    return text.toUpperCase().split(' ').join('_');
}

export function generateCaptionFormData({file, language}) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('language', language);

    return formData;
}

export function generateFileUrl(file) {
    return URL.createObjectURL(file);
}

export const rolesOptions = [{
    label: 'TUTORIALS_PAGE.MANAGER',
    value: 'manager',
}, {
    label: 'TUTORIALS_PAGE.EMPLOYEE',
    value: 'employee',
}, {
    label: 'TUTORIALS_PAGE.RH',
    value: 'rh',
}, {
    label: 'TUTORIALS_PAGE.ADMIN',
    value: 'admin',
}];

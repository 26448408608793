import * as yup from 'yup';

export const loginSchema = (t) => yup.object({
    username: yup.string().trim()
        .email(t('INVALID_EMAIL'))
        .required(t('REQUIRED_FIELD')),
    password: yup.string().trim().required(t('REQUIRED_FIELD')),
});

export const changePasswordSchema = (t) => yup.object({
    username: yup.string().trim()
        .email(t('INVALID_EMAIL'))
        .required(t('REQUIRED_FIELD')),
});

export const insertCodeSchema = (t) => yup.object({
    code: yup.string().trim()
        .required(t('REQUIRED_FIELD'))
        .length(6, t('FILL_ALL_FIELDS')),
});

export const newPasswordSchema = (t) => yup.object({
    password: yup.string().trim().required(t('REQUIRED_FIELD')),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password')], t('PASSWORD_MUST_BE_EQUAL'))
        .required(t('REQUIRED_FIELD')),
});

import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import React, {useRef, useState} from 'react';
import {
    Button,
    Container,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import SvgIcon from '@src/components/SvgIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import {FormikTextField} from '@src/components/FormikFields';
import {ATTACHMENT_MAX_SIZE_ALLOWED, attachmentsAcceptedFiles} from '@src/modules/trainings/utils';
import {useCreateContentMutation, useCreateTrainingTopicMutation, useLazyGetAttachmentContentUrlQuery} from '@src/modules/trainings/trainings.api';
import {uploadAttachmentFile} from '@src/modules/uploads/uploadAttachment';
import {topicAttachmentsSchema} from '@src/modules/trainings/schema';
import {LoadingButton} from '@mui/lab';

import styles from './attachments-form.module.scss';

export function AttachmentsForm({trainingId, setActiveTopic}) {
    const [isLoading, setIsLoading] = useState(false);
    
    const {t} = useTranslation();
    const attachmentInputRef = useRef();
    const {enqueueSnackbar} = useSnackbar();

    const [createTopic] = useCreateTrainingTopicMutation();
    const [createAttachmentsContent] = useCreateContentMutation();
    const [getAttachmentUploadUrl] = useLazyGetAttachmentContentUrlQuery();

    const initialValues = {
        title: '',
        attachments: [],
    };

    const formik = useFormik({
        validationSchema: topicAttachmentsSchema(t),
        initialValues,
        onSubmit: handleSubmit,
    });

    function deleteAttachment(index) {
        const attachments = formik.values.attachments;
        const updatedAttachments = attachments.filter(item => item !== attachments[index]);
        formik.setFieldValue('attachments', updatedAttachments);
    }

    function addAttachment(file) {
        if (file) {
            const updatedAttachments = [...formik.values.attachments, file];
            formik.setFieldValue('attachments', updatedAttachments);
        }
    }

    async function uploadAttachment({
        data,
        topicId,
        contentId,
    }) {
        if (!data) {
            return;
        }
    
        const filteredData = data.filter(item => !item.id);
    
        const promises = filteredData.map(async (file) => {
            try {
                const params = {
                    ContentType: file?.type,
                    ContentLength: file?.size,
                    FileName: file?.name,
                    ContentDisposition: `inline; filename="${file?.name}"`,
                };
    
                if (file?.size > ATTACHMENT_MAX_SIZE_ALLOWED) {
                    enqueueSnackbar(t('TRAININGS_PAGE.ATTACHMENTS_ERROR', {fileName: file?.name}), {variant: 'error'});
                } else {
                    const attachments = await getAttachmentUploadUrl({trainingId, topicId, contentId, params}).unwrap();
                    await uploadAttachmentFile(attachments.url, attachments.fields, file, 'inline');
                }
    
            } catch (error) {
                enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.CREATE_ATTACHMENTS_ERROR'), {variant: 'error'});
            }
        });
    
        await Promise.all(promises);
    }

    function formSubmit(e)  {
        e.preventDefault();
        formik.handleSubmit();
    }

    async function handleSubmit(form) {
        setIsLoading(true);
        try {
            const {title, attachments} = form;

            if (attachments.length === 0) {
                return enqueueSnackbar(t('TRAININGS_PAGE.ADD_LEAST_ONE_ATTACHMENT'), {variant: 'error'});
            }

            const topicResponse = await createTopic({
                trainingId,
                title,
            }).unwrap();

            const contentResponse = await createAttachmentsContent({
                trainingId,
                topicId: topicResponse.id,
                body: {
                    type: 'attachments',
                    value: {},
                },
            }).unwrap();

            await uploadAttachment({
                topicId: topicResponse.id,
                contentId: contentResponse.id,
                data: attachments,
            });

            setActiveTopic({
                ...topicResponse,
                content: contentResponse.id,
            });
        } catch (error) {
            enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.CREATE_ERROR'), {variant: 'error'});
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Container maxWidth='md'>
            <form onSubmit={formSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormikTextField
                            fullWidth
                            name='title'
                            label={t('TRAININGS_PAGE.TOPIC_TITLE')}
                            formik={formik}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <div className={styles.selectFileContainer}>
                            <Button variant='contained' onClick={() => attachmentInputRef.current.click()}>
                                {t('TRAININGS_PAGE.UPDATE_ATTACHMENT')}
                            </Button>

                            <Typography variant='body1'>{t('TRAININGS_PAGE.SELECT_ATTACHMENTS_FILE_TYPES')}</Typography>
                        </div>

                        <ul className={styles.attachmentsList}>
                            {formik.values.attachments.map((file, index) => (
                                <li key={`${new Date()}-${file.name}`} className={styles.attachmentsItem}>
                                    <div className={styles.attachmentDetails}>
                                        <SvgIcon 
                                            name={String(file.type).startsWith('image') ? 'Image' : 'FileDoc'} 
                                            className={styles.attachmentIcon}
                                            />

                                        <Typography>{file.name}</Typography>
                                    </div>

                                    <IconButton onClick={() => deleteAttachment(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </li>
                                ))}
                        </ul>

                        <input
                            ref={attachmentInputRef}
                            id='attachment' 
                            name='attachment'
                            type='file'
                            accept={attachmentsAcceptedFiles} 
                            className={styles.attachmentInput}
                            onChange={(event) => {
                                const file = event.target.files[0];
                                addAttachment(file);
                            }}
                        />
                    </Grid>
                    
                    <Grid item xs>
                        <LoadingButton 
                            fullWidth
                            type='submit'
                            variant='contained'
                            loading={isLoading}
                        >
                            {t('TRAININGS_PAGE.CREATE_TOPIC')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}

import {Button, Container, Grid, IconButton, InputAdornment} from '@mui/material';
import PageHeader from '@src/components/PageHeader';
import {adminsEditSchema} from '@src/modules/admins/schema';
import {routes} from '@src/utils/routes';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {FormikTextField} from '@src/components/FormikFields';
import {LoadingButton} from '@mui/lab';
import {Edit, Visibility, VisibilityOff} from '@mui/icons-material';
import {useEditAdminMutation, useGetAdminQuery} from '@src/modules/admins/admins.api';
import {useSnackbar} from 'notistack';
import CheckBox from '@src/components/Checkbox';
import {formatPermissionValue, permissions} from '@src/modules/admins/utils';

import styles from './admins-edit.module.scss';

export default function EditAdmins() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [editMode, setEditMode] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const {adminId} = useParams();
    const {data, isLoading: adminLoading} = useGetAdminQuery(
        {adminId},
        {skip: !adminId},
    );
    const [editAdmin] = useEditAdminMutation();

    const toggleShowPassword = () => {
        setShowPassword(old => !old);
    };

    const initialValues = {
        name: '',
        email: '',
        password: '',
        permissions: data?.permissions,
        disabled: data?.disabled,
    };

    const formSubmit = (e) => {

        e.preventDefault();
        formik.handleSubmit();
    };
    const formik = useFormik({
        validationSchema: adminsEditSchema(t),
        initialValues,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(form) {
        try {
            const formattedPermissions = {};
            Object.keys(form.permissions).forEach(item => {
                formattedPermissions[item] = formatPermissionValue(form.permissions[item]);
            });
            const payload = {
                ...form,
                password: form.password || undefined,
                permissions: formattedPermissions,
            };
            await editAdmin ({
                adminId: data?.id,
                body: payload,
            }).unwrap();
            enqueueSnackbar(t('ADMINS.EDIT_SUCCESS'), {variant: 'success'});
            navigate(routes.ADMINS);
        } catch (error) {
            if (error.status === 409){
                enqueueSnackbar(t('ADMINS.EMAIL_ERROR'), {variant: 'error'});
            }
            enqueueSnackbar(t('ADMINS.EDIT_ERROR'), {variant: 'error'});
        }    
    }

    function handlePermission(field, permission) {
        if (formik?.values?.permissions?.[field] && formik?.values?.permissions?.[field].includes(permission)) {
            let updatedPermission = formik.values.permissions[field].filter(item => item != permission);
            formik.setFieldValue(`permissions.${field}`, updatedPermission);
        } else if (formik?.values?.permissions?.[field]) {
            formik.setFieldValue(`permissions.${field}`, [...formik.values.permissions[field], permission]);
        } else {
            formik.setFieldValue(`permissions.${field}`, [permission]);
        }
    }
    
    function resourcesPermissions(field) {
        const permissions = data?.permissions[field] ? data?.permissions?.[field]?.split(',').map((x) => x.trim()) : [];
        const hasConsult = permissions.includes('list') && permissions.includes('detail');

        const withoutConsult = permissions.filter(p => p !== 'list' && p !== 'detail');

        return hasConsult ? [...withoutConsult, 'consult'] : withoutConsult;
    }
    useEffect(() => {
        if (data){
            formik.setValues({
                name: data?.name,
                email: data?.email,
                permissions: {
                    admins: resourcesPermissions('admins') || '',
                    trainings: resourcesPermissions('trainings') || '',
                    tutorials: resourcesPermissions('tutorials') || '',
                    commercialAccounts: resourcesPermissions('commercialAccounts') || '',
                    trainingAccounts: resourcesPermissions('trainingAccounts') || '',
                    faq: resourcesPermissions('faq') || '',
                    notes: resourcesPermissions('notes') || '',
                    boletos: resourcesPermissions('boletos') || '',
                },
                disabled: data?.disabled,
            });
        }
    }, [data]);
    
    return (
        <Container maxWidth='xl'>
            <PageHeader
                title='ADMINS.EDIT'
                textTooltip={t('ADMINS.BACK')}
                toBack={() => navigate(routes.ADMINS)} 
    />

            <main>
                <Container>
                    <form onSubmit={formSubmit} className={styles.form} autoComplete='off'>
                        <Grid className={styles.container}>
                            <Grid item xs={12}>
                                <FormikTextField
                                    fullWidth
                                    name='name'
                                    type='text'
                                    label={t('ADMINS.NAME')}
                                    formik={formik}
                                    readOnly={!editMode}
                                    disabled={!editMode}
                                    loading={adminLoading.toString()}

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField
                                    fullWidth
                                    name='email'
                                    type='email'
                                    label={t('ADMINS.EMAIL')}
                                    formik={formik}
                                    readOnly={!editMode}
                                    disabled={!editMode}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField
                                    fullWidth
                                    formik={formik}
                                    name='password'
                                    label={t('PASSWORD')}
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete='new-password'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    onClick={toggleShowPassword}
                                                    edge='end'
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    readOnly={!editMode}
                                    disabled={!editMode}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h2>{t('ADMINS.PERMISSIONS')}</h2>
                                <div className={styles.containerRow}>
                                    {permissions.map((permission, key) => {
                                        return (
                                            <div className={styles.section} key={key}>
                                                <div className={styles.sectionHeader}>
                                                    <h3 className={styles.title}>{t(permission.title)}</h3>
                                                </div>
                                                <div className={styles.sectionContent}>
                                                    <CheckBox 
                                                        name={`${permission.name}-manage`}
                                                        label={t('ADMINS.MANAGE')}
                                                        onChange={() =>  handlePermission(permission.name, 'manage')}
                                                        readOnly={!editMode}
                                                        disabled={!editMode}
                                                        checked={formik?.values?.permissions?.[permission.name]?.includes('manage') || false}
                                                    />
                                                    {!permission.onlyManage &&
                                                        <>
                                                            <CheckBox
                                                                name={`${permission.name}-consult`}
                                                                label={t('ADMINS.CONSULT')}
                                                                onChange={() =>  handlePermission(permission.name, 'consult')}
                                                                readOnly={!editMode}
                                                                disabled={!editMode}
                                                                checked={formik?.values?.permissions?.[permission.name]?.includes('consult') || false}
                                                            />
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })}
                                    
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {!editMode ? (
                                <div className={styles.editButton}>
                                    <Button 
                                        startIcon={<Edit />} 
                                        variant='outlined' 
                                        color='secondary' 
                                        onClick={() => setEditMode(true)} 
                                        fullWidth
                                    >
                                        {t('ADMINS.ENABLE_EDITING')}
                                    </Button>
                                </div>
                            )
                        :
                                <div className={styles.buttonWraper}>
                                    <Button
                                        variant='outlined'
                                        type='button'
                                        fullWidth
                                        onClick={() => setEditMode(false)}
                                        >
                                        {t('ADMINS.CANCEL')}
                                    </Button>
                                    <LoadingButton
                                        type='submit'
                                        variant='contained'
                                        fullWidth
                            >
                                        {t('ADMINS.SAVE')}
                                    </LoadingButton>
                                </div>}    
                        </Grid>
                    </form>
                </Container>
            </main>
        </Container>  
    );
}

import cx from 'classnames';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Button, CircularProgress, Container, IconButton, Typography} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PageHeader from '@src/components/PageHeader';
import {routes} from '@src/utils/routes';
import {useGetTrainingQuery, useGetTrainingTopicsQuery} from '@src/modules/trainings/trainings.api';
import {useCan} from '@src/hooks/useCan';
import {permissionTypes, permissionResources} from '@src/hooks/usePrivateRoutes';

import {TextForm} from './components/TextForm';
import {ContentTypeCard} from './components/ContentTypeCard';
import {VideoForm} from './components/VideoForm';
import {AttachmentsForm} from './components/AttachmentsForm';
import {SelectedTopicContent} from './components/SelectedTopicContent';
import styles from './trainings-topics.module.scss';

export default function TrainingsTopics() {
    const [activeTopic, setActiveTopic] = useState(null);
    const [newTopicType, setNewTopicType] = useState(null);
    const can = useCan();

    const {trainingId} = useParams();

    const {
        data: training,
        isLoading: isTrainingLoading,
    } = useGetTrainingQuery({trainingId}, {skip: !trainingId});
    const {data: topics, isTopicsLoading} = useGetTrainingTopicsQuery({trainingId}, {skip: !trainingId});

    const {t} = useTranslation();
    const navigate = useNavigate();

    const contentForms = useMemo(() => {
        return {
            text: <TextForm 
                trainingId={trainingId}
                setActiveTopic={(newTopic) => {
                    setNewTopicType(null);
                    setActiveTopic(newTopic);
                }} 
            />,
            video: <VideoForm 
                trainingId={trainingId}
                setActiveTopic={(newTopic) => {
                    setNewTopicType(null);
                    setActiveTopic(newTopic);
                }} 
            />,
            attachments: <AttachmentsForm 
                trainingId={trainingId}
                setActiveTopic={(newTopic) => {
                    setNewTopicType(null);
                    setActiveTopic(newTopic);
                }} 
            />,
        };
    }, []);
    
    return (
        <Container maxWidth='xl' className={styles.container}>
            <PageHeader
                title='TRAININGS_PAGE.TOPICS'
                textTooltip={t('TRAININGS_PAGE.BACK')}
                toBack={() => navigate(routes.TRAININGS)} 
            />
                
            {isTopicsLoading || isTrainingLoading ? (
                <Box 
                    sx={{
                        height: 120,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress color='inherit' />
                </Box>
            ) : (
                <main className={cx(styles.main, {[styles.newTopic]: !!newTopicType})}>
                    <div className={cx(styles.topicsContainer, {[styles.newTopic]: !!newTopicType})}>
                        <Typography variant='h6' className={styles.contentName}>{training.title}</Typography>
                    
                        {can(permissionResources.trainings, permissionTypes.edit) && <Button 
                            size='small'
                            variant='outlined'
                            className={styles.addTopicButton}
                            onClick={() => setActiveTopic(null)}
                        >
                            <AddCircleOutlineIcon className={styles.addTopicIcon} />  {t('TRAININGS_PAGE.ADD_TOPIC')}
                        </Button>}

                        <div className={styles.listWrapper}>
                            {topics?.length === 0 ? (
                                <Typography sx={{textAlign: 'center'}}>
                                    {t('TRAININGS_PAGE.CREATE_FIRST_TOPIC')}
                                </Typography>
                            ) : (
                                <ul className={styles.topicsList}>
                                    {topics?.map(topic => (
                                        <li 
                                            key={topic.id}
                                            className={cx(styles.topicsItem, {[styles.isActive]: activeTopic?.id === topic.id})}
                                            onClick={() => setActiveTopic(topic)}
                                        >
                                            {topic.title}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>

                    <div className={styles.content}>
                        {activeTopic ? (
                            <SelectedTopicContent 
                                trainingId={trainingId}
                                topic={activeTopic}
                                contentId={activeTopic.content}
                                setActiveTopic={setActiveTopic}
                            />
                        ) :
                            newTopicType ? (
                                <div className={styles.newTopicContainer}>
                                    <header className={styles.formHeader}>
                                        <IconButton 
                                            className={styles.contentTypeBackButton}
                                            size='small'
                                            onClick={() => setNewTopicType(null)}
                                        >
                                            <ArrowBackIosIcon className={styles.contentTypeBackIcon} />
                                        </IconButton>

                                        <Typography variant='h5' className={styles.formTitle}>{t('TRAININGS_PAGE.ADD_A')}{' '}{t(`TRAININGS_PAGE.${String(newTopicType).toUpperCase()}`)}</Typography>

                                        <div className={styles.emptySpace} />
                                    </header>

                                    {contentForms[newTopicType]}
                                </div> 
                            ) : <NewContent setNewTopicType={setNewTopicType} />
                        }
                    </div>
                </main>
            )}
        </Container>
    );
}

const NewContent = ({setNewTopicType}) => {
    const can = useCan();
    const {t} = useTranslation();
    const canCreate = can(permissionResources.trainings, permissionTypes.edit);
    return (
        <div className={styles.selectContentTypeContainer}>
            <Typography variant='h5'>{canCreate ? t('TRAININGS_PAGE.SELECT_CONTENT_TYPE') : 'Selecione um tópico ao lado'}</Typography>
        
            {canCreate && <div className={styles.contentTypes}>
                <ContentTypeCard 
                    iconName='Text'
                    typeLabel={t('TRAININGS_PAGE.TEXT')} 
                    onSelect={() => setNewTopicType('text')} 
                />
                <ContentTypeCard 
                    iconName='Play'
                    typeLabel={t('TRAININGS_PAGE.VIDEO')} 
                    onSelect={() => setNewTopicType('video')} 
                />
                <ContentTypeCard 
                    iconName='UploadSimple'
                    typeLabel={t('TRAININGS_PAGE.ATTACHMENTS')} 
                    onSelect={() => setNewTopicType('attachments')} 
                />
            </div>}
        </div>
    );
};

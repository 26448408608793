import React from 'react';
import {useTranslation} from 'react-i18next';
import SvgIcon from '@src/components/SvgIcon';
import Divider from '@src/components/Divider';
import ErrorIcon from '@mui/icons-material/Error';
import {importStatus} from '@src/modules/async-tasks/utils';
import {UploadButton} from '@src/modules/importUsers/components/UploadButton';

import styles from './returned-logs.module.scss';

export default function ReturnedLogs({
    logs,
    countSuccess,
    countErrors,
    importData,
    translationsImportErros,
    translationImportType,
}) {
    return (
        <>
            <div className={styles.content}>
                <div className={styles.details}>
                    {logs.length > 0 && 
                        <>
                            <SummaryOfInformation amount={countSuccess} logsAmount={logs.length} translationImportType={translationImportType} />
                            <Divider className={styles.divider} />
                        </>
                    }
                    <SummaryOfInformation error amount={countErrors} logsAmount={logs.length} translationImportType={translationImportType} />
                    {logs.length > 0 && <LogDetails logs={logs} translationsImportErros={translationsImportErros} />}
                </div>
            </div>
            <UploadButton importData={importData} />
        </>
    );
}

function SummaryOfInformation({error, amount, logsAmount, translationImportType}) {
    const {t} = useTranslation();

    return (
        <div className={styles.containerSummary}>
            <div className={styles.contentSummary}>
                {error ? (
                    <ErrorIcon
                        className={styles.icon}
                        color='error'
                    />
                ) : (
                    <SvgIcon
                        name='SmallGreenCheck'
                        className={styles.icon}
                    />
                )}
                <h1 className={styles.amount}>
                    {logsAmount > 0 ? `${amount}${t(translationImportType.type)}` : t('IMPORT_USERS.NO_USERS')}
                </h1>
            </div>
            {logsAmount > 0 &&
                <span className={styles.text}>{t(error ? 'IMPORT_USERS.ERROR_TEXT' : 'IMPORT_USERS.SUCCESS_TEXT')}</span>
            }
            
        </div>
    );
}

function LogDetails({logs, translationsImportErros}) {
    const {t} = useTranslation();

    return (
        <div className={styles.logDetailsContainer}>
            <header className={styles.header}>
                <span className={styles.headerTitle}>{t('IMPORT_USERS.DESCRIPTION')}</span>
                <span>{t('IMPORT_USERS.ROW')}</span>
                <span>{t('IMPORT_USERS.COLUMN')}</span>
            </header>
            <Divider className={styles.divider} />
            <main className={styles.logDetailsMain}>
                {logs?.map((log, index) => (
                    log?.rowStatus === importStatus.error && (
                        <div key={index} className={styles.detail}>
                            <span className={styles.description}>{t(translationsImportErros[log?.rowStatusMessage])}</span>
                            <span>{log?.rowNum}</span>
                            <span>{log?.column}</span>
                        </div>
                    )
                ))}
            </main>
        </div>
    );
}

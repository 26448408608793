import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {
    Button,
    ButtonGroup,
    Container,
    Skeleton,
    Typography,
    Autocomplete,
    TextField,
} from '@mui/material';
import {InfoCard} from '@src/modules/accountDashboard/components/InfoCard';
import {
    useGetCoachingRegistersCountQuery,
    useGetEvaluationsCountQuery,
    useGetFeedbacksCountQuery,
    useGetLastActiveQuery,
    useGetReprimandsCountQuery,
    useGetSectorsQuery,
    useGetSuspensionsCountQuery,
    useGetTrainingsCountQuery,
    useGetUsersCountQuery,
} from '@src/modules/accountDashboard/accounts.api';
import {useListAllQuery} from '@src/modules/accounts/accounts.api';
import {accountTypes} from '@src/modules/accounts/schema';
import {routes} from '@src/utils/routes';
import * as moment from 'moment';

import styles from './account-dashboard.module.scss';
import {UsersAccessChart} from './UsersAccessChart';

export default function AccountDashboard() {
    const {t} = useTranslation();
    const {accountType, accountId} = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('generalInfo');

    const idWithType = `${accountType}/${accountId}`;

    const {data: accountsData, isLoading} = useListAllQuery({type: accountType});
    const {data: usersCount, isLoading: userLoading} = useGetUsersCountQuery({id: idWithType}, {skip: !idWithType});
    const {data: reprimandsCount, isLoading: reprimandsLoading} = useGetReprimandsCountQuery({id: idWithType}, {skip: !idWithType});
    const {data: feedbacksCount, isLoading: feedbacksLoading} = useGetFeedbacksCountQuery({id: idWithType}, {skip: !idWithType});
    const {data: coachingRegistersCount, isLoading: coachingRegistersLoading} = useGetCoachingRegistersCountQuery({id: idWithType}, {skip: !idWithType});
    const {data: suspensionsCount, isLoading: suspensionsLoading} = useGetSuspensionsCountQuery({id: idWithType}, {skip: !idWithType});
    const {data: evaluationsCount, isLoading: evaluationsLoading} = useGetEvaluationsCountQuery({id: idWithType}, {skip: !idWithType});
    const {data: trainingsCount, isLoading: trainingsLoading} = useGetTrainingsCountQuery({id: idWithType}, {skip: !idWithType});
    const {data: lastActive, isLoading: lastActiveLoading} = useGetLastActiveQuery({id: idWithType}, {skip: !idWithType});
    const {data: sectors, isLoading: sectorsLoading} = useGetSectorsQuery({id: idWithType}, {skip: !idWithType});

    const dataIsLoading =
        userLoading ||
        feedbacksLoading ||
        reprimandsLoading ||
        coachingRegistersLoading ||
        suspensionsLoading ||
        evaluationsLoading ||
        trainingsLoading ||
        lastActiveLoading ||
        sectorsLoading;

    const cardsInfo = useMemo(() => [{
        iconName: 'Users',
        name: 'ACCOUNT_DASHBOARD_PAGE.USERS',
        total: usersCount?.created || 0,
        users: usersCount?.active,
    }, {
        iconName: 'WarningCircle',
        name: 'ACCOUNT_DASHBOARD_PAGE.REPRIMAND',
        total: reprimandsCount?.count || 0,
    }, {
        iconName: 'FeedbackHeart',
        name: 'ACCOUNT_DASHBOARD_PAGE.FEEDBACKS',
        total: feedbacksCount?.count || 0,
    }, {
        iconName: 'Target',
        name: 'ACCOUNT_DASHBOARD_PAGE.COACH_PROCESS',
        total: coachingRegistersCount?.count || 0,
    }, {
        iconName: 'Notepad',
        name: 'ACCOUNT_DASHBOARD_PAGE.APE',
        total: evaluationsCount?.ape || 0,
    }, {
        iconName: 'ChartBar',
        name: 'ACCOUNT_DASHBOARD_PAGE.EVALUATIONS',
        total: Number(evaluationsCount?.multidirectional) + Number(evaluationsCount?.['decision-matrix']) || 0,
    }, {
        iconName: 'TrafficCone',
        name: 'ACCOUNT_DASHBOARD_PAGE.SUSPENSION',
        total: suspensionsCount?.count || 0,
    }, {
        iconName: 'Tv',
        name: 'ACCOUNT_DASHBOARD_PAGE.TRAININGS',
        total: trainingsCount?.count || 0,
    }, {
        iconName: 'ClockCounterClockwise',
        name: 'ACCOUNT_DASHBOARD_PAGE.LAST_ACCESS',
        totalWithoutLabel: lastActive?.lastActivity ? moment(lastActive?.lastActivity).format('L HH:mm') : t('ACCOUNT_DASHBOARD_PAGE.NO_ACCESS'),
    }, {
        iconName: 'TreeStructure-setor',
        name: 'ACCOUNT_DASHBOARD_PAGE.SECTOR_QUANTITY',
        total: sectors?.quantity || 0,
    }], [
        reprimandsCount,
        feedbacksCount,
        coachingRegistersCount,
        suspensionsCount,
        evaluationsCount,
    ]);

    function handleChangeAccount(accountId) {
        navigate(routes.ACCOUNT_DASHBOARD(accountType, accountId));
    }

    const accountsOptions = useMemo(
        () =>  accountsData
            ?.filter(item => item.name)
            ?.sort((a, b) => a.name.localeCompare(b.name))
            ?.map(account => {
                const isCommercialAccount = accountType ===  accountTypes.commercials;
                return {
                    label: `${account?.name}${account?.cnpj && isCommercialAccount ? ` - ${account.cnpj}` : ''}`,
                    value: String(account?.id),
                };
            }),
        [accountsData],
    );

    const currentAccount = useMemo(() => accountsOptions?.find(account => account?.value === accountId), [accountsOptions, accountId]);

    return (
        <Container maxWidth='xl' className={styles.container}>
            <header className={styles.header}>
                <Typography variant='h4' component='h1'>
                    {t('ACCOUNT_DASHBOARD_PAGE.NAME')}
                </Typography>

                {isLoading ? (
                    <Skeleton variant='rounded' width={210} height={56} />
                ) : (
                    <Autocomplete
                        disablePortal
                        disableClearable
                        options={accountsOptions}
                        value={currentAccount}
                        defaultValue={currentAccount}
                        onChange={(_event, newValue) => handleChangeAccount(newValue.value)}
                        className={styles.accountsSelect}
                        noOptionsText={t('ACCOUNT_DASHBOARD_PAGE.NO_OPTIONS')}
                        renderInput={(params) => <TextField {...params} />}
                    />
                )}
            </header>

            <main className={styles.main}>
                {accountType === accountTypes.commercials && (
                    <ButtonGroup variant='outlined' className={styles.tabContainer}>
                        <Button
                            variant={activeTab === 'generalInfo' ? 'contained' : 'outlined'}
                            onClick={() => setActiveTab('generalInfo')}
                    >
                            {t('ACCOUNT_DASHBOARD_PAGE.GENERAL_INFO')}
                        </Button>
                        <Button
                            variant={activeTab === 'userAccess' ? 'contained' : 'outlined'}
                            onClick={() => setActiveTab('userAccess')}
                    >
                            {t('ACCOUNT_DASHBOARD_PAGE.USER_ACCESS')}
                        </Button>
                    </ButtonGroup>
                )}
                {activeTab === 'generalInfo' ? (
                    <div className={styles.gridContainer}>
                        {dataIsLoading ? (
                            <>
                                {Array.from(new Array(8)).map((_, index) => (
                                    <Skeleton
                                        main
                                        key={index}
                                        variant='rounded'
                                        className={styles.gridItem}
                                        width='100%'
                                        height={114}
                                />
                            ))}
                            </>
                        ) : (
                            cardsInfo.map((card) => (
                                <InfoCard
                                    key={card.name}
                                    className={styles.gridItem}
                                    iconName={card.iconName}
                                    name={t(card.name)}
                                    finishedCount={card.finishedCount}
                                    total={card.total}
                                    totalWithoutLabel={card.totalWithoutLabel}
                                    users={card.users ?? ''}
                            />
                            ))
                        )}
                    </div>
                ) :
                (
                    <UsersAccessChart accountType={accountType} accountId={accountId} />
                )}
            </main>
        </Container>
    );
}

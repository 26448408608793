
import React, {useState, useEffect, useMemo} from 'react';
import {LoadingButton} from '@mui/lab';
import {Box, CircularProgress, Container, Grid, Button, TextField} from '@mui/material';
import {FormikTextField} from '@src/components/FormikFields';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {useParams, useNavigate} from 'react-router-dom';
import {frequentlyAskedQuestionsSchema} from '@src/modules/frequentlyAskedQuestions/schema';
import {Edit} from '@mui/icons-material';
import {formatDate} from '@src/utils/moment-utils';
import {useEditFrequentlyAskedQuestionMutation, useGetFrequentlyAskedQuestionQuery, useGetFrequentlyAskedQuestionsQuery} from '@src/modules/frequentlyAskedQuestions/frequentlyAskedQuestions.api';
import {routes} from '@src/utils/routes';
import PageHeader from '@src/components/PageHeader';
import {MAX_ANSWER_LENGTH} from '@src/modules/frequentlyAskedQuestions/utils';
import {CategoryTagsField} from '@src/components/CategoryTagsField';
import TextEditor from '@src/components/TextEditor';

import styles from './frequently-asked-questions-edit.module.scss';

export default function FrequentlyAskedQuestionsEdit(){
    const [tags, setTags] = useState([]);
    const [editMode, setEditMode] = useState(false);

    const {t} = useTranslation();
    const {faqId} = useParams();
    const {data, isLoading: isDataLoading} = useGetFrequentlyAskedQuestionQuery({faqId}, {skip: !faqId});
    const [editFaq, {isLoading: editLoading, isSuccess, error}] = useEditFrequentlyAskedQuestionMutation();
    const {data: faqData} = useGetFrequentlyAskedQuestionsQuery();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const listTags = faqData?.map(value => value.tags);

    const tagsArrayMerge = useMemo(() => {
        if (listTags){
            return listTags.flat();
        }
    }, [listTags]);

    const tagsNames = {};

    tagsArrayMerge?.forEach(item => {
        const lowerItem = item?.toLowerCase();
        const FormattedName = lowerItem?.charAt(0)?.toUpperCase() + lowerItem?.slice(1);
        tagsNames[FormattedName] = true;
    });

    const tagsOptions = Object.keys(tagsNames);

    const formik = useFormik({
        validationSchema: frequentlyAskedQuestionsSchema(t),
        initialValues: {
            question: '',
            answer: '',
            tags: '',
        },
        onSubmit: (form) => {
            editFaq({faqId, body: form});
        },
    });

    const formSubmit = (e) => {
        e.preventDefault();
        formik.handleSubmit();
    };

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar(t('FAQ.EDIT_SUCCESS'), {variant: 'success'});
            navigate(routes.FAQ);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(t('FAQ.EDIT_ERROR'), {variant: 'error'});
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            formik.setValues({
                question: data?.question || '',
                answer: data?.answer || '',
                tags: data?.tags || [],
            });
            setTags(data?.tags);
        }
    }, [data]);

    const cannotSave = useMemo(() => verifyChange(formik.values, data), [formik.values, data]);
    return (
        <Container maxWidth='xl'>
            <PageHeader title={editMode ? 'FAQ.EDIT' : 'FAQ.DETAILS'} textTooltip={t('FAQ.BACK')} toBack={() => navigate(routes.FAQ)} />
            {isDataLoading &&
                <Box sx={{height: 120, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <CircularProgress color='inherit' />
                </Box>}
            {!isDataLoading &&
                <form onSubmit={formSubmit} autoComplete='off'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={styles.date}>
                            <TextField
                                value={formatDate(data?.created_at)}
                                label={t('FAQ.CREATED_AT')}
                                disabled={true}
                                fullWidth
                            />
                            <TextField
                                value={data?.updated_at !== data?.created_at ? formatDate(data?.updated_at) : (t('FAQ.NO_EDIT'))}
                                label={t('FAQ.UPDATED_AT')}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikTextField
                                fullWidth
                                name='question'
                                label={t('FAQ.QUESTION')}
                                formik={formik}
                                disabled={!editMode}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CategoryTagsField
                                name='tags'
                                label={t('FAQ.CATEGORY_TAGS')}
                                formik={formik}
                                tags={tags}
                                setTags={setTags}
                                tagsOptions={tagsOptions}
                                disabled={!editMode}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextEditor
                                name='answer'
                                onChange={(value) => formik.setFieldValue('answer', value)}
                                initialValue={data.answer}
                                error={formik.values.answer.length >= MAX_ANSWER_LENGTH}
                                helperText={formik.values.answer.length >= MAX_ANSWER_LENGTH ? t('FAQ.MAX_LENGTH') : ''}
                                maxLength={MAX_ANSWER_LENGTH}
                                disabled={!editMode}
                            />
                        </Grid>
                        {<Grid item xs={12} mt={2}>
                            {(!editMode) &&
                                <Button startIcon={<Edit />} variant='outlined' color='secondary' onClick={() => setEditMode(true)} fullWidth>{t('FAQ.EDIT_MODE')}</Button>
                        }
                            {editMode &&
                                <div className={styles.buttons}>
                                    <Button
                                        variant='outlined'
                                        type='button'
                                        fullWidth
                                        onClick={() => setEditMode(false)}
                                >
                                        {t('FAQ.CANCEL')}
                                    </Button>
                                    <LoadingButton
                                        type='submit'
                                        loading={editLoading}
                                        disabled={cannotSave}
                                        variant='contained'
                                        fullWidth
                                >
                                        {t('FAQ.SAVE')}
                                    </LoadingButton>
                                </div>

                        }
                        </Grid>}
                    </Grid>
                </form>
}
        </Container>
    );
}

const verifyChange = (newValues, oldValues) => {
    if (!newValues || !oldValues) {
        return false;
    }

    return newValues.question === oldValues.question
        && newValues.answer === oldValues.answer
        && newValues.tags === oldValues.tags;
};

import React from 'react';
import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FormikTextField} from '@src/components/FormikFields';
import cx from 'classnames';
import {formatCPF} from '@brazilian-utils/brazilian-utils';
import {DatePicker} from '@mui/x-date-pickers';
import * as moment from 'moment';

import {handlePhoneChange, MIN_DATE} from '../../utils';

import styles from './financialResponsible.module.scss';
export default function FinancialResponsible({formik, readOnly, disabled}){
    const {t} = useTranslation();
    const errorDateOfBirth = formik?.errors?.financial_responsible?.birthday;

    const handleCpfChange = (event, formik) => {
        const formattedCPF = formatCPF(event.target.value);
        formik.setFieldValue('financial_responsible.cpf', formattedCPF);
    };
    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <Typography variant='h6' component='h6' mt={1} mb={2}>
                    {t('ACCOUNTS_PAGE.FINANCIAL_RESPONSIBLE')}
                </Typography>
            </div>
            <div className={styles.row}>
                <div className={styles.soloItem}>
                    <FormikTextField
                        fullWidth
                        name='financial_responsible.name'
                        data-qa='account-financial_responsible-name'
                        label={t('ACCOUNTS_PAGE.RESPONSIBLE_FINANCIAL_NAME')}
                        formik={formik}
                        value={formik.values?.financial_responsible?.name}
                        onChange={(e) => formik.setFieldValue('financial_responsible.name', e.target.value)}
                        error={Boolean(formik.touched.financial_responsible?.name && formik.errors.financial_responsible?.name)}
                        helperText={formik.touched.financial_responsible?.name && formik.errors.financial_responsible?.name}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='financial_responsible.cpf'
                        data-qa='account-financial_responsible-cpf'
                        label={t('ACCOUNTS_PAGE.CPF')}
                        formik={formik}
                        onChange={(e) => handleCpfChange(e, formik)}
                        value={formik.values?.financial_responsible?.cpf}
                        error={Boolean(formik.touched.financial_responsible?.cpf && formik.errors.financial_responsible?.cpf)}
                        helperText={formik.touched.financial_responsible?.cpf && formik.errors.financial_responsible?.cpf}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </div>
                <div className={styles.datePickItem}>
                    <DatePicker
                        name='financial_responsible.birthday'
                        data-qa='account-financial_responsible-birthday'
                        value={formik.values?.financial_responsible?.birthday}
                        onChange={(date) => formik.setFieldValue('financial_responsible.birthday', date)}
                        label={t('ACCOUNTS_PAGE.DATE_OF_BIRTH')}
                        readOnly={readOnly}
                        disabled={disabled}
                        className={cx(styles.datePick, {
                            [styles.pickError]: errorDateOfBirth,
                        })}
                        minDate={moment(MIN_DATE)} 
                        maxDate={moment()}
                    />
                    {errorDateOfBirth && (
                        <span className={styles.errorMessage}>{errorDateOfBirth}</span>
                    )}
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        type='email'
                        name='financial_responsible.email'
                        data-qa='account-financial_responsible-email'
                        label={t('ACCOUNTS_PAGE.RESPONSIBLE_FINANCIAL_EMAIL')}
                        formik={formik}
                        value={formik.values?.financial_responsible?.email}
                        onChange={(e) => formik.setFieldValue('financial_responsible.email', e.target.value)}
                        error={Boolean(formik.touched.financial_responsible?.email && formik.errors.financial_responsible?.email)}
                        helperText={formik.touched.financial_responsible?.email && formik.errors.financial_responsible?.email}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </div>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='financial_responsible.phone'
                        label={t('ACCOUNTS_PAGE.RESPONSIBLE_FINANCIAL_PHONE')}
                        data-qa='account-financial_responsible-phone'
                        formik={formik}
                        value={formik.values?.financial_responsible?.phone}
                        onChange={(e) => handlePhoneChange(e, formik)}
                        error={Boolean(formik.touched.financial_responsible?.phone && formik.errors.financial_responsible?.phone)}
                        helperText={formik.touched.financial_responsible?.phone && formik.errors.financial_responsible?.phone}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
}

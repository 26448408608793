import {MAX_ANSWER_LENGTH} from '@src/modules/frequentlyAskedQuestions/utils';
import React from 'react';

export default function CharactersCount({editor}){
    return (
        <span>
            {editor.storage.characterCount.characters()} / {MAX_ANSWER_LENGTH}
        </span>
    );
}

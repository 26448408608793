
export const permissions = [
    {
        name: 'boletos',
        title: 'ADMINS.BOLETOS',
        onlyManage: true,
    },
    {
        name: 'admins',
        title: 'ADMINS.MANAGERS',
        onlyManage: true,
    }, {
        name: 'tutorials',
        title: 'ADMINS.TUTORIALS',
        onlyManage: true,
    }, {
        name: 'notes',
        title: 'ADMINS.NOTES',
        onlyManage: true,
    }, {
        name: 'faq',
        title: 'ADMINS.FAQ',
        onlyManage: true,
    },
    {
        name: 'trainings',
        title: 'ADMINS.TRAININGS',
        onlyManage: false,
    }, {
        name: 'trainingAccounts',
        title: 'ADMINS.TRAINING_ACCOUNTS',
        onlyManage: false,
    }, {
        name: 'commercialAccounts',
        title: 'ADMINS.COMMERCIAL_ACCOUNTS',
        onlyManage: false,
    }, {
        name: 'generalDashboard',
        title: 'ADMINS.DASHBOARD',
        onlyManage: true,
    }];

export function formatPermissionValue(permissions) {
    const withoutConsult = permissions.includes('consult')
        ? [...permissions.filter(p => p !== 'consult'), 'list', 'detail']
        : permissions;

    return withoutConsult.length > 0 ? withoutConsult.join(', ') : undefined;
}

import * as yup from 'yup';

import {
    MAX_DESCRIPTION_LENGTH,
    MAX_TITLE_LENGTH,
    MIN_DESCRIPTION_LENGTH,
    MIN_TITLE_LENGTH,
    MIN_TEXT_CONTENT_VALUE_LENGTH,
} from './utils';

export const trainingsSchema = (t) => {
    return yup.object({
        title: yup.string().trim()
            .min(MIN_TITLE_LENGTH, t('MIN_LENGTH', {length: MIN_TITLE_LENGTH}))
            .max(MAX_TITLE_LENGTH, t('MAX_LENGTH', {length: MAX_TITLE_LENGTH}))
            .required(t('REQUIRED_FIELD')),
        description: yup.string().trim()
            .min(MIN_DESCRIPTION_LENGTH, t('MIN_LENGTH', {length: MIN_DESCRIPTION_LENGTH}))
            .max(MAX_DESCRIPTION_LENGTH, t('MAX_LENGTH', {length: MAX_DESCRIPTION_LENGTH}))
            .required(t('REQUIRED_FIELD')),
        thumbnail: yup.mixed()
            .required(t('REQUIRED_FIELD')),
        categories: yup.array()
            .of(yup.string())
            .typeError(t('TRAININGS_PAGE.SAVE_CATEGORIES_MESSAGE')),
        allowedAccounts: yup.array()
            .of(yup.string())
            .nullable(),
    });
};

export const topicTextSchema = (t) => {
    return yup.object({
        title: yup.string().trim()
            .min(MIN_TITLE_LENGTH, t('MIN_LENGTH', {length: MIN_TITLE_LENGTH}))
            .max(MAX_TITLE_LENGTH, t('MAX_LENGTH', {length: MAX_TITLE_LENGTH}))
            .required(t('REQUIRED_FIELD')),
        value: yup.string().trim()
            .min(MIN_TEXT_CONTENT_VALUE_LENGTH, t('MIN_LENGTH', {length: MIN_TEXT_CONTENT_VALUE_LENGTH}))
            .required(t('REQUIRED_FIELD')),
    });
};

export const topicVideoSchema = (t) => {
    return yup.object({
        title: yup.string().trim()
            .min(MIN_TITLE_LENGTH, t('MIN_LENGTH', {length: MIN_TITLE_LENGTH}))
            .max(MAX_TITLE_LENGTH, t('MAX_LENGTH', {length: MAX_TITLE_LENGTH}))
            .required(t('REQUIRED_FIELD')),
        video: yup.mixed()
            .required(t('REQUIRED_FIELD')),
        captions: yup.array().of(
            yup.object({
                language: yup.string(),
                caption: yup.mixed(),
            }),
        ),
    });
};

export const topicAttachmentsSchema = (t) => {
    return yup.object({
        title: yup.string().trim()
            .min(MIN_TITLE_LENGTH, t('MIN_LENGTH', {length: MIN_TITLE_LENGTH}))
            .max(MAX_TITLE_LENGTH, t('MAX_LENGTH', {length: MAX_TITLE_LENGTH}))
            .required(t('REQUIRED_FIELD')),
        attachments: yup.array().of(
            yup.mixed(),
        ).required(t('REQUIRED_FIELD')),
    });
};

import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import EditIcon from '@mui/icons-material/Edit';
import {Button} from '@mui/material';

import {generateFileUrl} from '../../utils';

import styles from './edit-tutorial-thumbnail.module.scss';

export function EditTutorialThumbnail({editMode, thumbnailUrl, setThumbnail}) {
    const [currentThumbnailUrl, setCurrentThumbnailUrl] = useState('');
    const [previewUrl, setPreviewUrl] = useState(null);

    const inputRef = useRef(null);
    
    const {t} = useTranslation();

    function handleChangeThumbnail() {
        inputRef.current.click();
    }

    function addFileInInput({file}) {
        if (file) {
            setThumbnail(file);
            setPreviewUrl(generateFileUrl(file));
        } else {
            setThumbnail(thumbnailUrl);
            setPreviewUrl(generateFileUrl(thumbnailUrl));
        }
    }

    useEffect(() => {
        if (typeof thumbnailUrl === 'string') {
            setCurrentThumbnailUrl(thumbnailUrl);
        }

        return () => {
            setCurrentThumbnailUrl(null);
        };
    }, [thumbnailUrl]);

    return (
        <div className={styles.container}>
            <div className={`
                ${styles.mediaInputContainer} 
                ${!editMode && styles.mediaInputContainerDisabled}
            `}>
                <label htmlFor='thumbnail'>
                    {currentThumbnailUrl && !previewUrl && <img src={currentThumbnailUrl} />}

                    {previewUrl && (
                        <img src={previewUrl} />
                    )}

                    {!currentThumbnailUrl && !previewUrl && (
                        <>
                            <CameraAltOutlinedIcon sx={{fontSize: '2rem'}} />
                            {t('TUTORIALS_PAGE.SELECT_THUMBNAIL')}
                        </>
                    )}
                </label>
               
                <input
                    ref={inputRef}
                    type='file'
                    id='thumbnail'
                    name='thumbnail'
                    accept='.png, .jpeg, .jpg'
                    disabled={!editMode}
                    onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        addFileInInput({file});
                    }}
                />
            </div>

            <Button
                endIcon={<EditIcon />}
                size='small'
                color='warning'
                disabled={!editMode}
                onClick={handleChangeThumbnail}
            >
                {t('TUTORIALS_PAGE.CHANGE_SELECTED_THUMBNAIL')}
            </Button>
        </div>
    );
}

import React, {useEffect} from 'react';
import ContentLoader from 'react-content-loader';
import {useTranslation} from 'react-i18next';
import {useTable, usePagination, useSortBy} from 'react-table';
import noData from '@assets/imgs/NoData.svg';
import {Stack, Pagination} from '@mui/material';

import styles from './table.module.scss';

export default function Table({
    columns = [],
    data = [],
    loading,
    total,
    pageSize,
    isError,
    paginated = true,
    Actions,
    handleClick,
    changePage = () => {},
    notFoundText,
    actionsClassName,
    pageActual,
    setPage,
    sortBy,
}) {

    const {t} = useTranslation();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        pageCount,
        prepareRow,
        state: {pageIndex},
    } = useTable({
        columns,
        data,
        initialState: {pageIndex: 0, pageSize: 10, sortBy: sortBy ? [{id: sortBy, desc: true}] : []},
        manualPagination: true,
        pageCount: Math.ceil(total / pageSize),
    }, sortBy ? useSortBy : '', usePagination);

    useEffect(() => {
        changePage(pageIndex);
    }, [pageIndex]);

    if (loading) {
        return <LoadingContent />;
    }

    if (isError) {
        return <HasError />;
    }

    if (!loading && data.length === 0) {
        return <NoData notFoundText={notFoundText} />;
    }

    return (
        <div className={`${styles.container} ${paginated ? styles.paginated : ''}`}>
            <div className={styles.tableContainer}>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup, i) => (
                            <tr key={i} {...headerGroup.getHeaderGroupProps()} tabIndex='0'>
                                {headerGroup.headers.map((column, idx) => (
                                    <th key={idx} {...column.getHeaderProps(sortBy ? column.getSortByToggleProps() : '')}>{column.render('Header')}</th>
                            ))}
                                {Actions && <th className={styles.actionsCell}>{t('ACTIONS')}</th>}
                            </tr>
                ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr
                            className={handleClick ? styles.clickableRow : ''}
                            key={i}
                            {...row.getRowProps()}
                            onClick={handleClick ? () => handleClick(row.original.id) : () => {}}
                            tabIndex='0'
                        >
                            {row.cells.map((cell, idx) => {
                                return (
                                    <td key={idx} {...cell.getCellProps()}>
                                        {<span>{cell.render('Cell')}</span>}
                                    </td>
                                );
                            })}
                            {Actions &&
                                <td>
                                    <Actions id={row.original.id} disabled={row.original.disabled} data={row.original} className={actionsClassName} />
                                </td>}
                        </tr>
                    );
                })}
                    </tbody>
                </table>
            </div>
            {paginated &&
                <Stack spacing={2}>
                    <Pagination
                        count={pageCount}
                        page={pageActual + 1}
                        onChange={(_, value) => setPage(value - 1)}
                        variant='outlined'
                        shape='rounded'
                  />
                </Stack>
        }
        </div>
    );
}

const LoadingContent = (props) => {
    return (
        <ContentLoader
            speed={2}
            width={'100%'}
            viewBox={'0 0 400 200'}
            backgroundColor={'var(--theme-skeleton-background'}
            foregroundColor={'var(--theme-skeleton-foreground'}
            {...props}
>
            {Array(5).fill('').map((_, idx) =>
                <rect key={idx} x='0' y={idx * 20} rx='0' ry='0' width='100%' height='15' />,
)}
        </ContentLoader>
    );
};

const NoData = ({notFoundText}) => {
    const {t} = useTranslation();

    return (
        <div className={styles.noData}>
            <img src={noData} alt='no data' />
            <p>{notFoundText || t('NO_DATA_FOUND')}</p>
        </div>
    );
};

const HasError = () => {
    const {t} = useTranslation();

    return (
        <div className={styles.noData}>
            <img src={noData} alt='Error' />
            <p>{t('ERROR_TABLE_LOAD')}</p>
        </div>
    );
};

import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {LoadingButton} from '@mui/lab';

function AlertDialog({title, description, confirmMessage, cancelMessage, open = false, isLoading = false, confirmColor = 'error', onConfirm, onClose, className}) {
    const {t} = useTranslation();
    
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            className={className}
        >
            <DialogTitle id='alert-dialog-title'>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description' width={480}>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='inherit'>{cancelMessage || t('CANCEL_ACT')}</Button>
                <LoadingButton onClick={onConfirm} color={confirmColor} loading={isLoading} autoFocus>
                    {confirmMessage || t('CONFIRM_ACT')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default AlertDialog;

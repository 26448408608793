import React from 'react';

export default function SvgIcon({name: iconName, ...rest}) {
    const [svg, setSvg] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [mounted, setMounted] = React.useState(true);

    React.useEffect(() => {
        const importIcon = async () => {
            try {
                if (iconName && !svg) {
                    setLoading(true);
                    const mod = await import('!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/icons/' + iconName + '.svg');
                    setSvg(mod.default);
                }
            } finally {
                if (mounted) {
                    setLoading(false);
                }
            }
        };
        importIcon();
    }, [iconName, mounted]);

    React.useEffect(() => {
        return () => {
            setMounted(false);
        };
    }, []);

    if (!loading && svg) {
        const ImportedIcon = svg;
        return <ImportedIcon {...rest} />;
    }
    return null;
}

import React from 'react';
import {Backdrop, CircularProgress} from '@mui/material';
import {FocusTrap} from '@mui/base';

function LoadingBackdrop({isLoading = false, children, className}) {
    return (
        <FocusTrap open={isLoading}>
            <Backdrop
                slot='Backdrop'
                className={className}
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color='inherit' />
                {children}
            </Backdrop>
        </FocusTrap>
    );
}

export default LoadingBackdrop;

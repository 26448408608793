import React from 'react';
import {Button} from '@mui/material';
import {LoadingButton} from '@mui/lab';

import Modal from '../Modal';

import styles from './confirmation-modal.module.scss';

export function ConfirmationModal({title, description, cancelText, confirmText, handleClose, handleSubmit, loading = false}) {
    return (
        <Modal
            onClose={handleClose}
            title={title}
            modalWidth='400px'
        >
            <div className={styles.modalContent}>
                <p>{description}</p>

                <div className={styles.buttonsContainer}>
                    <Button
                        className={styles.cancelButton}
                        variant='outlined'
                        onClick={handleClose}
                    >
                        {cancelText}
                    </Button>
                    <LoadingButton
                        className={styles.confirmButton}
                        variant='contained'
                        color='error'
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        {confirmText}
                    </LoadingButton>
                </div>
            </div>
        </Modal>
    );
}

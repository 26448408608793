import {Typography} from '@mui/material';
import React, {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './timer.module.scss';

export default function Timer({
    counter,
    setCounter,
}) {
  
    const {t} = useTranslation();
  
    useEffect(() => {
        const remainingTime =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(remainingTime);
    }, [counter, setCounter]);
  
    const timer = useMemo(() => {
        const minutes = Math.floor((counter % 3600) / 60).toString().padStart(2, '0');
        const seconds = (counter % 60).toString().padStart(2, '0');
  
        return `${minutes}:${seconds}`;
    }, [counter]);
  
    return (
        <Typography color='primary' className={styles.timer}>{`${timer} ${t('TO_SEND_CODE')}`}</Typography>
    );
}

import React from 'react';

import styles from './check-box.module.scss';
export default function CheckBox({value, name, onChange, className, id, label, ...props}){
    return (
        <div className={styles.container}>
            <label htmlFor={id}>{label}</label>
            <input type='checkbox' name={name} onChange={onChange} id={id} value={value} {...props} />
            <div className={styles.checkmark} />
        </div>
    );
}

import * as yup from 'yup';

import {
    MAX_ANSWER_LENGTH,
    MAX_QUESTION_LENGTH,
    MIN_ANSWER_LENGTH,
    MIN_QUESTION_LENGTH,
    MIN_TAGS_LENGTH,
    MIN_TAG_LENGTH,
    MAX_TAG_LENGTH,
} from './utils';

export const frequentlyAskedQuestionsSchema = (t) => {
    return yup.object({
        question: yup.string().trim()
            .min(MIN_QUESTION_LENGTH, t('MIN_LENGTH', {length: MIN_QUESTION_LENGTH}))
            .max(MAX_QUESTION_LENGTH, t('MAX_LENGTH', {length: MAX_QUESTION_LENGTH}))
            .required(t('REQUIRED_FIELD')),
        answer: yup.string().trim()
            .min(MIN_ANSWER_LENGTH, t('MIN_LENGTH', {length: MIN_ANSWER_LENGTH}))
            .max(MAX_ANSWER_LENGTH, t('MAX_LENGTH', {length: MAX_ANSWER_LENGTH}))
            .required(t('REQUIRED_FIELD')),
        tags: yup.array()
            .min(MIN_TAGS_LENGTH, t('REQUIRED_FIELD'))
            .of(yup.string().trim()
                .min(MIN_TAG_LENGTH, t('FAQ.TAG_MIN_LENGTH', {length: MIN_TAG_LENGTH}))
                .max(MAX_TAG_LENGTH, t('FAQ.TAG_MAX_LENGTH', {length: MAX_TAG_LENGTH})))
            .required(t('REQUIRED_FIELD'))
            .typeError(t('FAQ.SAVE_TAG_MESSAGE')),
    });
};

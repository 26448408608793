import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {importStatus as status} from '@src/modules/async-tasks/utils';
import {Box} from '@mui/material';

import ReturnedLogs from './components/ReturnedLogs';
import FinishedImportContent from './components/FinishedImportContent';
import styles from './verify-import.module.scss';

export default function VerifyImport({
    logs,
    importData,
    translationsImportErros,
    translationImportType,
    route,
    importStatus,
}) {
    const {t} = useTranslation();
    const isDone = importStatus === status.done;

    const countErrors = useMemo(() => logs ? logs.filter(item => item && item?.rowStatus === status.error).length : [], [logs]); 
    const countSuccess = useMemo(() => logs ? logs?.filter(item => item && item?.rowStatus === status.done).length : [], [logs]); 

    return (
        <Box className={styles.main}>
            <h1 className={styles.title}>
                {t(
                    isDone && (countErrors > 0 || logs.length === 0) ? 
                        'IMPORT_USERS.CHECK_INFORMATION' : 
                        'IMPORT_USERS.IMPORT_SUCCESS',
                )}
            </h1>
            {isDone && (countErrors > 0 || logs.length === 0) ? 
                <ReturnedLogs
                    logs={logs}
                    importData={importData}
                    translationsImportErros={translationsImportErros}
                    translationImportType={translationImportType}
                    countErrors={countErrors}
                    countSuccess={countSuccess}
                /> 
             :
                <FinishedImportContent
                    translationImportType={translationImportType}
                    route={route}
                />
            } 
        </Box>
    );
}

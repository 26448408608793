import React, {useCallback} from 'react';
import classNames from 'classnames';
import {IconButton} from '@mui/material';
import SvgIcon from '@src/components/SvgIcon';

import styles from './menu-bar.module.scss';

export function MenuBar({editor, setShowMenuAdd, url}) {

    const activeLink = useCallback(() => {
        editor.chain().focus().toggleLink().run();

        if (!url) {
            setShowMenuAdd(true);

        }
    }, [editor, setShowMenuAdd, url]);

    if (!editor) {
        return null;
    }

    return (
        <>
            <IconButton
                className={classNames([styles.menuBarButton, {[styles.isActive]: editor.isActive('heading', {level: 1})}])}
                type='button'
                onClick={() => editor.chain().focus().toggleHeading({level: 1}).run()}
            >
                <SvgIcon name='H1' className={styles.menuBarIcon} />
            </IconButton>
            <IconButton
                className={classNames([styles.menuBarButton, {[styles.isActive]: editor.isActive('heading', {level: 2})}])}
                type='button'
                onClick={() => editor.chain().focus().toggleHeading({level: 2}).run()}
            >
                <SvgIcon name='H2' className={styles.menuBarIcon} />
            </IconButton>
            <IconButton
                className={classNames([styles.menuBarButton, {[styles.isActive]: editor.isActive('heading', {level: 3})}])}
                type='button'
                onClick={() => editor.chain().focus().toggleHeading({level: 3}).run()}
            >
                <SvgIcon name='H3' className={styles.menuBarIcon} />
            </IconButton>

            <span className={styles.divider} />

            <IconButton
                className={classNames([styles.menuBarButton, {[styles.isActive]: editor.isActive('bold')}])}
                type='button'
                onClick={() => editor.chain().focus().toggleBold().run()}
            >
                <SvgIcon name='Bold' className={styles.menuBarIcon} />
            </IconButton>
            <IconButton
                className={classNames([styles.menuBarButton, {[styles.isActive]: editor.isActive('italic')}])}
                type='button'
                onClick={() => editor.chain().focus().toggleItalic().run()}
            >
                <SvgIcon name='Italic' className={styles.menuBarIcon} />
            </IconButton>
            <IconButton
                className={classNames([styles.menuBarButton, {[styles.isActive]: editor.isActive('underline')}])}
                type='button'
                onClick={() => editor.chain().focus().toggleUnderline().run()}
            >
                <SvgIcon name='Underline' className={styles.menuBarIcon} />
            </IconButton>
            <span className={styles.divider} />
            <IconButton
                className={classNames([styles.menuBarButton, {[styles.isActive]: editor.isActive('link')}])}
                type='button'
                onClick={activeLink}
            >
                <SvgIcon name='Link' className={styles.menuBarIcon} />
            </IconButton>
        </>
    );
}

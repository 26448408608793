import React, {useCallback, useMemo, useState} from 'react';
import {
    Button,
    Container,
    IconButton,
    Tooltip,
    Typography,
    Switch,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import Table from '@src/components/Table';
import EditIcon from '@mui/icons-material/Edit';
import {useGetAdminsQuery, useToggleDisabledMutation} from '@src/modules/admins/admins.api';
import {useSnackbar} from 'notistack';
import {ConfirmationModal} from '@src/components/ConfirmationModal';

import styles from './admins-list.module.scss';

export default function Admins(){
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {data, isLoading} = useGetAdminsQuery();

    const columns = useMemo(() => [
        {accessor: 'name', Header: t('ADMINS.NAME')},
        {accessor: 'email', Header: t('ADMINS.EMAIL')},
    ], []);

    return (
        <Container maxWidth='xl'>
            <header className={styles.adminsListHeader}>
                <Typography variant='h4' component='h2'>
                    {t('ADMINS.ADMINS')}
                </Typography>
                <Button variant='contained' onClick={() => navigate(routes.ADMINS_CREATE)}>
                    {t('ADMINS.CREATE')}
                </Button>
            </header>
            
            <Table 
                columns={columns}
                data={data}
                notFoundText={t('ADMINS.NO_DATA_FOUND')}
                paginated={false}
                loading={isLoading}
                Actions={Actions}
                actionsClassName={styles.actions}
            />       
        </Container>
    );
}

const Actions = ({data}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [toggle] = useToggleDisabledMutation();
    const {enqueueSnackbar} = useSnackbar();
    const [open, setOpen] = useState();

    const toggleDisabled  = useCallback(async (user) => {
        try {
            await toggle({id: user.id, body: !data.disabled}).unwrap();
            enqueueSnackbar(data.disabled ? t('ADMINS.ENABLE_ADMIN_SUCCSESS') :   t('ADMINS.DISABLE_ADMIN_SUCCESS'), {variant: 'success'});
        } catch (error){
            enqueueSnackbar(t('ADMINS.DISABLE_ERROR'), {variant: 'error'});
        } finally {
            setOpen(false);
        }
    }, [data?.disabled]);
    return (
        
        <div className={styles.actions}>
            <Tooltip title={t('ADMINS.EDIT')}>
                <IconButton 
                    size='small'
                    onClick={() => navigate(routes.ADMINS_EDIT(data?.id))}
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={data.disabled ? t('ADMINS.ENABLE') : t('ADMINS.DISABLE')}>
                <Switch 
                    onClick={() => setOpen(true)}
                    name='modules.climateCheck'
                    checked={!data?.disabled}
                />
            </Tooltip>
            {open && (
                <ConfirmationModal
                    title={data?.disabled ? t('ADMINS.ENABLE_ADMIN') : t('ADMINS.DISABLE_ADMIN')}
                    description={t('ADMINS.DISABLE/ENABLE', {state: data?.disabled ? t('ADMINS.ENABLE_LOWER') : t('ADMINS.DISABLE_LOWER')})}
                    cancelText={t('ADMINS.CANCEL')}
                    confirmText={t('ADMINS.CONFIRM')}
                    handleClose={() => setOpen(false)}
                    handleSubmit={() => toggleDisabled(data)}
                />
             )}
        </div>
    );
};

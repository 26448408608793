import Dropzone from 'react-dropzone';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import React, {useState} from 'react';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import {InputLabel, MenuItem, Select} from '@mui/material';
import Modal from '@src/components/Modal';
import {captionLanguages, convertToConstant} from '@src/modules/trainings/utils';

import styles from './new-caption-modal.module.scss';

export function NewCaptionModal({handleClose, captions, submitFunction, loading = false}) {
    const {t} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const alreadySelectedLanguages = captions.map(caption => caption.language);
    const availableOptions = captionLanguages.filter(caption => !alreadySelectedLanguages.includes(caption));

    const enableButton = selectedLanguage && selectedFile;

    function onDrop(file) {
        setSelectedFile(file[0]);
    }

    async function handleSubmit() {
        await submitFunction({
            language: selectedLanguage,
            file: selectedFile,
        });

        setSelectedLanguage(null);
        setSelectedFile(null);
        handleClose();
    }

    return (
        <Modal
            title={t('TRAININGS_PAGE.ADD_NEW_CAPTION')}
            modalWidth='500px'
            onClose={handleClose}
        >
            <div className={styles.modalContent}>
                <div className={styles.modalInputGroup}>
                    <InputLabel htmlFor='caption-language'>
                        {t('TRAININGS_PAGE.SELECT_CAPTION_LANGUAGE')}
                    </InputLabel>
                    <Select
                        fullWidth
                        labelId='caption-language'
                        id='caption-language'
                        value={selectedLanguage}
                        onChange={({target}) => {
                            setSelectedLanguage(target.value);
                        }}
                    >
                        {availableOptions.map((language) => 
                            <MenuItem
                                key={language}
                                value={language}
                            >
                                {t(`LANGUAGES.${convertToConstant(language)}`)}
                            </MenuItem>,
                        )}                        
                    </Select>
                </div>

                <div className={styles.modalInputGroup}>
                    <InputLabel htmlFor='caption-file'>
                        {t('TRAININGS_PAGE.SELECT_CAPTION_FILE')}
                    </InputLabel>

                    <label htmlFor='caption-file' className={styles.captionDropzone}>
                        {selectedFile ? 
                            <p>{selectedFile.name}</p> :
                            <>
                                <SubtitlesIcon />
                                <p>{t('TRAININGS_PAGE.SELECT_SRT_FILE')}</p>
                            </>
                        }
                    </label>

                    <Dropzone onDrop={onDrop} accept={{'text/plain': ['.srt']}} maxFiles={1}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()}>
                                <input id='caption-file' {...getInputProps()} />
                            </div>
                        )}
                    </Dropzone>
                </div>

                <LoadingButton
                    onClick={handleSubmit}
                    type='button'
                    variant='contained'
                    fullWidth
                    disabled={!enableButton}
                    loading={loading}
                >
                    {t('TRAININGS_PAGE.ADD_CAPTION')}
                </LoadingButton>
            </div>
        </Modal>
    );
}

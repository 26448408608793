import React, {useState} from 'react';
import {
    Grid,
    Container,
    InputAdornment,
    IconButton,
} from '@mui/material';
import PageHeader from '@src/components/PageHeader';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import {FormikTextField} from '@src/components/FormikFields';
import {useFormik} from 'formik';
import {adminsSchema} from '@src/modules/admins/schema';
import {LoadingButton} from '@mui/lab';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useSnackbar} from 'notistack';
import {useAddAdminsMutation} from '@src/modules/admins/admins.api';
import CheckBox from '@src/components/Checkbox';
import {formatPermissionValue, permissions} from '@src/modules/admins/utils';

import styles from './admins-create.module.scss';
export default function CreateAdmins(){
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [addAdmins, isLoading] = useAddAdminsMutation();
    const [showPassword, setShowPassword] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const initialValues = {
        name: '',
        email: '',
        password: '',
        permissions: {
            commercialAccounts: '',
            boletos: '',
            trainingAccounts: '',
            faq: '',
            notes: '',
            tutorials: '',
            trainings: '',
            admins: '',
        },
        disabled: '',
    };
    function formSubmit(e)  {
        e.preventDefault();
        formik.handleSubmit();
    }
    const toggleShowPassword = () => {
        setShowPassword(old => !old);
    };
    async function handleSubmit(form) {
        try {
            const formattedPermissions = {};
            Object.keys(form.permissions).forEach(item => {
                formattedPermissions[item] = formatPermissionValue(form.permissions[item]);
            });
            const payload = {
                name: form.name,
                email: form.email,
                password: form.password || undefined,
                permissions: formattedPermissions,
                disabled: false,
            };
            await addAdmins({body: payload}).unwrap();
            enqueueSnackbar(t('ADMINS.CREATE_SUCCESS'), {variant: 'success'});
        } catch (error) {
            if (error.status === 409){
                enqueueSnackbar(t('ADMINS.EMAIL_ERROR'), {variant: 'error'});
            }
            enqueueSnackbar(t('ADMINS.CREATE_ERROR'), {variant: 'error'});
        } finally {
            navigate(routes.ADMINS);
        }
    }
    const formik = useFormik({
        validationSchema: adminsSchema(t),
        initialValues,
        onSubmit: handleSubmit,
    });

    function handlePermission(field, permission){
        if (formik?.values?.permissions?.[field]?.includes(permission)){
            let updatedPermission = formik.values.permissions[field].filter(item => item != permission);
            formik.setFieldValue(`permissions.${field}`, updatedPermission);
        } else {
            formik.setFieldValue(`permissions.${field}`, [...formik.values.permissions[field], permission]);
        }
    }

    return (
        <Container maxWidth='xl'>
            <PageHeader
                title='ADMINS.CREATE'
                textTooltip={t('ADMINS.BACK')}
                toBack={() => navigate(routes.ADMINS)} 
        />
            <main>
                <Container>
                    <form onSubmit={formSubmit} className={styles.form} autoComplete='off'>
                        <Grid className={styles.container}>
                            <Grid item xs={12}>
                                <FormikTextField
                                    fullWidth
                                    name='name'
                                    type='text'
                                    label={t('ADMINS.NAME')}
                                    formik={formik}

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField
                                    fullWidth
                                    name='email'
                                    type='email'
                                    label={t('ADMINS.EMAIL')}
                                    formik={formik}
                                    autoComplete='off'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField
                                    fullWidth
                                    formik={formik}
                                    name='password'
                                    label={t('PASSWORD')}
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete='current-password'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    onClick={toggleShowPassword}
                                                    edge='end'
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h2>{t('ADMINS.PERMISSIONS')}</h2>
                                <div className={styles.containerRow}>
                                    {permissions.map((permission, key) => {
                                        return (
                                            <div className={styles.section} key={key}>
                                                <div className={styles.sectionHeader}>
                                                    <h3>{t(permission.title)}</h3>
                                                </div>
                                                <div className={styles.sectionContent}>
                                                    <CheckBox 
                                                        name={`${permission.name}-manage`}
                                                        label={t('ADMINS.MANAGE')}
                                                        onChange={() =>  handlePermission(permission.name, 'manage')}
                                                        checked={formik?.values?.permissions?.[permission.name]?.includes('manage')}
                                                    />
                                                    {!permission.onlyManage &&
                                                        <>
                                                            <CheckBox
                                                                name={`${permission.name}-list`}
                                                                label={t('ADMINS.LIST')}
                                                                onChange={() =>  handlePermission(permission.name, 'list')}
                                                                checked={formik?.values?.permissions?.[permission.name]?.includes('list')}
                                                            />
                                                            <CheckBox
                                                                name={`${permission.name}-detail`}
                                                                label={t('ADMINS.DETAIL')}
                                                                onChange={() =>  handlePermission(permission.name, 'detail')}
                                                                checked={formik?.values?.permissions?.[permission.name]?.includes('detail')}
                                                            />
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })}
                                    
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                type='submit'
                                loading={!isLoading}
                                variant='contained'
                                fullWidth
                                >
                                {t('ADMINS.SAVE')}
                            </LoadingButton>                            
                        </Grid>
                    </form>
                </Container>
            </main>
        </Container>   
    );
}

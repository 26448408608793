import React from 'react';
import getCroppedImg from '@src/utils/image-utils';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import SvgIcon from '@src/components/SvgIcon';
import Cropper from 'react-easy-crop';
import {Button, Slider} from '@mui/material';

import styles from './image-cropper.module.scss';

export function ImageCropper({file, setNewImageUrl, setNewImageFile, onClose}) {
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        const croppedImage = await getCroppedImg(
            file,
            croppedArea,
            rotation,
            {
                width: 1280, 
                height: 720,
            },
        );

        const fr = new FileReader();
        fr.readAsArrayBuffer(croppedImage);
        fr.onload = function () {
            const blob = new Blob([fr.result]);

            const url = URL.createObjectURL(blob, {type: 'image/png'});

            setNewImageUrl(url);
            setNewImageFile(croppedImage);
        };

        onClose?.();
    }, [file, croppedArea, rotation, dispatch, setNewImageUrl, onClose, t]);

    const onZoomChange = (zoom) => {
        setZoom(zoom);
    };

    const changeRotation = () => {
        if (rotation === 360) {
            setRotation(90);
        } else {
            setRotation(rotation + 90);
        }

    };

    return (
        <div className={styles.cropContainer}>
            <div className={styles.cropper}>
                <Cropper
                    image={file}
                    crop={crop}
                    cropShape='rect'
                    showGrid={false}
                    aspect={16 / 9}
                    rotation={rotation}
                    zoom={zoom}
                    onCropChange={setCrop}
                    onZoomChange={onZoomChange}
                    onCropComplete={onCropComplete}
                    objectFit='auto-cover'
                />
            </div>
            <div className={styles.controls}>
                <Slider
                    value={zoom}
                    min={1}
                    step={0.1}
                    max={3}
                    onChange={({target}) => onZoomChange(target.value)}
                    aria-label='Default'
                    valueLabelDisplay='auto'
                />

                <div className={styles.cropButtons}>
                    <Button
                        variant='contained'
                        onClick={showCroppedImage}
                    >
                        {t('TRAININGS_PAGE.FINISH')}
                    </Button>
                    <Button onClick={changeRotation} startIcon={<SvgIcon name='Rotate' />} className={styles.rotateBtn}>
                        {t('TRAININGS_PAGE.TURN')}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export const MIN_QUESTION_LENGTH = 3;
export const MAX_QUESTION_LENGTH = 255;

export const MIN_ANSWER_LENGTH = 3;
export const MAX_ANSWER_LENGTH = 8192;

export const MIN_TAGS_LENGTH = 1;

export const MIN_TAG_LENGTH = 3;
export const MAX_TAG_LENGTH = 50;

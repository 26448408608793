import React, {useMemo, useState, useCallback} from 'react';
import {IconButton, Switch, Tooltip, Button, Container, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import Table from '@src/components/Table';
import {formatDate} from '@src/utils/moment-utils';
import {useGetFrequentlyAskedQuestionsQuery, useToggleFrequentlyAskedQuestionMutation} from '@src/modules/frequentlyAskedQuestions/frequentlyAskedQuestions.api';
import AlertDialog from '@src/components/AlertDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useSnackbar} from 'notistack';
import {useCan} from '@src/hooks/useCan';
import {permissionTypes, permissionResources} from '@src/hooks/usePrivateRoutes';

import styles from './frequently-asked-questions-list.module.scss';

export default function FrequentlyAskedQuestionsList(){
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {data, isLoading, isFetching} = useGetFrequentlyAskedQuestionsQuery();
    const can = useCan();
    
    const columns = useMemo(() => [
        {accessor: 'question', Header: t('FAQ.QUESTIONS')},
        {accessor: 'tags', Header: t('FAQ.TAGS'), Cell: ({cell: {value}}) => value?.join(', ')},
        {accessor: 'created_at', Header: t('FAQ.CREATED_AT'), Cell: ({cell: {value}}) => formatDate(value)},
        {accessor: 'updated_at', Header: t('FAQ.UPDATED_AT'), Cell: ({row}) =>  row?.values.created_at !== row?.values.updated_at ? formatDate(row?.values.updated_at) : ''},
    ], []);
    
    return (
        <Container maxWidth='xl'>
            <header className={styles.accountsListHeader}>
                <Typography variant='h4' component='h2'>
                    {t('FAQ.NAME')}
                </Typography>
                {can(permissionResources.faq, permissionTypes.create) && <Button variant='contained' onClick={() => navigate(routes.FAQ_CREATE)}>
                    {t('FAQ.CREATED')}
                </Button>}
            </header>
            <Table 
                columns={columns}
                data={data}
                notFoundText={t('FAQ.NO_DATA_FOUND')}
                paginated={false}
                loading={isLoading || isFetching}
                Actions={Actions} 
            />       
        </Container>
    );
}  

const Actions = ({data, id}) => {
    const {t} = useTranslation();
    const [toggleId, setToggleId] = useState(null);
    const navigate = useNavigate();
    const [toggle, {isLoading: isToggleLoading}] = useToggleFrequentlyAskedQuestionMutation();
    const {enqueueSnackbar} = useSnackbar();

    const onChange = () => {
        setToggleId(id);
    };
    
    const handleClose = () => {
        setToggleId(null);
    };

    const handleConfirm = useCallback(async () => {
        try {
            await toggle({faqId: id, body: !data?.disabled}).unwrap(); 
            setToggleId(null); 
            enqueueSnackbar(t('FAQ.TOGGLE_SUCCESS', {type: t(data?.disabled ? 'FAQ.ENABLED_TYPE' : 'FAQ.DISABLED_TYPE')}), {variant: 'success'});

        } catch {
            enqueueSnackbar(t('FAQ.TOGGLE_ERROR'), {variant: 'error'});
        }

    }, [toggleId]);

    return (
        <div className={styles.actions}>
            <Tooltip title={t('FAQ.TOGGLE')}>
                <Switch color='success' size='small' checked={!data?.disabled} onClick={onChange} />
            </Tooltip>
            {<Tooltip title={t('ACCOUNTS_PAGE.TOOLTIPS.VIEW')}>
                <IconButton size='small' onClick={() => navigate(routes.FAQ_EDIT(id))}>
                    <VisibilityIcon />
                </IconButton>
            </Tooltip>}
            {
                <AlertDialog 
                    open={!!toggleId}
                    title={t(!data?.disabled ? 'FAQ.DISABLE_ALERT' : 'FAQ.ENABLE_ALERT')}
                    description={t(!data?.disabled ? 'FAQ.DISABLE_ALERT_DESCRIPTION' : 'FAQ.ENABLE_ALERT_DESCRIPTION')}
                    isLoading={isToggleLoading}
                    onClose={handleClose}
                    onConfirm={handleConfirm}
                    confirmColor={'success'}
                    className={styles.dialog}
                />
            }
        </div>
    );
};

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import * as moment from 'moment';
import 'moment/locale/pt-br';

import ptBR from './pt.json';

i18n
    .use(initReactI18next).init({
        resources: {
            'pt': {
                translations: {...ptBR},
            },
        },
        lng: 'pt',
        fallbackLng: 'pt',
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: '.',
        detection: {
            order: ['navigator'],
        },
    });

document.documentElement.lang = i18n.language;

moment.locale('pt-br');

export default i18n;

import {apiSlice} from '@src/store/apiSlice';

export const dashboardApi = apiSlice
    .injectEndpoints({
        endpoints: (builder) => ({
            getDashboardInfo: builder.query({
                query: () => ({url: '/dashboard'}),
            }),
        }),
    });

export const {useGetDashboardInfoQuery} = dashboardApi;

import * as React from 'react';
import Box from '@mui/material/Box';
import MUIStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

export function Stepper({activeStep, steps}) {
    return (
        <Box sx={{width: '100%'}}>
            <MUIStepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </MUIStepper>
        </Box>
    );
}

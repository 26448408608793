import {apiSlice} from '@src/store/apiSlice';

const tutorialsTags = {
    tutorialsTags: 'TUTORIALS',
    listAbstractTag: {type: 'TUTORIALS', id: 'LIST'},
};

const captionsTags = {
    captionsTags: 'CAPTIONS',
    listAbstractTag: {type: 'CAPTIONS', id: 'LIST'},
};

const tutorialsApiSlice = apiSlice
    .enhanceEndpoints({addTagTypes: Object.values(tutorialsTags)})
    .injectEndpoints({
        endpoints: (builder) => ({
            getTutorials: builder.query({
                query: () => {
                    return {
                        url: '/videos',
                        method: 'GET',
                    };
                },
                providesTags: (result) => result
                    ? [...result.map(({id}) => ({type: tutorialsTags.tutorialsTags, id})), tutorialsTags.listAbstractTag]
                    : [tutorialsTags.listAbstractTag],
            }),
            getUploadData: builder.query({
                query: (params) => {
                    return {
                        url: '/videos/thumbnail/url',
                        method: 'GET',
                        params,
                    };
                },
            }),
            AddTutorial: builder.mutation({
                query: ({body}) => {
                    const {title, thumbnail, disabled, tags, roles} = body;
                    return {
                        url: '/videos',
                        method: 'POST',
                        body: {
                            title,
                            thumbnail,
                            disabled,
                            tags,
                            roles,
                        },
                    };
                },
                invalidatesTags: [tutorialsTags.listAbstractTag],
            }),
            getTutorial: builder.query({
                query: ({tutorialId}) => ({url: `/videos/${tutorialId}`}),
                providesTags: (result, _, {tutorialId}) => [{type: tutorialsTags.tutorialsTags, id: tutorialId}],
            }),
            editTutorial: builder.mutation({
                query: ({tutorialId, body}) => {
                    return {
                        url: `videos/${tutorialId}`,
                        method: 'PATCH',
                        body,
                    };
                },
                invalidatesTags: (result, _, {tutorialId}) => result ? [
                    {type: tutorialsTags.tutorialsTags, id: tutorialId}, tutorialsTags.listAbstractTag,
                ] : [],
            }),
            editTutorialVideo: builder.mutation({
                query: ({videoId}) => {
                    return {
                        url: `videos/${videoId}/url`,
                        method: 'PUT',
                    };
                },
                invalidatesTags: (result, _, {tutorialId}) => result ? [
                    {type: tutorialsTags.tutorialsTags, id: tutorialId}, tutorialsTags.listAbstractTag,
                ] : [],
            }),
            toggleTutorial: builder.mutation({
                query: ({videoId, disabled}) => {
                    return {
                        url: `videos/${videoId}/disabled`,
                        method: 'PUT',
                        body: disabled,
                    };
                },
                invalidatesTags: (result, _, args) =>  [{
                    type: tutorialsTags.tutorialsTags, 
                    id: args.videoId,
                }],
            }),
            getCaptions: builder.query({
                query: ({videoId}) => {
                    return {
                        url: `/videos/${videoId}/captions`,
                    };
                },
                providesTags: (result, _, args) =>  [{
                    type: captionsTags.captionsTags, 
                    id: args.videoId,
                }],
            }),
            addCaption: builder.mutation({
                query: ({videoId, body}) => {
                    return {
                        url: `/videos/${videoId}/captions`,
                        method: 'POST',
                        body,
                    };
                },
                invalidatesTags: (result, _, args) =>  [{
                    type: captionsTags.captionsTags, 
                    id: args.videoId,
                }],
            }),
            updateCaption: builder.mutation({
                query: ({videoId, captionId, body}) => {
                    return {
                        url: `/videos/${videoId}/captions/${captionId}`,
                        method: 'PATCH',
                        body,
                    };
                },
                invalidatesTags: (result, _, args) =>  [{
                    type: captionsTags.captionsTags, 
                    id: args.videoId,
                }],
            }),
            deleteCaption: builder.mutation({
                query: ({videoId, captionId}) => {
                    return {
                        url: `/videos/${videoId}/captions/${captionId}`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: (result, _, args) =>  [{
                    type: captionsTags.captionsTags, 
                    id: args.videoId,
                }],
            }),
        }),
        overrideExisting: false,
    });

export const {
    useGetTutorialsQuery,
    useLazyGetUploadDataQuery,
    useAddTutorialMutation, 
    useGetTutorialQuery,
    useEditTutorialMutation,
    useEditTutorialVideoMutation,
    useToggleTutorialMutation,
    useGetCaptionsQuery,
    useAddCaptionMutation, 
    useUpdateCaptionMutation,
    useDeleteCaptionMutation,
} = tutorialsApiSlice;

import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import CredentialsHelper from '@src/utils/credentialsHelper';
import config from '@utils/config';

export const apiSlice = createApi({
    reducerPath: 'api',

    baseQuery: fetchBaseQuery({ 
        baseUrl: config.basePath,
        prepareHeaders: (headers) => {
            const token =  CredentialsHelper.accessToken();
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: () => ({}),
});

export const MIN_TITLE_LENGTH = 1;
export const MAX_TITLE_LENGTH = 80;

export const MIN_DESCRIPTION_LENGTH = 1;
export const MAX_DESCRIPTION_LENGTH = 8192;

export const MIN_TEXT_CONTENT_VALUE_LENGTH = 1;

export const ATTACHMENT_MAX_SIZE_ALLOWED = 10485760;

export const captionLanguages = [
    'Arabic',
    'Cantonese',
    'Catalan',
    'Chinese',
    'Danish',
    'Dutch',
    'English',
    'Finnish',
    'French',
    'German',
    'Greek',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Korean',
    'Malayalam',
    'Mandarin Chinese',
    'Norwegian',
    'Polish',
    'Portuguese',
    'Romanian',
    'Russian',
    'Spanish',
    'Swedish',
    'Taiwanese Mandarin',
    'Tamil',
    'Thai',
    'Turkish',
    'Ukrainian',
    'Urdu',
    'Vietnamese',
    'Welsh',
    'Zulu',
];

export function convertToConstant(text) {
    return text.toUpperCase().split(' ').join('_');
}

export function generateCaptionFormData({file, language}) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('language', language);

    return formData;
}

export function generateUniqueId() {
    const timestamp = Date.now().toString(36);
    const randomNum = Math.random().toString(36);
    return timestamp + randomNum;
}

export const attachmentsAcceptedFiles = 'image/*,.pdf,.xls,.xlsx,.doc,.docx,.txt,.ppt,.pptx';

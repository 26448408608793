import axios from 'axios';

export const downloadFromLink = (url, fileName) => {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.download = fileName;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const uploadFile = async (url, fields, file, type = 'attachment', onUploadProgress, contentDisposition) => {
    const formData = new FormData();
    formData.append('Content-Type', file.type);
    if (contentDisposition){
        formData.append('Content-Disposition', `${type}; filename="${file.name}"`);
    }
    Object.entries(fields).forEach(([k, v]) => formData.append(k, v));
    formData.append('file', file);

    return axios.post(url, formData, {
        onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;

            if (onUploadProgress) {
                onUploadProgress(progress);
            }
        },
    })
        .then(d => d.data)
        .catch(error => {
            throw error;
        });
};

import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {routes} from '@utils/routes';
import {useSelector} from 'react-redux';
import {AuthSelectors} from '@src/modules/auth/auth.reducer';

export default function PrivateRoute({children}) {
    const loggedIn = useSelector(AuthSelectors.isLoggedIn);
    const location = useLocation();
    if (!loggedIn) {
        return <Navigate to={routes.LOGIN} state={{from: location}} />;
    }

    return children ? children : <Outlet />;
}

import * as yup from 'yup';

export const adminsSchema = (t) => {
    return yup.object({
        name: yup.string().trim().required(t('REQUIRED_FIELD')),
        email: yup.string().trim().email(t('INVALID_EMAIL')).required(t('REQUIRED_FIELD')),
        password: yup.string().required(t('REQUIRED_FIELD')),
        disabled: yup.boolean(),
        permissions: yup.object({
            boletos: yup.array().of(
                yup.string()),
            commercialAccounts: yup.array().of(
                yup.string()),
            trainingAccounts: yup.array().of(
                yup.string()),
            faq: yup.array().of(
                yup.string()),
            notes: yup.array().of(
                yup.string()),
            tutorials: yup.array().of(
                yup.string()),
            trainings: yup.array().of(
                yup.string()),
            admins: yup.array().of(
                yup.string()),
        }),
    });
};

export const adminsEditSchema = (t) => {
    return yup.object({
        name: yup.string().required(t('REQUIRED_FIELD')),
        email: yup.string().required(t('REQUIRED_FIELD')),
        password: yup.string(),
        disabled: yup.boolean(),
        permissions: yup.object({
            boletos: yup.array().of(
                yup.string()),
            commercialAccounts: yup.array().of(
                yup.string()),
            trainingAccounts: yup.array().of(
                yup.string()),
            faq: yup.array().of(
                yup.string()),
            notes: yup.array().of(
                yup.string()),
            tutorials: yup.array().of(
                yup.string()),
            trainings: yup.array().of(
                yup.string()),
            admins: yup.array().of(
                yup.string()),
        }),
    });
};

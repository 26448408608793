import React from 'react';
import {Switch, Tooltip, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import styles from './accountResources.module.scss';

export default function AccountResources({formik}){
    const {t} = useTranslation();
    const climateCheckTitle = formik.values.modules?.climateCheck ? t('DISABLE_SEARCH') : t('ACTIVATE_SEARCH');

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <Typography variant='h6' component='h6' mt={1} mb={2}>
                    {t('ACCOUNTS_PAGE.ENABLE_CLIMATE_CHECK')}
                </Typography>
            </div>
            <div className={styles.item}>
                <Tooltip title={climateCheckTitle}>
                    <Switch 
                        onChange={() => formik.setFieldValue('modules.climateCheck', !formik.values?.modules?.climateCheck)}
                        name='modules.climateCheck'
                        checked={formik?.values?.modules?.climateCheck}
                        formik={formik}
                    />
                </Tooltip>
                <div>
                    {climateCheckTitle}
                </div>
            </div>
        </div>
    );
}

import {AccountCircle} from '@mui/icons-material';
import React, {useCallback, useEffect} from 'react';
import {Grid, Paper, Container, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import logo from '@assets/imgs/logo.svg';
import {useFormik} from 'formik';
import {FormikIconTextField} from '@components/FormikFields';
import {changePasswordSchema} from '@modules/auth/schema';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import {useRequestCodeMutation} from '@src/modules/auth/auth.api';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import {setSession} from '@src/modules/auth/auth.reducer';

import styles from './forget-password.module.scss';

export default function ForgetPassword() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();

    const [requestCode, {isLoading, isSuccess, data, error}] = useRequestCodeMutation();

    const handleRequestChange = useCallback((data) => {
        requestCode(data);
    }, []);

    useEffect(() => {
        if (isSuccess) {
            if (data.state === 'email-sent') {
                navigate(routes.LOGIN);
                enqueueSnackbar(t('INSTRUCTIONS_SENT'), {variant: 'success'});
            } else if (data.state === 'validate-code') {
                dispatch(setSession(data.session));
                navigate(routes.INSERT_CODE);
                enqueueSnackbar(t('CODE_SENT'), {variant: 'success'});
            }

        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(t('CODE_SENT_ERROR'), {variant: 'error'});
        }
    }, [error]);

    const formik = useFormik({
        validationSchema: changePasswordSchema(t),
        initialValues: {
            username: '',
        },
        onSubmit: handleRequestChange,
    });

    const formSubmit = e => {
        e.preventDefault();
        formik.handleSubmit();
    };

    return (
        <Container maxWidth='xs' className={styles.container}>
            <img src={logo} />
            <Paper className={styles.paper}>
                <Typography textAlign='center' className={styles.title}>
                    {t('FORGET_PASSWORD')}
                </Typography>
                <Typography textAlign='center' color='textSecondary' className={styles.subtitle}>
                    {t('INSERT_EMAIL')}
                </Typography>
                <form onSubmit={formSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormikIconTextField
                                fullWidth
                                name='username'
                                startIcon={<AccountCircle />}
                                label={t('EMAIL')}
                                formik={formik}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                type='submit'
                                loading={isLoading}
                                variant='contained'
                            >
                                {t('REQUEST')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>  
    );
}

import React, {useEffect, useMemo, useState} from 'react';
import {
    Button,
    Container,
    Typography,
} from '@mui/material';
import {useListAllTrainingAccountsQuery} from '@src/modules/accounts/accounts.api';
import AccountTable from '@src/modules/accounts/components/AccountTable';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import {accountTypes} from '@src/modules/accounts/schema';
import {useCan} from '@src/hooks/useCan';
import {permissionTypes, permissionResources} from '@src/hooks/usePrivateRoutes';
import Divider from '@src/components/Divider';
import {SearchForm} from '@src/components/SearchForm';

import styles from './list.module.scss';

export default function DemoAccounts() {
    const [pollingInterval, setPollingInterval] = useState(0);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const can = useCan();

    const [search, setSearch] = useState('');

    const params = useMemo(() => {
        const params = {};

        if (search) {
            params.search = search;
        }

        return params;
    }, [search]);

    const {data, isLoading} = useListAllTrainingAccountsQuery({
        type: accountTypes.trainings,
        params,
    }, {
        pollingInterval,
        skipPollingIfUnfocused: true,
    });

    useEffect(() => {
        if (data?.isPreparing) {
            setPollingInterval(1000);
        }
    }, [data?.isPreparing]);

    return (
        <Container maxWidth='xl'>
            <div className={styles.accountsListHeader}>
                <Typography variant='h3' component='h2'>
                    {t('DEMO_ACCOUNTS')}
                </Typography>
                {can(permissionResources.trainingAccounts, permissionTypes.create) && <Button variant='contained' onClick={() => navigate(routes.DEMO_ACCOUNT_CREATE)}>
                    {t('ACCOUNTS_PAGE.CREATE_ACT')}
                </Button>}
            </div>

            <Divider />

            <SearchForm setSearch={setSearch} />

            <AccountTable
                accountType={accountTypes.trainings}
                data={data?.data}
                isLoading={isLoading}
                handleEdit={(id) => navigate(routes.ACCOUNT_EDIT(accountTypes.trainings, id))}
            />
        </Container>
    );
}

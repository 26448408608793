import React from 'react';
import {useTranslation} from 'react-i18next';
import SuccessfulAction from '@assets/imgs/SuccessfulAction.svg';
import SvgIcon from '@src/components/SvgIcon';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Divider} from '@mui/material';
import {useGetAccountUsersQuery} from '@src/modules/accounts/accounts.api';
import {accountTypes} from '@src/modules/accounts/schema';

import styles from './finished-import-content.module.scss';

export default function FinishedImportContent({translationImportType, route}) {
    const {accountId} = useParams();
    const {data: usersAmount} = useGetAccountUsersQuery({id: `${accountTypes.trainings}/${accountId}`}, {skip: !accountId});

    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={styles.content}>
            <img src={SuccessfulAction} alt='successfulAction' className={styles.image} />
            <div className={styles.information}>
                <div className={styles.check}>
                    <SvgIcon className={styles.icon} name='SmallGreenCheck' />
                    <h1 className={styles.ready}>{t('IMPORT_USERS.READY')}</h1>
                </div>
                <span className={styles.importedSuccessfully}>{t(translationImportType.importedSuccessFully)}</span>
                <Divider className={styles.divider} />
                <span className={styles.total}>
                    {t(translationImportType.total, {numberOfRecords: usersAmount?.active})}
                </span>
                <Button
                    className={styles.button}
                    onClick={() => navigate(route)}
                    variant='outlined'
                >
                    {t(translationImportType.seeList)}
                </Button>
            </div>
        </div>
    );
}

import {Dialog} from '@reach/dialog';
import React from 'react';

import Divider from '../Divider';
import SvgIcon from '../SvgIcon';

import styles from './modal.module.scss';

export default function Modal({
    title,
    subTitle,
    children,
    modalWidth,
    modalHeight,
    onClose,
    className = '',
    sectionClassName = '',
}) {
    return (
        <Dialog aria-label='modal' className={className} onDismiss={onClose}>
            <div className={styles.modalContainer} aria-label='modal content'>
                <section className={`${styles.modalSection} ${sectionClassName}`} style={{width: modalWidth, height: modalHeight}}>
                    <header className={styles.header}>
                        <div className={styles.text}>
                            {title && <div className={styles.title}>{title}</div>}
                            {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
                        </div>
                        <button onClick={onClose}>
                            <SvgIcon name='Close' className={styles.close} />
                        </button>
                    </header>
                    <Divider />
                    <div className={styles.content}>
                        {children}
                    </div>
                </section>
            </div>
        </Dialog>
    );
}

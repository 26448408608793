import {Box, Typography} from '@mui/material';
import React from 'react';

export default function UserInfo({isMobile}) {

    if (isMobile) {
        return <MobileUserInfo />;
    }

    return (
        <Box>
            <Typography fontWeight='bold' textTransform='upp'>
                {/* ToDo: Informações do usuario */}
            </Typography>
        </Box>
    );
}

const MobileUserInfo = () => {
    return (
        <Box padding='20px' textAlign='center' sx={{backgroundColor: (theme) => theme.palette.primary.dark}}>
            <Typography fontWeight='bold' textTransform='upp'>
                {/* ToDo: Informações do usuario */}
            </Typography>
        </Box>
    );
};

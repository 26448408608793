import React, {useEffect, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {
    Grid,
    IconButton,
    InputAdornment,
} from '@mui/material';
import {FormikTextField} from '@src/components/FormikFields';
import {
    useUpdateResponsiblePasswordMutation,
} from '@src/modules/accounts/accounts.api';
import {changePasswordAccountSchema} from '@src/modules/accounts/schema';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import Modal from '@src/components/Modal';
import {Visibility, VisibilityOff} from '@mui/icons-material';

function AccountPasswordModal({id, setId}) {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const [updateResponsiblePassword, {isLoading, isSuccess, error}] =
    useUpdateResponsiblePasswordMutation();

    const [showPassword, setShowPassword] = useState(false);

    const formik = useFormik({
        validationSchema: changePasswordAccountSchema(t),
        initialValues: {
            password: '',
        },
        onSubmit: async ({password}) => {
            await updateResponsiblePassword({
                id,
                body: {
                    password,
                },
            }).unwrap();
        },
    });

    const formSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
 
        formik.handleSubmit();
    };

    const handleClose = () => {
        setId(undefined);

        formik.handleReset();
    };

    const toggleShowPassword = () => {
        setShowPassword((old) => !old);
    };

    useEffect(() => {
        if (isSuccess) {
            formik.handleReset();
            setId(undefined);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(t('ACCOUNTS_PAGE.CHANGE_PASSWORD_ERROR'), {variant: 'error'});
        }
    }, [error]);

    return (
        <Modal
            title={t('ACCOUNTS_PAGE.CHANGE_PASSWORD_TITLE')}
            subTitle={t('ACCOUNTS_PAGE.CHANGE_PASSWORD_SUBTITLE')}
            modalWidth={462}
            onClose={handleClose}
    >
            <form onSubmit={formSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormikTextField
                            fullWidth
                            name='password'
                            label={t('NEW_PASSWORD')}
                            type={showPassword ? 'text' : 'password'}
                            formik={formik}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={toggleShowPassword} edge='end'>
                                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <LoadingButton
                            type='submit'
                            loading={isLoading}
                            variant='contained'
                            fullWidth
                        >
                            {t('ACCOUNTS_PAGE.SAVE')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </Modal>
    );
}

export default AccountPasswordModal;

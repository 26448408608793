import React from 'react';
import SvgIcon from '@src/components/SvgIcon';
import cx from 'classnames';

import styles from './content-type-card.module.scss';

export function ContentTypeCard({onSelect, iconName, typeLabel, className}) {
    return (
        <div role='button' className={cx(styles.container, className)} onClick={onSelect}>
            <SvgIcon name={iconName} className={styles.icon} />
            <span className={styles.label}>{typeLabel}</span>
        </div>
    );
}

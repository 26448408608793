import React from 'react';
import {Box, LinearProgress, Typography} from '@mui/material';

export function VideoUploadProgress(props) {
    const percentage = `${Math.round(Number(props.value))}%`;

    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant='determinate' sx={{height: 10, borderRadius: 8}} {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography 
                    variant='body2'
                    color='text.secondary'
                >
                    {percentage}
                </Typography>
            </Box>
        </Box>
    );
}

import {apiSlice} from '@src/store/apiSlice';

const faqTags = {
    faqTags: 'FAQ',
    listAbstractTag: {type: 'FAQ', id: 'LIST'},

};

const faqApiSlice = apiSlice
    .enhanceEndpoints({addTagTypes: Object.values(faqTags)})
    .injectEndpoints({
        endpoints: (builder) => ({
            getFrequentlyAskedQuestions: builder.query({
                query: () => {
                    return {
                        url: '/faq',
                        method: 'GET',
                    };
                },
                providesTags: (result) => result
                    ? [...result.map(({id}) => ({type: faqTags.faqTags, id})), faqTags.listAbstractTag]
                    : [faqTags.listAbstractTag],
            }),
            AddFrequentlyAskedQuestions: builder.mutation({
                query: ({body}) => {
                    return {
                        url: '/faq',
                        method: 'POST',
                        body,
                    };
                },
                invalidatesTags: [faqTags.listAbstractTag],
            }),
            getFrequentlyAskedQuestion: builder.query({
                query: ({faqId}) => ({url: `faq/${faqId}`}),
                providesTags: (result, error, {faqId}) => [{type: faqTags.faqTags, id: faqId}],
            }),
            editFrequentlyAskedQuestion: builder.mutation({
                query: ({faqId, body}) => {
                    return {
                        url: `faq/${faqId}`,
                        method: 'PATCH',
                        body,
                    };
                },
                invalidatesTags: (result, error, {faqId}) => result ? [
                    {type: faqTags.faqTags, id: faqId}, faqTags.listAbstractTag,
                ] : [],
            }),
            toggleFrequentlyAskedQuestion: builder.mutation({
                query: ({faqId, body}) => {
                    return {
                        url: `faq/${faqId}/disabled`,
                        method: 'PUT',
                        body,
                    };
                },
                invalidatesTags: [faqTags.listAbstractTag],
            }),
        }),
        overrideExisting: false,
    });

export const {
    useGetFrequentlyAskedQuestionsQuery,
    useAddFrequentlyAskedQuestionsMutation, 
    useGetFrequentlyAskedQuestionQuery,
    useEditFrequentlyAskedQuestionMutation,
    useToggleFrequentlyAskedQuestionMutation,
} = faqApiSlice;

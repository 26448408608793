import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import config from '@utils/config';

export const authApi = createApi({
    reducerPath: 'authApi',

    baseQuery: fetchBaseQuery({ 
        baseUrl: config.basePath,
    }),

    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: '/auth/token',
                method: 'POST',
                body: {
                    ...credentials,
                    grant_type: 'password',
                    client_id: config.clientId,
                },

            }),
        }),

        requestCode: builder.mutation({
            query: (payload) => ({
                url: '/auth/change-password',
                method: 'POST',
                body: {
                    ...payload,
                    client_id: config.clientId,
                },
            }),
        }),

        insertCode: builder.mutation({
            query: (payload) => ({
                url: '/auth/validate-code',
                method: 'POST',
                body: {...payload},
            }),
        }),

        resendCode: builder.mutation({
            query: (payload) => ({
                url: '/auth/resend-code',
                method: 'POST',
                body: {...payload},
            }),
        }),

        resetPassword: builder.mutation({
            query: (passwords) => ({
                url: '/auth/set-password',
                method: 'POST',
                body: {
                    ...passwords,
                },
            }),
        }),
    }),

});

export const {useLoginMutation, useRequestCodeMutation, useInsertCodeMutation, useResendCodeMutation, useResetPasswordMutation} = authApi;

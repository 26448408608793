import React, {createContext, useMemo, useState} from 'react';
import {Box, IconButton, Tooltip} from '@mui/material';
import Table from '@src/components/Table';
import {useTranslation} from 'react-i18next';
import * as moment from 'moment';
import EmployeeName from '@src/components/EmployeeName';
import {routes} from '@src/utils/routes';
import {useNavigate} from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountName from '@src/components/AccountName';

import styles from './notesTable.module.scss';

const NotesTableContext = createContext({});

function NotesTable({data, isLoading, setPage, pageSize, total, page}) {
    const {t} = useTranslation();
    const [id, setId] = useState(null);
    const columns = useMemo(() => [
        {accessor: 'user', Header: t('SUGGESTIONS_PAGE.AUTHOR'), Cell: (props) => <EmployeeName accountId={props?.cell?.row?.original?.account} employeeId={props?.value} />},
        {accessor: 'account', Header: t('SUGGESTIONS_PAGE.COMPANY'), Cell: (props) => <AccountName accountData={props?.cell?.row?.original?.account} />},
        {accessor: 'created_at', Header: t('SUGGESTIONS_PAGE.CREATED_AT')},
        {accessor: 'text', Header: t('SUGGESTIONS_PAGE.CONTENT')},
    ], [t]);
    const rows = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.map((row) => ({
            id: row.id,
            user: row.user,
            account: row.account,
            created_at: moment(row.created_at).format('DD/MM/YYYY'),
            text: row.text,
        }));
    }, [data]);

    return (
        <NotesTableContext.Provider value={{rows, id, setId}}>
            <Table 
                columns={columns}
                data={rows}
                notFoundText={t('ACCOUNTS_PAGE.NO_DATA_FOUND')}
                Actions={Actions}
                actionsClassName={styles.actions}
                loading={isLoading}
                setPage={setPage}
                pageSize={pageSize}
                total={total}
                pageActual={page}
            />
        </NotesTableContext.Provider>
    );
}
const Actions = ({id}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onOpen = () => {
        navigate(routes.SUGGESTIONS_DETAILS(id));
    };
    return (
        <div className={styles.actions}>
            <Box ml={2}>
                <Tooltip title={t('ACCOUNTS_PAGE.TOOLTIPS.VIEW')}>
                    <IconButton size='small' onClick={onOpen}>
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </div>
    );
};
export default NotesTable;

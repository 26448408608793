import React from 'react';

import CharactersCount from '../CharactersCount';

import styles from './editor-footer.module.scss';
export default function EditorFooter({editor, error, helperText}){
    return (
        <div className={styles.content}>
            <span className={styles.helperText}>{error ? helperText : ''}</span>
            <CharactersCount editor={editor} />
        </div>
    );
}

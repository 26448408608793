import React, {useMemo, useState} from 'react';
import {
    Button,
    Container,
    Typography,
} from '@mui/material';
import {useListAllQuery} from '@src/modules/accounts/accounts.api';
import AccountTable from '@src/modules/accounts/components/AccountTable';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import {accountTypes, searchTypes} from '@src/modules/accounts/schema';
import {useCan} from '@src/hooks/useCan';
import {permissionTypes, permissionResources} from '@src/hooks/usePrivateRoutes';
import Divider from '@src/components/Divider';
import {SearchForm} from '@src/components/SearchForm';

import styles from './list.module.scss';

export default function Accounts() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const can = useCan();

    const [search, setSearch] = useState('');
    const [searchType, setSearchType] = useState('');

    const params = useMemo(() => {
        const params = {};

        if (search) {
            params.search = search;
        }
        if (searchType) {
            params.type = searchType === searchTypes.ALL ? undefined : searchType;
        }

        return params;
    }, [search, searchType]);

    const {
        data,
        isLoading,
    } = useListAllQuery({
        type: accountTypes.commercials,
        params,
    });

    return (
        <Container maxWidth='xl'>
            <div className={styles.accountsListHeader}>
                <Typography variant='h3' component='h2'>
                    {t('ACCOUNTS')}
                </Typography>
                {
                    can(permissionResources.commercialAccounts, permissionTypes.create) &&
                        <Button variant='contained' onClick={() => navigate(routes.ACCOUNT_CREATE)}>
                            {t('ACCOUNTS_PAGE.CREATE_ACT')}
                        </Button>
                }
            </div>

            <Divider />

            <SearchForm setSearch={setSearch} setSearchType={setSearchType} />

            <AccountTable
                accountType={accountTypes.commercials}
                data={data}
                isLoading={isLoading}
                handleEdit={(id) => (
                    navigate(routes.ACCOUNT_EDIT(accountTypes.commercials, id)))
                }
            />
        </Container>
    );
}

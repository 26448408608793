import {apiSlice} from '@src/store/apiSlice';

const trainingsTags = {
    trainingsTags: 'TRAININGS',
    listAbstractTag: {type: 'TRAININGS', id: 'LIST'},
};

const topicsTags = {
    topicsTags: 'TOPICS',
    listAbstractTag: {type: 'TOPICS', id: 'LIST'},
};

const contentsTags = {
    contentsTags: 'CONTENTS',
    listAbstractTag: {type: 'CONTENTS', id: 'LIST'},
};

const captionsTags = {
    captionsTags: 'CAPTIONS',
    listAbstractTag: {type: 'CAPTIONS', id: 'LIST'},
};

const trainingsApiSlice = apiSlice
    .enhanceEndpoints({addTagTypes: [
        ...Object.values(trainingsTags),
        ...Object.values(topicsTags),
        ...Object.values(contentsTags),
        ...Object.values(captionsTags),
    ]})
    .injectEndpoints({
        endpoints: (builder) => ({
            createTraining: builder.mutation({
                query: ({body}) => {
                    const {title, categories, description, allowedAccounts} = body;
                    return {
                        url: '/trainings',
                        method: 'POST',
                        body: {
                            title,
                            categories,
                            description,
                            allowedAccounts,
                        },
                    };
                },
                async onQueryStarted({image}, {dispatch, queryFulfilled}) {
                    let patchResult = null;
                    try {
                        const {data: created} = await queryFulfilled;
                        patchResult = dispatch(
                            apiSlice.util.updateQueryData('getTrainings', undefined, (draft) => {
                                const modified = [...draft, {...created, thumbnail: image}];
                                modified.sort((a, b) => a.id.localeCompare(b.id));
                                return modified;
                            }),
                        );
                    } catch {
                        if (patchResult) {
                            patchResult.undo();
                        }
                    }
                },
            }),
            getTrainings: builder.query({
                query: () => {
                    return {
                        url: '/trainings',
                        method: 'GET',
                    };
                },
                providesTags: (result) => result
                    ? [...result.map(({id}) => ({type: trainingsTags.trainingsTags, id})), trainingsTags.listAbstractTag]
                    : [trainingsTags.listAbstractTag],
            }),
            getTraining: builder.query({
                query: ({trainingId}) => ({url: `/trainings/${trainingId}`}),
                providesTags: (result, _, {trainingId}) => [{type: trainingsTags.trainingsTags, id: trainingId}],
            }),
            editTraining: builder.mutation({
                query: ({trainingId, body}) => {
                    return {
                        url: `/trainings/${trainingId}`,
                        method: 'PUT',
                        body,
                    };
                },
                async onQueryStarted({trainingId, image}, {dispatch, queryFulfilled}) {
                    let patchResultList = null;
                    let patchResultEdit = null;
                    try {
                        const {data: edited} = await queryFulfilled;
                        patchResultList = dispatch(
                            apiSlice.util.updateQueryData('getTrainings', undefined, (draft) => {
                                const modified = draft.map(d => d.id === trainingId ? ({...edited, thumbnail: image || d.thumbnail}) : d);
                                modified.sort((a, b) => a.id.localeCompare(b.id));
                                return modified;
                            }),
                        );
                        patchResultEdit = dispatch(
                            apiSlice.util.updateQueryData('getTraining', {trainingId}, (draft) => {
                                return {...edited, thumbnail: image || draft.thumbnail};
                            }),
                        );
                    } catch {
                        if (patchResultList) {
                            patchResultList.undo();
                        }
                        if (patchResultEdit) {
                            patchResultEdit.undo();
                        }
                    }
                },
            }),
            toggleTraining: builder.mutation({
                query: ({trainingId, disabled}) => {
                    return {
                        url: `/trainings/${trainingId}/disabled`,
                        method: 'PUT',
                        body: disabled,
                    };
                },
                invalidatesTags: (result, _, args) =>  [{
                    type: trainingsTags.trainingsTags, 
                    id: args.trainingId,
                }],
            }),
            getTrainingThumbnailUrl: builder.query({
                query: ({trainingId, params}) => {
                    return {
                        url: `/trainings/${trainingId}/thumbnailUrl`,
                        method: 'GET',
                        params,
                    };
                },
            }),
            createTrainingTopic: builder.mutation({
                query: ({trainingId, title}) => {
                    return {
                        url: `/trainings/${trainingId}/topics`,
                        method: 'POST',
                        body: {
                            title,
                        },
                    };
                },
            }),
            getTrainingTopics: builder.query({
                query: ({trainingId}) => {
                    return {
                        url: `/trainings/${trainingId}/topics`,
                        method: 'GET',
                    };
                },
                providesTags: (result) => result
                    ? [...result.map(({id}) => ({type: topicsTags.topicsTags, id})), topicsTags.listAbstractTag]
                    : [topicsTags.listAbstractTag],
            }),
            getTrainingTopic: builder.query({
                query: ({trainingId, topicId}) => {
                    return {
                        url: `/trainings/${trainingId}/topics/${topicId}`,
                        method: 'GET',
                    };
                },
                providesTags: (result) => result
                    ? [...result.map(({id}) => ({type: topicsTags.topicsTags, id})), topicsTags.listAbstractTag]
                    : [topicsTags.listAbstractTag],
            }),
            editTrainingTopic: builder.mutation({
                query: ({trainingId, topicId, title}) => {
                    return {
                        url: `/trainings/${trainingId}/topics/${topicId}`,
                        method: 'PUT',
                        body: {
                            title,
                        },
                    };
                },
                invalidatesTags: (result, _, {topicId}) => result ? [
                    {type: topicsTags.topicsTags, id: topicId},
                    topicsTags.listAbstractTag,
                ] : [],
            }),
            deleteTopic: builder.mutation({
                query: ({trainingId, topicId}) => {
                    return {
                        url: `/trainings/${trainingId}/topics/${topicId}`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: (result, _, args) =>  [{
                    type: topicsTags.topicsTags, 
                    id: args.topicId,
                }],
            }),
            createContent: builder.mutation({
                query: ({trainingId, topicId, body: {value, type}}) => {
                    return {
                        url: `/trainings/${trainingId}/topics/${topicId}/contents`,
                        method: 'POST',
                        body: {
                            type,
                            value,
                        },
                    };
                },
                invalidatesTags: [topicsTags.listAbstractTag],
            }),
            getContent: builder.query({
                query: ({trainingId, topicId, contentId}) => {
                    return {
                        url: `/trainings/${trainingId}/topics/${topicId}/contents/${contentId}`,
                        method: 'GET',
                    };
                },
                providesTags: (result, _, {contentId}) => [{type: contentsTags.contentsTags, id: contentId}],
            }),
            editContent: builder.mutation({
                query: ({trainingId, topicId, contentId, body: {type, value}}) => {
                    return {
                        url: `/trainings/${trainingId}/topics/${topicId}/contents/${contentId}`,
                        method: 'PUT',
                        body: {
                            type,
                            value,
                        },
                    };
                },
                invalidatesTags: (result, _, args) =>  [{
                    type: contentsTags.contentsTags, 
                    id: args.contentId,
                }],
            }),
            createVideoContent: builder.mutation({
                query: ({trainingId, topicId}) => {
                    return {
                        url: `/trainings/${trainingId}/topics/${topicId}/contents/video`,
                        method: 'POST',
                    };
                },
            }),
            getVideoCaptions: builder.query({
                query: ({trainingId, topicId, contentId}) => {
                    return {
                        url: `/trainings/${trainingId}/topics/${topicId}/contents/${contentId}/captions`,
                        method: 'GET',
                    };
                },
                providesTags: (result, _, args) =>  [{
                    type: captionsTags.captionsTags, 
                    id: args.contentId,
                }],
            }),
            createVideoCaption: builder.mutation({
                query: ({trainingId, topicId, contentId, body}) => {                    
                    return {
                        url: `/trainings/${trainingId}/topics/${topicId}/contents/${contentId}/captions`,
                        method: 'POST',
                        body,
                    };
                },
            }),
            editVideoCaption: builder.mutation({
                query: ({trainingId, topicId, contentId, captionId, body}) => {
                    return {
                        url: `/trainings/${trainingId}/topics/${topicId}/contents/${contentId}/captions/${captionId}`,
                        method: 'PATCH',
                        body,
                    };
                },
                invalidatesTags: (result, _, args) =>  [{
                    type: captionsTags.captionsTags, 
                    id: args.contentId,
                }],
            }),
            deleteVideoCaption: builder.mutation({
                query: ({trainingId, topicId, contentId, captionId}) => {
                    return {
                        url: `/trainings/${trainingId}/topics/${topicId}/contents/${contentId}/captions/${captionId}`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: (result, _, args) =>  [{
                    type: captionsTags.captionsTags, 
                    id: args.contentId,
                }],
            }),
            getAttachmentContentUrl: builder.query({
                query: ({trainingId, topicId, contentId, params}) => {
                    return {
                        url: `/trainings/${trainingId}/topics/${topicId}/contents/${contentId}/attUrl`,
                        method: 'GET',
                        params,
                    };
                },
            }),
            deleteAttachmentContent: builder.mutation({
                query: ({trainingId, topicId, contentId, attachmentId}) => {
                    return {
                        url: `/trainings/${trainingId}/topics/${topicId}/contents/${contentId}/attachments/${attachmentId}`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: (result, _, args) =>  [{
                    type: contentsTags.contentsTags, 
                    id: args.videoId,
                }],
            }),
        }),
        overrideExisting: false,
    });

export const {
    useCreateTrainingMutation,
    useGetTrainingsQuery,
    useGetTrainingQuery,
    useEditTrainingMutation,
    useToggleTrainingMutation,
    useGetTrainingThumbnailUrlQuery,
    useLazyGetTrainingThumbnailUrlQuery,
    useCreateTrainingTopicMutation,
    useGetTrainingTopicsQuery,
    useGetTrainingTopicQuery,
    useEditTrainingTopicMutation,
    useDeleteTopicMutation,
    useCreateContentMutation,
    useGetContentQuery,
    useEditContentMutation,
    useCreateVideoContentMutation,
    useGetAttachmentContentUrlQuery,
    useGetVideoCaptionsQuery,
    useLazyGetVideoCaptionsQuery,
    useCreateVideoCaptionMutation,
    useEditVideoCaptionMutation,
    useDeleteVideoCaptionMutation,
    useLazyGetAttachmentContentUrlQuery,
    useDeleteAttachmentContentMutation,
} = trainingsApiSlice;

export function invalidateTopics()  {
    return trainingsApiSlice.util.invalidateTags([topicsTags.listAbstractTag]);
}

export function invalidateTrainings(trainingId)  {
    return trainingsApiSlice.util.invalidateTags([trainingsTags.listAbstractTag, trainingId ? {type: trainingsTags.trainingsTags, id: trainingId} : {}]);
}

export function invalidateContent(contentId)  {
    return trainingsApiSlice.util.invalidateTags([contentsTags.listAbstractTag, contentId ? {type: contentsTags.contentsTags, id: contentId} : {}]);
}

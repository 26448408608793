import config from './config';

const CredentialsHelper = {
    update: function (accessToken) {
        localStorage.setItem(config.accessToken, accessToken);
    },
    clear: function () {
        localStorage.removeItem(config.accessToken);
    },
    accessToken: () => localStorage.getItem(config.accessToken),
    isLoggedIn: () => Boolean(CredentialsHelper.accessToken()),
};

export default CredentialsHelper;

import React, {useState} from 'react';
import {Button, IconButton, InputAdornment, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FormikTextField} from '@src/components/FormikFields';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useParams} from 'react-router-dom';
import cx from 'classnames';
import {permissionResources, permissionTypes} from '@src/hooks/usePrivateRoutes';
import {useCan} from '@src/hooks/useCan';

import AccountPasswordModal from '../AccountPasswordModal';
import {accountTypes} from '../../schema';

import styles from './responsibleDetails.module.scss';
export default function ResponsibleDetails({formik, showPasswordConfirmation = true, readOnly, disabled, isEdit}){
    const {t} = useTranslation();
    const {accountType, id: userId} = useParams();
    const [id, setId] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const can = useCan();

    const permissionResource = accountType === accountTypes.commercials ? permissionResources.commercialAccounts : permissionResources.trainingAccounts;
    
    const toggleShowPassword = () => {
        setShowPassword(old => !old);
    };

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <Typography variant='h6' component='h6' mt={1} mb={2}>
                    {t('ACCOUNTS_PAGE.ADMIN_INFORMATION')}
                </Typography>
            </div>
            <div className={styles.row}>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='responsible.name'
                        data-qa='account-responsible-name'
                        label={t('ACCOUNTS_PAGE.FORM.NAME_ADMIN')}
                        formik={formik}
                        value={formik.values?.responsible?.name}
                        onChange={(e) => formik.setFieldValue('responsible.name', e.target.value)}
                        error={Boolean(formik.touched?.responsible?.name && formik.errors?.responsible?.name)}
                        helperText={formik.touched?.responsible?.name && formik.errors?.responsible?.name}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </div>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='re'
                        data-qa='account-responsable-email'
                        autoComplete={'off'}
                        label={t('ACCOUNTS_PAGE.FORM.EMAIL_ADMIN')}
                        formik={formik}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </div>
            </div>
            {showPasswordConfirmation && (
                <div className={styles.row}>
                    <div className={styles.item}>
                        <div className={styles.password}>
                            <FormikTextField
                                fullWidth
                                formik={formik}
                                name='rp'
                                data-qa='account-rp'
                                label={t('PASSWORD')}
                                type={showPassword ? 'text' : 'password'}
                                autoComplete='new-password'
                                InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    onClick={toggleShowPassword}
                                                    edge='end'
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                }}
                        />
                        </div>
                    </div>
                </div>
            )}
            {isEdit && can(permissionResource, permissionTypes.edit) && (
                <div className={cx(styles.row, styles.changePasswordContainer)}>
                    <Button
                        variant='outlined'
                        data-qa='create-account-change-password'
                        className={styles.changePasswordButton}
                        onClick={() => {
                            setId(userId);
                        }}>
                        {t('ACCOUNTS_PAGE.CHANGE_ADMIN_PASSWORD')}
                    </Button> 
                </div>
            )}
            {!!id && (
                <AccountPasswordModal id={`${accountType}/${id}`} setId={setId} />
            )}
        </div>
    );
}

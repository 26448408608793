import React from 'react';
import {useDebounce} from '@src/hooks/useDebounce';
import {Formik, useFormik} from 'formik';
import {useEffect, useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {searchTypes, translateSearchType} from '@src/modules/accounts/schema';
import cx from 'classnames';

import styles from './search-form.module.scss';

export function SearchForm({setSearch, setSearchType}) {
    const {t} = useTranslation();

    const [inputValue, setInputValue] = useState('');
    const [typeValue, setTypeValue] = useState(searchTypes.ALL);
    const debouncedSearchTerm = useDebounce(inputValue);
    const hasSearchType = !!setSearchType;
    const formik = useFormik({
        initialValues: {
            search: '',
            type: '',
        },
    });

    useEffect(() => {
        setSearch(debouncedSearchTerm);
        hasSearchType && setSearchType(typeValue);
    }, [debouncedSearchTerm, typeValue]);

    return (
        <div className={styles.searchContainer}>
            <Formik>
                <div className={styles.row}>
                    <div 
                        className={cx({
                            [styles.searchItem]: !hasSearchType,
                            [styles.searchItemWithType]: hasSearchType,
                         })
                    }>
                        <TextField
                            id='search'
                            name='search'
                            fullWidth={!hasSearchType}
                            data-qa='input-search'
                            label={t('ACCOUNTS_PAGE.SEARCH_BY_ACCOUNT_NAME')}
                            value={inputValue}
                            onChange={(e) => {
                                setInputValue(e.target.value);
                                formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            error={formik.touched.search && Boolean(formik.errors.search)}
                            helperText={formik.touched.search && formik.errors.search}
                        />
                    </div>
                    {hasSearchType && (
                        <div className={styles.searchItemSelect}>
                            <FormControl>
                                <InputLabel id='type'>{t('ACCOUNTS_PAGE.SEARCH_BY_ACCOUNT_TYPE')}</InputLabel>
                                <Select
                                    id='type'
                                    name='type'
                                    data-qa='input-type'
                                    placeholder={t('ACCOUNTS_PAGE.SEARCH_BY_ACCOUNT_TYPE')}
                                    label={t('ACCOUNTS_PAGE.SEARCH_BY_ACCOUNT_TYPE')}
                                    value={typeValue}
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => {
                                    setTypeValue(e.target.value);
                                    formik.handleChange(e);
                                    }}
                                >           
                                    {Object.values(searchTypes).map((value) => (
                                        <MenuItem key={value} value={value}>
                                            {t(translateSearchType[value])}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                </div>
            </Formik>
        </div>
    );
}

import React from 'react';
import {Typography} from '@mui/material';
import cx from 'classnames';

import styles from './info-card.module.scss';

export function InfoCard({
    className,
    name,
    total,
}) {
    const formatNumber = (number) => {
        let num  = number.toString();
        let formatNum = num.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        return formatNum;
    };
    return (
        <div className={cx(styles.infoCard, className)}>
            <div className={styles.aside}>
                <div className={styles.description}>
                    <Typography className={styles.cardName}>
                        {name}
                    </Typography>
                </div>
            </div>
            <div className={styles.total}>
                <Typography variant='h5'>{formatNumber(total)}</Typography>
            </div>
        </div>
    );
}

import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useRef, useState} from 'react';
import {Button, Container, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import {FormikTextField} from '@src/components/FormikFields';
import {convertToConstant, generateCaptionFormData} from '@src/modules/trainings/utils';
import {topicVideoSchema} from '@src/modules/trainings/schema';
import {invalidateTopics, useCreateContentMutation, useCreateTrainingTopicMutation, useCreateVideoCaptionMutation, useCreateVideoContentMutation} from '@src/modules/trainings/trainings.api';
import {LoadingButton} from '@mui/lab';
import {uploadVideo} from '@src/modules/uploads/uploadVideo';

import {NewCaptionModal} from '../NewCaptionModal';

import styles from './video-form.module.scss';

export function VideoForm({trainingId, setActiveTopic}) {
    const [videoUrl, setVideoUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [isCaptionsModalOpen, setIsCaptionsModalOpen] = useState(false);

    const [createTopic] = useCreateTrainingTopicMutation();
    const [createVideo] = useCreateVideoContentMutation();
    const [createVideoContent] = useCreateContentMutation();
    const [createCaption] = useCreateVideoCaptionMutation();

    const videoRef = useRef(null);
    const inputVideoRef = useRef(null);
    
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const initialValues = {
        title: '',
        video: '',
        captions: [],
    };

    const formik = useFormik({
        validationSchema: topicVideoSchema(t),
        initialValues,
        onSubmit: handleSubmit,
    });

    function addFileInInput({file}) {
        if (file) {
            formik.setFieldValue('video', file);
            const videoUrl = URL.createObjectURL(file);

            setVideoUrl(videoUrl);
        } else {
            formik.setFieldValue('video', '');
            setVideoUrl('');
        }
    }
    
    function removeCaptionItem(languageToRemove) {
        const currentCaptions = formik.values.captions;
        const captionsUpdated = currentCaptions.filter(caption => caption.language !== languageToRemove);
    
        formik.setFieldValue('captions', captionsUpdated);
    }

    function addCaptionItem(caption) {
        const currentCaptions = formik.values.captions;
        const newCaptions = [
            ...currentCaptions,
            {
                language: caption.language,
                file: caption.file,
            },
        ];
        
        formik.setFieldValue('captions', newCaptions);
    }
    
    async function handleUploadVideo({video, uploadData, setUploadPercentage}) {
        try {
            await uploadVideo({
                video, 
                uploadData,
                setUploadPercentage,
            });
        } catch {
            throw new Error('TRAININGS_PAGE.UPLOAD_ERROR');
        }
    }

    async function uploadCaptions({captions, trainingId, topicId, contentId, addCaption}) {
        try {
            const captionsPromises = captions.map(async (caption) => {
                const captionsFormData = generateCaptionFormData({file: caption.file, language: caption.language});
    
                return addCaption({
                    trainingId,
                    topicId,
                    contentId,
                    body: captionsFormData,
                }).unwrap();
            });
    
            await Promise.all(captionsPromises);
        } catch {
            throw new Error('TRAININGS_PAGE.CAPTION_ERROR');
        }
    }

    function formSubmit(e)  {
        e.preventDefault();
        formik.handleSubmit();
    }

    async function handleSubmit(form) {
        setIsLoading(true);
        try {
            const {title, video, captions} = form;

            const topicResponse = await createTopic({
                trainingId,
                title,
            }).unwrap();
            
            const videoResponse = await createVideo({
                trainingId,
                topicId: topicResponse.id,
            }).unwrap();
            
            await handleUploadVideo({
                video,
                uploadData: videoResponse,
                setUploadPercentage,
            });

            const contentResponse = await createVideoContent({
                trainingId, 
                topicId: topicResponse.id,
                body: {
                    type: 'video',
                    value: videoResponse.id,
                },
            }).unwrap();
            
            if (form.captions.length > 0) {                    
                await uploadCaptions({
                    trainingId,
                    captions, 
                    topicId: topicResponse.id,
                    contentId: contentResponse.id,
                    addCaption: createCaption,
                });
            }

            invalidateTopics();

            setActiveTopic({
                ...topicResponse,
                content: contentResponse.id,
            });
            
            enqueueSnackbar(t('TRAININGS_PAGE.CREATE_VIDEO_SUCCESS'), {variant: 'success'});
        } catch (error) {
            enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.CREATE_VIDEO_ERROR'), {variant: 'error'});
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {    
        videoRef.current?.load();
    }, [videoUrl]);

    return (
        <Container maxWidth='md'>
            <form onSubmit={formSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormikTextField
                            fullWidth
                            name='title'
                            label={t('TRAININGS_PAGE.TOPIC_TITLE')}
                            formik={formik}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <div className={styles.videoContainer}>
                            {videoUrl ? (
                                <div className={styles.videoPreviewContainer}>
                                    <Tooltip title={t('TRAININGS_PAGE.CHANGE_VIDEO')}>
                                        <IconButton 
                                            type='button'
                                            className={styles.changeVideoButton}
                                            onClick={() => inputVideoRef.current.click()}
                                        >
                                            <SwapVertOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <video controls className={styles.videoPreview} ref={videoRef}>
                                        <source src={videoUrl} type='video/mp4' />
                                    </video>
                                </div>
                            ) : (
                                <label htmlFor='video' className={styles.videoLabel}>
                                    <PlayCircleFilledWhiteOutlinedIcon sx={{fontSize: '2rem'}} /> 
                                    {t('TRAININGS_PAGE.SELECT_VIDEO')}
                                </label>
                            )}
                        </div>

                        <input
                            ref={inputVideoRef}
                            className={styles.videoInput}
                            type='file'
                            id='video'
                            name='video'
                            accept='.mp4, .mov, .mkv'
                            onChange={(event) => {
                                const file = event.currentTarget.files[0];
                                addFileInInput({file});
                            }}
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <div className={styles.captionsContainer}>
                            <label>{t('TRAININGS_PAGE.CAPTIONS')}</label>
                                
                            {formik.values.captions?.length > 0 ? 
                                <ul className={styles.captionsList}>
                                    {formik.values.captions.map((caption, index) => (
                                        <li key={`${caption.language}-${index}`}>
                                            <div className={styles.captionsItem}>
                                                <p>{t(`LANGUAGES.${convertToConstant(caption.language)}`)} - {caption.file.name}</p>
                                            
                                                <IconButton 
                                                    onClick={() => removeCaptionItem(caption.language)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </li>
                                    ))}
                                </ul> : 
                                <p className={styles.noCaptions}>{t('TRAININGS_PAGE.NO_CAPTIONS')}</p>
                            }

                            <Button 
                                variant='contained'
                                type='button'
                                size='small'
                                onClick={() => setIsCaptionsModalOpen(true)}
                            >
                                {t('TRAININGS_PAGE.ADD_NEW_CAPTION')}
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {isLoading  && (
                            <Typography variant='caption'>
                                {t('TRAININGS_PAGE.UPLOADING_VIDEO')} {uploadPercentage}%
                            </Typography>
                        )}
                        
                        <LoadingButton
                            fullWidth 
                            type='submit'
                            variant='contained'
                            loading={isLoading}
                        >
                            {t('TRAININGS_PAGE.CREATE_TOPIC')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>

            {isCaptionsModalOpen && 
                <NewCaptionModal
                    handleClose={() => setIsCaptionsModalOpen(false)}
                    captions={formik.values.captions}
                    submitFunction={addCaptionItem}
                />
            }
        </Container>
    );
}

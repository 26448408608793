import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select} from '@mui/material';
import {useGetAccountsQuery} from '@src/modules/accounts/accounts.api';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export function AccountsSelect({formik, ...props}) {
    const {data: accountsData} = useGetAccountsQuery();
    
    const {t} = useTranslation();
    
    const accountsOptions = useMemo(() => {
        return accountsData?.map((account) => {
            return {
                label: account.name,
                value: account.id,
            };
        }) || [];
    }, [accountsData]);

    function handleChange(event) {
        const {target: {value}} = event;
        formik.setFieldValue('allowedAccounts',
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    return (
        <FormControl fullWidth sx={{my: 2}}>
            <InputLabel id='allowedAccounts' disabled={props.disabled}>{t('ALL_ACCOUNTS')}</InputLabel>
            <Select
                labelId='allowedAccounts'
                id='allowedAccounts-select'
                name='allowedAccounts'
                multiple
                value={formik.values.allowedAccounts}
                onChange={handleChange}
                input={<OutlinedInput label={t('ALL_ACCOUNTS')} />}
                MenuProps={MenuProps}
                renderValue={(selected) => (
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                        {selected.map((value) => {
                            const selectedAccount = accountsOptions.find(item => item.value === value);

                            if (selectedAccount) {
                                return (
                                    <Chip key={selectedAccount.value} label={selectedAccount.label} />
                                );
                            }
                        })}
                    </Box>
                )}
                {...props}
            >
                {accountsOptions?.map((item) => (
                    <MenuItem
                        key={item.value}
                        value={item.value}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

import React from 'react';
import ContentLoader from 'react-content-loader';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
import {useGetUserQuery} from '@src/modules/suggestions/notes.api';

import styles from './employee-name.modules.scss';

const EmployeeName = ({label, employeeId, className = '', accountId}) => {
    const {t} = useTranslation();
    const {data: author, isLoading} = useGetUserQuery({accountId: accountId, userId: employeeId});

    if (isLoading || !employeeId) {
        return <Loading />;
    }

    if (!label) {
        return <span className={cx({[className]: className})}>{author?.name || t('UNPROVIDED')}</span>;
    }

    return (
        <div className={`${styles.container} ${className}`}>
            <span className={styles.title}>{label && `${t(label)}:`}<span>{author?.name || t('UNPROVIDED')}</span></span>
        </div>
    );
};

const Loading = () => {
    return (
        <ContentLoader
            speed={2}
            width={'100%'}
            height='30px'
            backgroundColor={'var(--theme-skeleton-background'}
            foregroundColor={'var(--theme-skeleton-foreground'}
        >
            <rect x='0' y='0' rx='5' ry='5' width='100%' height='50px' />,
        </ContentLoader>
    );
};

export default EmployeeName;

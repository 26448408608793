import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import TocIcon from '@mui/icons-material/Toc';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, {useCallback, useMemo, useState} from 'react';
import {
    Button,
    Container,
    IconButton,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import Table from '@src/components/Table';
import AlertDialog from '@src/components/AlertDialog';
import {routes} from '@src/utils/routes';
import {formatDate} from '@src/utils/moment-utils';
import {useGetTrainingsQuery, useToggleTrainingMutation} from '@src/modules/trainings/trainings.api';
import {useCan} from '@src/hooks/useCan';
import {permissionTypes, permissionResources} from '@src/hooks/usePrivateRoutes';

import styles from './trainings-list.module.scss';

export default function TrainingsList() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {data: trainings, isLoading: isTrainingsLoading} = useGetTrainingsQuery();
    const can = useCan();

    const columns = useMemo(() => [
        {accessor: 'thumbnail', Header: t('TRAININGS_PAGE.THUMBNAIL'), Cell: ({cell: {value}}) => <img className={styles.thumbnailCellImage} src={value} />},
        {accessor: 'title', Header: t('TRAININGS_PAGE.TITLE')},
        {accessor: 'created_at', Header: t('TRAININGS_PAGE.CREATED_AT'), Cell: ({cell: {value}}) => formatDate(value)},
        {accessor: 'updated_at', Header: t('TRAININGS_PAGE.UPDATED_AT'), Cell: ({row}) =>  row?.values.created_at !== row?.values.updated_at ? formatDate(row?.values.updated_at) : ''},
    ], []);

    return (
        <Container maxWidth='xl'>
            <header className={styles.trainingsListHeader}>
                <Typography variant='h4' component='h2'>
                    {t('TRAININGS')}
                </Typography>
                {can(permissionResources.trainings, permissionTypes.create) && <Button variant='contained' onClick={() => navigate(routes.TRAININGS_CREATE)}>
                    {t('TRAININGS_PAGE.CREATE')}
                </Button>}
            </header>
                
            <Table 
                columns={columns}
                data={trainings}
                notFoundText={t('TRAININGS_PAGE.NO_DATA_FOUND')}
                paginated={false}
                loading={isTrainingsLoading}
                Actions={Actions} 
            />       
        </Container>
    );
}

const Actions = ({data, id}) => {
    const {t} = useTranslation();
    const [toggleId, setToggleId] = useState(null);
    const navigate = useNavigate();
    const [toggle, {isLoading: isToggleLoading}] = useToggleTrainingMutation();
    const can = useCan();
    const canEditDetail = can(permissionResources.trainings, permissionTypes.edit) || can(permissionResources.trainings, permissionTypes.detail);

    const {enqueueSnackbar} = useSnackbar();

    const handleConfirm = useCallback(async () => {
        try {
            await toggle({trainingId: id, disabled: !data?.disabled}).unwrap(); 
            setToggleId(null); 
            enqueueSnackbar(
                t('TRAININGS_PAGE.TOGGLE_SUCCESS', {
                    type: t(data?.disabled ? 'TRAININGS_PAGE.ENABLED_TYPE' : 'TRAININGS_PAGE.DISABLED_TYPE'),
                }),
                {variant: 'success'},
            );
        } catch {
            enqueueSnackbar(t('TRAININGS_PAGE.TOGGLE_ERROR'), {variant: 'error'});
        }
    }, [toggleId]);

    return (
        <div className={styles.actions}>
            {can(permissionResources.trainings, permissionTypes.edit) && 
                <Tooltip title={t('TRAININGS_PAGE.TOGGLE')}>
                    <Switch 
                        color='success'
                        size='small'
                        checked={!data?.disabled}
                        onClick={() => setToggleId(id)}
                />
                </Tooltip>}
            {canEditDetail && 
                <Tooltip title={t('TRAININGS_PAGE.TOOLTIP_TOPICS')}>
                    <IconButton 
                        size='small'
                        onClick={() => navigate(routes.TRAININGS_TOPICS(id))}
                >
                        <TocIcon />
                    </IconButton>
                </Tooltip>}
            {canEditDetail &&
                <Tooltip title={t('TRAININGS_PAGE.TOOLTIP_VIEW')}>
                    <IconButton 
                        size='small'
                        onClick={() => navigate(routes.TRAININGS_EDIT(id))}
                >
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>}
                
            <AlertDialog 
                open={!!toggleId}
                title={t(
                    !data?.disabled ? 
                        'TRAININGS_PAGE.DISABLE_ALERT' :
                        'TRAININGS_PAGE.ENABLE_ALERT',
                )}
                description={t(
                    !data?.disabled ? 
                        'TRAININGS_PAGE.DISABLE_ALERT_DESCRIPTION' : 
                        'TRAININGS_PAGE.ENABLE_ALERT_DESCRIPTION',
                )}
                isLoading={isToggleLoading}
                onClose={() => setToggleId(null)}
                onConfirm={handleConfirm}
                confirmColor='success'
                className={styles.dialog}
            />
        </div>
    );
};

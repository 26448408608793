import axios from 'axios';

export async function uploadImage({url, fields, file}) {
    const formData = new FormData();
    formData.append('Content-Type', file.type);
    
    Object.entries(fields).forEach(([key, value]) => formData.append(key, value));
    formData.append('file', file);

    return axios.post(url, formData)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

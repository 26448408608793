import React from 'react';
import {useTranslation} from 'react-i18next';
import {acceptedFiles} from '@modules/importUsers/utils';
import {Box} from '@mui/material';
import Dropzone from 'react-dropzone';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import styles from './upload-button.module.scss';

export function UploadButton({importData}) {
    const {t} = useTranslation();

    return (
        <>
            <Box sx={{my: 5}}>
                <label htmlFor='file-input-fixed' className={styles.label}>
                    <FileUploadOutlinedIcon />
                    {t('IMPORT_USERS.UPLOAD_CORRECTION')}
                </label>
            </Box>
            <Dropzone onDrop={importData} accept={acceptedFiles} maxFiles={1}>
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()}>
                        <input id='file-input-fixed' {...getInputProps()} />
                    </div>
                  )}
            </Dropzone>
        </>
    );
}

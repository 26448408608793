import {Box, CircularProgress, Container, Grid, TextField} from '@mui/material';
import {useGetByIdQuery} from '@src/modules/accounts/accounts.api';
import {useGetNoteQuery, useGetUserQuery} from '@src/modules/suggestions/notes.api';
import * as moment from 'moment';
import React from 'react';
import {routes} from '@src/utils/routes';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import PageHeader from '@src/components/PageHeader';

export default function SuggestionsDetails() {
    const {noteId} = useParams();
    const {data, isLoading: noteLoading} = useGetNoteQuery({noteId});
    const {data: author, isLoading: authorLoading} = useGetUserQuery({accountId: data?.[0]?.account, userId: data?.[0]?.user});
    const {data: company, isLoading: companyLoading} = useGetByIdQuery({id: data?.[0].account}, {skip: !data});
    const {t} = useTranslation();
    const created = moment(data?.[0].created_at).format('DD/MM/YYYY');
    const navigate = useNavigate();
    const isLoading = authorLoading || companyLoading || noteLoading;
    return (
        <>
            <Container maxWidth='xl'>
                <PageHeader title={'SUGGESTIONS_PAGE.DETAILS'} textTooltip={t('SUGGESTIONS_PAGE.BACK')} toBack={() => navigate(routes.SUGGESTIONS)} />
                {isLoading &&
                    <Box sx={{height: 120, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <CircularProgress color='inherit' />
                    </Box>
                }
                {!isLoading &&

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField value={author?.name || ''} disabled={true} fullWidth label={t('SUGGESTIONS_PAGE.AUTHOR')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField value={created || ''} disabled={true} fullWidth label={t('SUGGESTIONS_PAGE.CREATED_AT')} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={company?.name || ''} disabled={true} fullWidth label={t('SUGGESTIONS_PAGE.COMPANY')} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={data?.[0].text || ''} disabled={true} fullWidth label={t('SUGGESTIONS_PAGE.CONTENT')} multiline />
                        </Grid>
                    </Grid>
                }

            </Container>
        </>
    );
}

export const acceptedFiles = {
    'application/vnd.ms-excel': [],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
};

export const translationsUserImportErros = {
    'unknown_role': 'IMPORT_USERS.UNKNOWN_ROLE',
    'unknown_rank': 'IMPORT_USERS.UNKNOWN_RANK',
    'unknown_sector': 'IMPORT_USERS.UNKNOWN_SECTOR',
    'duplicated_email': 'IMPORT_USERS.DUPLICATED_EMAIL',
    'duplicated_phone': 'IMPORT_USERS.DUPLICATED_PHONE',
    'duplicated_username': 'IMPORT_USERS.DUPLICATED_USERNAME',
    'account_max_active_users': 'IMPORT_USERS.ACCOUNT_MAX_ACTIVE_USERS',
    'unexpected_error': 'IMPORT_USERS.UNEXPECTED_ERROR',
    'invalid_username': 'IMPORT_USERS.INVALID_USERNAME',
    'invalid_name': 'IMPORT_USERS.INVALID_NAME',
    'invalid_email': 'IMPORT_USERS.INVALID_EMAIL',
    'invalid_mobile_phone': 'IMPORT_USERS.INVALID_MOBILE_PHONE',
    'invalid_password': 'IMPORT_USERS.INVALID_PASSWORD',
};

export const maxUserReachedMessage = 'Account will reach max active users limit with this import';

export const translationUsersImport = {
    type: 'IMPORT_USERS.USERS',
    total: 'IMPORT_USERS.TOTAL_USERS',
    seeList: 'IMPORT_USERS.SEE_ACCOUNT_LIST',
    importedSuccessFully: 'IMPORT_USERS.IMPORTED_SUCCESSFULLY',
};

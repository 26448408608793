import React from 'react';
import SvgIcon from '@src/components/SvgIcon';
import {Tooltip, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import styles from './info-card.module.scss';

export function InfoCard({
    iconName,
    className,
    name,
    total,
    users,
    totalWithoutLabel,
}) {
    const {t} = useTranslation();
    const cardTotal = total || totalWithoutLabel || 0;

    return (
        <div className={cx(styles.infoCard, className)}>
            <div className={styles.aside}>
                <div className={styles.description}>
                    <SvgIcon name={iconName} className={styles.icon} />
                    <Typography className={styles.cardName}>
                        {name}
                    </Typography>
                </div>
            </div>
            {users &&
                <>
                    <div className={styles.total}>
                        <Typography variant='h5'>{users}</Typography>
                        <Typography variant='caption'>
                            {t('ACCOUNT_DASHBOARD_PAGE.ACTIVE')}
                        </Typography>
                    </div>
                    <div className={styles.total}>
                        <Typography variant='h5'>{total - users}</Typography>
                        <Typography variant='caption'>
                            {t('ACCOUNT_DASHBOARD_PAGE.INACTIVE')}
                        </Typography>
                    </div>
                </>
            }
            <div className={styles.total}>
                <Typography variant='h5'>{cardTotal}</Typography>
                <Typography variant='caption'>
                    {totalWithoutLabel ? '' : t('ACCOUNT_DASHBOARD_PAGE.TOTAL')}
                </Typography>
            </div>
            {cardTotal < 0 && (
                <Tooltip placement='top' title={t('ACCOUNT_DASHBOARD_PAGE.NEGATIVE_TOTAL_WARNING')}>
                    <div className={styles.warningButton}>
                        <WarningAmberIcon />
                    </div>
                </Tooltip>
            )}
        </div>
    );
}


import React, {useMemo, useRef, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {
    Button,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
} from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {FormikTextField} from '@src/components/FormikFields';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import {tutorialSchema} from '@src/modules/tutorials/schema';
import {useAddCaptionMutation,
    useAddTutorialMutation,  useGetTutorialsQuery,  useLazyGetUploadDataQuery} from '@src/modules/tutorials/tutorials.api';
import PageHeader from '@src/components/PageHeader';
import {convertToConstant, generateCaptionFormData, generateFileUrl, rolesOptions} from '@src/modules/tutorials/utils';
import {uploadVideo} from '@src/modules/uploads/uploadVideo';
import {uploadImage} from '@src/modules/uploads/uploadImage';
import {NewCaptionModal} from '@src/modules/tutorials/components/NewCaptionModal';
import {VideoUploadProgress} from '@src/modules/tutorials/components/VideoUploadProgress';
import LoadingBackdrop from '@src/components/LoadingBackdrop';
import {CategoryTagsField} from '@src/components/CategoryTagsField';

import styles from './tutorials-create.module.scss';

export default function TutorialsCreate(){
    const [tags, setTags] = useState([]);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isCaptionsModalOpen, setIsCaptionsModalOpen] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);

    const inputVideoRef = useRef(null);
    const inputThumbnailRef = useRef(null);

    const {t} = useTranslation();
    const [getUploadData] = useLazyGetUploadDataQuery();
    const [addCaption] = useAddCaptionMutation();
    const [createTutorial] = useAddTutorialMutation();
    const {data: tutorialsData} = useGetTutorialsQuery();

    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const listCategories = tutorialsData?.map(value => value.tags);
    const tagsArrayMerge = useMemo(() => {
        if (listCategories){
            return listCategories.flat();
        }
    }, [listCategories]);

    const tagsNames = {};

    tagsArrayMerge?.forEach(item => {
        const lowerItem = item?.toLowerCase();
        const FormattedName = lowerItem?.charAt(0)?.toUpperCase() + lowerItem?.slice(1);
        tagsNames[FormattedName] = true;
    });

    const tagsOptions = Object.keys(tagsNames);
    
    const formik = useFormik({
        validationSchema: tutorialSchema(t),
        initialValues: {
            thumbnail: '',
            video: '',
            title: '',
            captions: [],
            tags: [],
            roles: [],
            disabled: false,
        },
        onSubmit: async (form) => {
            setIsLoading(true);
            try {
                let uploadDataResponse = await uploadThumbnail(form.thumbnail, getUploadData);
                const formattedTags = form.tags.map(tag => tag?.toUpperCase());

                const payload = {
                    title: form.title,
                    thumbnail: uploadDataResponse.get,
                    tags: formattedTags,
                    disabled: form.disabled,
                    roles: form.roles,
                };
                
                const response = await createTutorial({body: payload}).unwrap();
                
                await handleUploadVideo(form.video, response, setUploadPercentage);
                
                await uploadCaptions(form.captions, response.id, addCaption);
                
                enqueueSnackbar(t('TUTORIALS_PAGE.CREATE_SUCCESS'), {variant: 'success'});
                
                navigate(routes.TUTORIALS);
            } catch (error) {
                enqueueSnackbar(t(error.message || 'TUTORIALS_PAGE.EDIT_ERROR'), {variant: 'error'});
            } finally {
                setIsLoading(false);
            }
        },
    });

    function handleChangeVideo() {
        setVideoUrl(null);
        inputVideoRef.current.click();
    }

    function handleChangeThumbnail() {
        setThumbnailUrl(null);
        inputThumbnailRef.current.click();
    }
    
    function addCaptionItem(caption) {
        const currentCaptions = formik.values.captions;
        const newCaptions = [
            ...currentCaptions,
            {
                language: caption.language,
                file: caption.file,
            },
        ];

        formik.setFieldValue('captions', newCaptions);
    }

    function removeCaptionItem(languageToRemove) {
        const currentCaptions = formik.values.captions;
        const captionsUpdated = currentCaptions.filter(caption => caption.language !== languageToRemove);
    
        formik.setFieldValue('captions', captionsUpdated);
    }

    function addFileInInput({file, input, setFileUrl}) {
        if (file) {
            formik.setFieldValue(input, file);
            setFileUrl(generateFileUrl(file));
        } else {
            formik.setFieldValue(input, '');
            setFileUrl('');
        }
    }

    const formSubmit = (e) => {
        e.preventDefault();
        formik.handleSubmit();
    };

    return (
        <>
            <Container maxWidth='xl'>
                <PageHeader title={'TUTORIALS_PAGE.CREATED'} textTooltip={t('TUTORIALS_PAGE.BACK')} toBack={() => navigate(routes.TUTORIALS)} />
                <form onSubmit={formSubmit} autoComplete='off'>
                    <Grid container spacing={2}>
                    
                        <div className={styles.mediasContainer}>
                            <div className={styles.mediaContainer}>
                                <div className={`${styles.mediaInputContainer} ${formik.touched?.thumbnail && formik.errors?.thumbnail && styles.mediaInputError}`}>
                                    <label htmlFor='thumbnail'>
                                        {thumbnailUrl ? 
                                            <img src={thumbnailUrl} /> : 
                                            <>
                                                <CameraAltOutlinedIcon sx={{fontSize: '2rem'}} />
                                                {t('TUTORIALS_PAGE.SELECT_THUMBNAIL')}
                                            </>
                                        }
                                    </label>
                                    <input
                                        ref={inputThumbnailRef}
                                        type='file'
                                        id='thumbnail'
                                        name='thumbnail'
                                        accept='.png, .jpeg, .jpg'
                                        onChange={(event) => {
                                            const file = event.currentTarget.files[0];
                                            addFileInInput({
                                                file,
                                                input: 'thumbnail',
                                                setFileUrl: setThumbnailUrl,
                                            });
                                        }}
                                    />
                                </div>

                                <Button
                                    endIcon={<EditIcon />}
                                    size='small'
                                    color='warning'
                                    onClick={handleChangeThumbnail}
                                >
                                    {t('TUTORIALS_PAGE.CHANGE_SELECTED_THUMBNAIL')}
                
                                </Button>
                            </div>

                            <div className={styles.mediaContainer}>
                                <div className={`${styles.mediaInputContainer} ${formik.touched?.video && formik.errors?.video && styles.mediaInputError}`}>
                                    <label htmlFor='video'>
                                        {videoUrl ? 
                                            <video controls>
                                                <source src={videoUrl} type='video/mp4' />
                                            </video> : 
                                            <>
                                                <PlayCircleFilledWhiteOutlinedIcon sx={{fontSize: '2rem'}} /> 
                                                {t('TUTORIALS_PAGE.SELECT_VIDEO')}
                                            </> 
                                    }
                                    </label>
                                    <input
                                        ref={inputVideoRef}
                                        type='file'
                                        id='video'
                                        name='video'
                                        accept='.mp4, .mov, .mkv'
                                        onChange={(event) => {
                                            const file = event.currentTarget.files[0];
                                            addFileInInput({
                                                file,
                                                input: 'video',
                                                setFileUrl: setVideoUrl,
                                            });
                                        }}
                                    />
                                </div>
                                <Button
                                    endIcon={<EditIcon />}
                                    size='small'
                                    color='warning'
                                    onClick={handleChangeVideo}
                                >
                                    {t('TUTORIALS_PAGE.CHANGE_SELECTED_VIDEO')}
                                </Button>
                            </div>
                        </div>

                        <Grid item xs={12}>
                            <FormikTextField
                                fullWidth
                                name='title'
                                label={t('TUTORIALS_PAGE.TITLE')}
                                formik={formik}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <CategoryTagsField
                                name='tags'
                                label={t('TUTORIALS_PAGE.CATEGORY_TAGS')}
                                formik={formik}
                                tags={tags}
                                setTags={setTags}
                                tagsOptions={tagsOptions}
                            />
                        </Grid>
                    
                        <div className={styles.captionsContainer}>
                            <label>{t('TUTORIALS_PAGE.CAPTIONS')}</label>
                            {formik.values.captions?.length > 0 ? 
                                <ul className={styles.captionsList}>
                                    {formik.values.captions.map((caption, index) => (
                                        <li key={`${caption.language}-${index}`}>
                                            <div className={styles.captionsItem}>
                                                <p>{t(`LANGUAGES.${convertToConstant(caption.language)}`)} - {caption.file.name}</p>
                                            
                                                <IconButton 
                                                    onClick={() => removeCaptionItem(caption.language)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </li>
                                    ))}
                                </ul> : 
                                <p className={styles.noCaptions}>{t('TUTORIALS_PAGE.NO_CAPTIONS')}</p>
                            }
                            <Button 
                                variant='contained'
                                type='button'
                                size='small'
                                onClick={() => setIsCaptionsModalOpen(true)}
                            >
                                {t('TUTORIALS_PAGE.ADD_NEW_CAPTION')}
                            </Button>
                        </div>
                    
                        <Grid item xs={12}>
                            <FormControl fullWidth error={Boolean(formik.touched.roles && formik.errors.roles)}>
                                <InputLabel id='roles'>{t('TUTORIALS_PAGE.ROLES')}</InputLabel>
                                <Select
                                    labelId='roles'
                                    multiple
                                    value={formik.values.roles}
                                    onChange={(e) => formik.setFieldValue('roles', e.target.value)}
                                    input={<OutlinedInput label={t('TUTORIALS_PAGE.ROLES')} />}
                                    fullWidth
                                >
                                    {rolesOptions.map((item) => (
                                        <MenuItem
                                            key={item.value}
                                            value={item.value}
                                        >
                                            {t(item.label)}
                                        </MenuItem>
                                ))}
                                </Select>
                                {formik.errors.roles && formik.touched.roles && <FormHelperText>{formik.errors.roles}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        <div className={styles.switchContainer}>
                            <label>{t('TUTORIALS_PAGE.STARTS_ENABLED')}</label>
                            <Switch 
                                color='success'
                                name='disabled'
                                checked={!formik?.values?.disabled} 
                                onClick={() => formik.setFieldValue('disabled', !formik?.values?.disabled)} 
                            />
                        </div>
                        
                        <Grid item xs={12} mt={2}>
                            <LoadingButton
                                type='submit'
                                loading={isLoading}
                                variant='contained'
                                fullWidth
                                >
                                {t('TUTORIALS_PAGE.SAVE')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Container>
         
            <LoadingBackdrop isLoading={isLoading} className={styles.backdrop}>
                <div className={styles.loadingContent}>
                    <VideoUploadProgress value={uploadPercentage}  />
                    <span>{t('TUTORIALS_PAGE.VIDEO_PROGRESS')}</span>
                </div>
            </LoadingBackdrop>
        
            {isCaptionsModalOpen && 
                <NewCaptionModal
                    handleClose={() => setIsCaptionsModalOpen(false)}
                    captions={formik.values.captions}
                    submitFunction={addCaptionItem}
                />
            }
        </>
    );
}

const handleUploadVideo = async (video, uploadData, setUploadPercentage) => {
    try {
        await uploadVideo({
            video: video, 
            uploadData,
            setUploadPercentage,
        });
    } catch {
        throw new Error('TUTORIALS_PAGE.UPLOAD_ERROR');
    }
};

const uploadThumbnail = async (thumbnail, getUploadData) => {
    try {
        const data = await getUploadData({
            ContentType: thumbnail.type,
            ContentLength: thumbnail.size,                
        }).unwrap();

        await uploadImage({
            url: data.post.url,
            fields: data.post.fields,
            file: thumbnail,
        });

        return data;
    } catch (error) {
        throw new Error('TUTORIALS_PAGE.THUMBNAIL_ERROR');
    }
};

const uploadCaptions = async (captions, tutorialId, addCaption) => {
    try {
        const captionsPromises = captions.map(async (caption) => {
            const captionsFormData = generateCaptionFormData({file: caption.file, language: caption.language});

            return addCaption({
                videoId: tutorialId, 
                body: captionsFormData,
            }).unwrap();
        });

        await Promise.all(captionsPromises);
    } catch {
        throw new Error('TUTORIALS_PAGE.CAPTION_ERROR');
    }
};

import {Button, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {CopyAllOutlined} from '@mui/icons-material';
import {useSnackbar} from 'notistack';

import styles from './accountCopyLink.module.scss';

export default function AccountCopyLink({link}) {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const handleCopy = () => {
        navigator.clipboard.writeText(link.url);
        enqueueSnackbar(t('COPIED'), {variant: 'success'});
    };
    return (
        <>
            <Typography variant='h6' component='h6' mt={1} mb={2}>
                {t('ACCOUNTS_PAGE.LINK')}
            </Typography>
            <div className={styles.item}>
                <Typography
                    className={styles.linkText}
                    variant='a'
                    component='a'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={link.url}
            >
                    {link.url}
                </Typography>
                <Button
                    type='button'
                    variant='outlined'
                    color='secondary'
                    startIcon={<CopyAllOutlined size={32} />}
                    onClick={handleCopy}
                >
                    {t('COPY')}
                </Button>
            </div>
        </>
    );
}

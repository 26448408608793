import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Container} from '@mui/material';
import NotesTable from '@src/modules/suggestions/components/NotesTable';
import {useListAllNotesQuery} from '@src/modules/suggestions/notes.api';

import styles from './suggestions.module.scss';

export default function Suggestions(){
    const [page, setPage] = useState(0);
    const {t} = useTranslation();
    const {data: allNotes, isLoading} = useListAllNotesQuery({page: page});
    const tempNotes = allNotes?.items;

    return (
        <Container maxWidth='xl'>
            <div className={styles.header}>
                <h2>{t('SUGGESTIONS_PAGE.SUGGESTIONS_AND_COMPLIMENTS')}</h2>
            </div>
            <div className={styles.content}>
                <NotesTable data={tempNotes} isLoading={isLoading} setPage={setPage} page={page} pageSize={allNotes?.pageSize} total={allNotes?.total} />
            </div>
        </Container>

    );
}

import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, CircularProgress} from '@mui/material';

import {Tips} from '../../Tips';

import styles from './analyze-import.module.scss';

export function AnalyzeImport() {
    const {t} = useTranslation();

    return (
        <Box className={styles.main}>
            <h1 className={styles.title}>{t('IMPORT_USERS.ANALYZING')}</h1>
            <div className={styles.content}>
                <div className={styles.contentProgress}>
                    <CircularProgress />
                </div>
                
                <Tips />
            </div>
        </Box>
    );
}

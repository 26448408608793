import {createSlice} from '@reduxjs/toolkit';
import CredentialsHelper from '@src/utils/credentialsHelper';

const initialState = {
    token: CredentialsHelper.accessToken(),
    loggedIn: false,
    session: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            state.token = action.payload;
            state.loggedIn = true;
        },
        logout: (state) => {
            state.token = null;
            state.loggedIn = false;
        },
        setSession: (state, {payload}) => {
            state.session = payload;
        },
    },
});

export class AuthSelectors {
    static isLoggedIn({auth}) {
        return auth?.token || auth?.loggedIn;
    }
}

export const {setCredentials, logout, setSession} = authSlice.actions;

export default authSlice.reducer;

import {AccountCircle} from '@mui/icons-material';
import React, {useCallback, useEffect} from 'react';
import {Grid, Paper, Container, Typography, Link} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useDispatch} from 'react-redux';
import {useLoginMutation} from '@src/modules/auth/auth.api';
import {setCredentials} from '@src/modules/auth/auth.reducer';
import {useTranslation} from 'react-i18next';
import logo from '@assets/imgs/logo.svg';
import {loginSchema} from '@modules/auth/schema';
import {useFormik} from 'formik';
import CredentialsHelper from '@src/utils/credentialsHelper';
import {FormikIconTextField, FormikPasswordTextField} from '@src/components/FormikFields';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import {useSnackbar} from 'notistack';

import styles from './login.module.scss';

export default function Login() {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const [login, {isLoading, isSuccess, error, data}] = useLoginMutation();

    const {from} = location.state || {from: {pathname: routes.HOME}};

    const handleLogin = useCallback((data) => {
        login(data);
    }, []);

    const formik = useFormik({
        validationSchema: loginSchema(t),
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: handleLogin,
    });

    useEffect(() => {
        if (isSuccess) {
            dispatch(setCredentials(data.access_token));
            CredentialsHelper.update(data.access_token);
            navigate(from);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            if (error.status === 401) {
                enqueueSnackbar(t('INVALID_USERNAME_PASSWORD'), {variant: 'error'});
            } else {
                enqueueSnackbar(t('LOGIN_ERROR'), {variant: 'error'});
            }
        }
    }, [error]);

    const formSubmit = e => {
        e.preventDefault();
        formik.handleSubmit();
    };

    return (
        <Container maxWidth='xs' className={styles.container}>
            <img src={logo} />
            <Paper className={styles.paper}>
                <Typography textAlign='center' className={styles.title}>
                    {t('WELCOME')}
                </Typography>
                <Typography textAlign='center' color='textSecondary' className={styles.subtitle}>
                    {t('INSERT_USER_AND_PASSWORD')}
                </Typography>
                <form onSubmit={formSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormikIconTextField
                                fullWidth
                                name='username'
                                startIcon={<AccountCircle />}
                                label={t('USERNAME')}
                                formik={formik}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikPasswordTextField
                                fullWidth
                                name='password'
                                label={t('PASSWORD')}
                                formik={formik}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                type='submit'
                                loading={isLoading}
                                variant='contained'
                            >
                                {t('LOGIN')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            <Link className={styles.forgetPassword} href={routes.FORGET_PASSWORD}> {t('FORGET_PASSWORD')}</Link>
        </Container>        
    );
}

import React, {useEffect, useState} from 'react';
import {EditorContent, useEditor} from '@tiptap/react';
import {Color} from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import TextAlign from '@tiptap/extension-text-align';
import TextUnderline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import CharacterCount from '@tiptap/extension-character-count';
import cx from 'classnames';

import {MenuBar} from './MenuBar';
import LinkPopover from './LinkPopover';
import './tip-tap.scss';
import styles from './text-editor.module.scss';
import EditorFooter from './EditorFooter';

export default function TextEditor({initialValue, onChange, onBlur = () => {}, error, helperText, maxLength, disabled}) {
    const [url, setUrl] = useState('');
    const [showMenuAdd, setShowMenuAdd] = useState(false);

    const editor = useEditor({
        extensions: [
            Color,
            TextStyle,
            TextAlign,
            TextUnderline,
            StarterKit,
            Link.configure({
                openOnClick: false,
                autolink: false,
            }),
            CharacterCount.configure({
                limit: maxLength,
            }),
        ],
        editable: !disabled,
        content: initialValue,
        onUpdate: ({editor}) => {
            if (typeof onChange === 'function') {
                onChange(editor.getHTML());
            }
        },

        onBlur: onBlur,
        editorProps: {
            transformPastedText(text) {
                return text.replace(/\xA0/g, ' ');
            },
            transformPastedHTML(html) {
                const formattedText = html.replace(/<[^>]*>/g, '');

                return formattedText;
            },
        },
    }, [initialValue, disabled]);

    useEffect(() => {
        setUrl(editor?.getAttributes('link').href);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor?.getAttributes('link').href]);

    return (
        <div className={styles.container}>
            <div className={styles.menuBarContainer}>
                <MenuBar editor={editor} setShowMenuAdd={setShowMenuAdd} url={url} />
            </div>

            <LinkPopover
                setUrl={setUrl}
                url={url}
                editor={editor}
                showMenuAdd={showMenuAdd}
                setShowMenuAdd={setShowMenuAdd}
            />
            <div className={cx(styles.editorContainer,  {[styles.error]: error}, {[styles.disabled]: disabled})}>
                <EditorContent editor={editor} />
                <EditorFooter
                    editor={editor}
                    error={error}
                    helperText={helperText}
                />

            </div>
        </div>
    );
}

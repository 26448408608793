import React from 'react';
import {CssBaseline, StyledEngineProvider, ThemeProvider} from '@mui/material';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Login from '@screens/Authentication/Login';
import {routes} from '@utils/routes';
import {theme} from '@utils/theme';
import PrivateRoute from '@src/components/PrivateRoute';
import Scaffolding from '@components/Scaffolding';
import {SnackbarProvider} from 'notistack';
import ForgetPassword from '@src/screens/Authentication/ForgetPassword';
import Code from '@src/screens/Authentication/Code';
import InsertPassword from '@src/screens/Authentication/InsertPassword';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

import {usePrivateRoutes} from './hooks/usePrivateRoutes';

function App() {

    const privateRoutes = usePrivateRoutes();

    return (
        <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='pt-br'>
                        <SnackbarProvider
                            anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                    >
                            <CssBaseline />
                            <Routes>
                                <Route path={routes.LOGIN} element={<Login />} />
                                <Route path={routes.FORGET_PASSWORD} element={<ForgetPassword />} />
                                <Route path={routes.INSERT_CODE} element={<Code />} />
                                <Route path={routes.INSERT_PASSWORD} element={<InsertPassword />} />
                                <Route element={<PrivateRoute />}>
                                    <Route path={routes.ROOT} element={<Scaffolding />}>
                                        {privateRoutes.map(({path, element}) =>
                                            <Route key={path} path={path} element={element} />,
                                     )}
                                    </Route>
                                </Route>
                                <Route path='*' element={<Navigate to={routes.ROOT} replace />} />
                            </Routes>
                        </SnackbarProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </BrowserRouter>

    );
}

export default App;

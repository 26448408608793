import cx from 'classnames';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Container, Grid} from '@mui/material';
import React, {useMemo, useRef, useState} from 'react';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import {routes} from '@src/utils/routes';
import PageHeader from '@src/components/PageHeader';
import {FormikTextField} from '@src/components/FormikFields';
import {MAX_DESCRIPTION_LENGTH} from '@src/modules/trainings/utils';
import {uploadImage} from '@src/modules/uploads/uploadImage';
import {trainingsSchema} from '@src/modules/trainings/schema';
import {useCreateTrainingMutation, useGetTrainingsQuery, useLazyGetTrainingThumbnailUrlQuery} from '@src/modules/trainings/trainings.api';
import Modal from '@src/components/Modal';
import {blobToBase64, readFile} from '@src/utils/image-utils';
import {AccountsSelect} from '@src/modules/trainings/components/AccountsSelect';

import {CategoryTagsField} from '../../../components/CategoryTagsField';
import {ImageCropper} from '../../../modules/trainings/components/ImageCropper';

import styles from './trainings-create.module.scss';

export default function TrainingsCreate() {
    const [tags, setTags] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fileToCrop, setFileToCrop] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);

    const [createTraining] = useCreateTrainingMutation();
    const [getUploadThumbnailUrl] = useLazyGetTrainingThumbnailUrlQuery();
    const {data: trainingsData} = useGetTrainingsQuery();
    
    const listCategories = trainingsData?.map(value => value.categories);

    const tagsArrayMerge = useMemo(() => {
        if (listCategories){
            return listCategories.flat();
        }
    }, [listCategories]);

    const tagsNames = {};

    tagsArrayMerge?.forEach(item => {
        const lowerItem = item?.toLowerCase();
        const FormattedName = lowerItem?.charAt(0)?.toUpperCase() + lowerItem?.slice(1);
        tagsNames[FormattedName] = true;
    });

    const tagsOptions = Object.keys(tagsNames);

    const {t} = useTranslation();
    const navigate = useNavigate();
    const inputThumbnailRef = useRef(null);
    const {enqueueSnackbar} = useSnackbar();

    const initialValues = {
        title: '',
        description: '',
        thumbnail: '',
        categories: [],
        allowedAccounts: [],
    };

    const formik = useFormik({
        validationSchema: trainingsSchema(t),
        initialValues,
        onSubmit: handleSubmit,
    });

    async function addFileToCrop(file) {
        if (file) {
            const fileUrl = await readFile(file);
            setFileToCrop(fileUrl);
        } else {
            setFileToCrop(null);
        }
    }

    async function uploadThumbnail({trainingId, thumbnail, getUploadData}) {
        try {
            const data = await getUploadData({
                trainingId,
                params: {
                    ContentType: thumbnail.type,
                    ContentLength: thumbnail.size,                
                },
            }).unwrap();
    
            await uploadImage({
                url: data.url,
                fields: data.fields,
                file: thumbnail,
            });
    
            return data;
        } catch (error) {
            throw new Error('TRAININGS_PAGE.THUMBNAIL_ERROR');
        }
    }

    function formSubmit(e)  {
        e.preventDefault();
        formik.handleSubmit();
    }

    async function handleSubmit(form) {
        setIsLoading(true);
        try {
            const formattedTags = form.categories.map(category => category?.toUpperCase());
            const base64Thumb = form.thumbnail ? await blobToBase64(form.thumbnail) : undefined;

            const body = {
                ...form,
                categories: formattedTags,
            };

            const response = await createTraining({body, image: base64Thumb}).unwrap();

            await uploadThumbnail({
                trainingId: response.id,
                thumbnail: form.thumbnail,
                getUploadData: getUploadThumbnailUrl,
            });
            
            enqueueSnackbar(t('TRAININGS_PAGE.CREATE_SUCCESS'), {variant: 'success'});
            navigate(routes.TRAININGS);
        } catch (error) {
            enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.CREATE_ERROR'), {variant: 'error'});
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Container maxWidth='xl'>
            <PageHeader
                title='TRAININGS_PAGE.CREATE'
                textTooltip={t('TRAININGS_PAGE.BACK')}
                toBack={() => navigate(routes.TRAININGS)} 
            />

            <main>
                <Container maxWidth='sm'>
                    <form onSubmit={formSubmit} className={styles.form}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormikTextField
                                    fullWidth
                                    name='title'
                                    label={t('TRAININGS_PAGE.TRAINING_TITLE')}
                                    formik={formik}
                                />
                            </Grid>
                            
                            <Grid item xs={12}>
                                <FormikTextField
                                    name='description'
                                    label={t('TRAININGS_PAGE.DESCRIPTION')}
                                    formik={formik}
                                    fullWidth
                                    multiline
                                    rows={6}
                                    maxLength={MAX_DESCRIPTION_LENGTH}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CategoryTagsField 
                                    name='categories'
                                    label={t('TRAININGS_PAGE.CATEGORIES')}
                                    formik={formik}
                                    tags={tags}
                                    setTags={setTags}
                                    tagsOptions={tagsOptions}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <AccountsSelect 
                                    formik={formik}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <div 
                                    className={cx(
                                        styles.mediaInputContainer, {
                                            [styles.thumbnailError]: formik.touched?.thumbnail && formik.errors?.thumbnail,
                                        },
                                    )}
                                >
                                    <label htmlFor='thumbnail' className={styles.thumbnailLabel}>
                                        {thumbnailUrl ? 
                                            <img src={thumbnailUrl} className={styles.thumbnailImage} /> : 
                                            <>
                                                <CameraAltOutlinedIcon sx={{fontSize: '2rem'}} />
                                                {t('TRAININGS_PAGE.SELECT_THUMBNAIL')}
                                            </>
                                        }
                                    </label>
                                    <input
                                        ref={inputThumbnailRef}
                                        className={styles.thumbnailInput}
                                        type='file'
                                        id='thumbnail'
                                        name='thumbnail'
                                        accept='.png, .jpeg, .jpg'
                                        onClick={(event) => {
                                            event.target.value = null;
                                            setThumbnailUrl(null);
                                            setFileToCrop(null);
                                            formik.setFieldValue('thumbnail', null);
                                        }}
                                        onChange={(event) => {
                                            const file = event.currentTarget.files[0];
                                            addFileToCrop(file);
                                        }}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <LoadingButton
                                    type='submit'
                                    loading={isLoading}
                                    variant='contained'
                                    fullWidth
                                >
                                    {t('TRAININGS_PAGE.SAVE')}
                                </LoadingButton>                            
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            </main>

            {!!fileToCrop && (
                <Modal
                    title={t('TRAININGS_PAGE.CROP_THUMBNAIL')}
                    modalWidth={500}
                    onClose={() => {
                        setFileToCrop(null);
                        setThumbnailUrl(null);
                    }}
                >
                    <ImageCropper
                        onClose={() => {
                            setFileToCrop(null);
                            setThumbnailUrl(null);
                            formik.setFieldValue('thumbnail', null);
                        }}
                        setNewImageUrl={(value) => {
                            setFileToCrop(null);
                            setThumbnailUrl(value);
                        }}
                        setNewImageFile={(value) => {
                            setFileToCrop(null);
                            formik.setFieldValue('thumbnail', value);
                        }}
                        file={fileToCrop}
                    />
                </Modal>
            )}
        </Container>        
    );
}

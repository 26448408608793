import {configureStore} from '@reduxjs/toolkit';
import {expiredTokenErrorHandler} from '@src/middlewares/expiredTokenErrorHandler';
import {authApi} from '@src/modules/auth/auth.api';

import {apiSlice} from './apiSlice';
import reducers from './reducers';

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(expiredTokenErrorHandler)
        .concat(authApi.middleware)
        .concat(apiSlice.middleware),
});

import axios from 'axios';

export async function uploadVideo({video, uploadData, setUploadPercentage}) {
    let chunkPosition = 1;
    const blocksize = 10240000;
    const chunks = Math.ceil(video.size / blocksize, blocksize);
    let formData = new FormData();
    let slice;
    const param = {'Content-Type': 'multipart/form-data', params: {format: 1}};

    const uploadUrl = uploadData.url;
    if (uploadUrl.includes('format=')) {
        delete param.params.format;
    }
            
    while (chunkPosition <= chunks) {
        const blockStart = (chunkPosition - 1) * blocksize;
        slice = video.slice(blockStart, chunkPosition * blocksize, video.type);
        formData = new FormData();
        formData.append('fileData', slice,  video.name);
        formData.append('ks', uploadData.ks);
        formData.append('uploadTokenId', uploadData.upload_token_id);
        formData.append('resume', chunkPosition !== 1);
        formData.append('resumeAt', chunkPosition === 1 ? 0 : -1);
        formData.append('finalChunk', chunkPosition === chunks);

        const uploadResponse = await axios.post(uploadUrl, formData, param);

        if (!uploadResponse.data || uploadResponse.data.objectType === 'KalturaAPIException' || uploadResponse.data.objectType !== 'KalturaUploadToken') {
            console.error('Chunk upload error', uploadResponse.data);
            return;
        }

        const currentPercentage = ((chunkPosition / chunks) * 100).toFixed(2);
        setUploadPercentage(currentPercentage);
        chunkPosition += 1;
    }
}

import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import React, {useEffect, useRef, useState} from 'react';
import {Button, Container, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import SvgIcon from '@src/components/SvgIcon';
import {FormikTextField} from '@src/components/FormikFields';
import {ATTACHMENT_MAX_SIZE_ALLOWED, attachmentsAcceptedFiles, generateUniqueId} from '@src/modules/trainings/utils';
import {topicAttachmentsSchema} from '@src/modules/trainings/schema';
import {ConfirmationModal} from '@src/components/ConfirmationModal';
import {
    invalidateContent,
    invalidateTopics,
    useDeleteAttachmentContentMutation,
    useDeleteTopicMutation,
    useEditTrainingTopicMutation,
    useLazyGetAttachmentContentUrlQuery,
}    from '@src/modules/trainings/trainings.api';
import {LoadingButton} from '@mui/lab';
import {uploadAttachmentFile} from '@src/modules/uploads/uploadAttachment';
import {useDispatch} from 'react-redux';

import styles from './attachments-edit-form.module.scss';

export function AttachmentsEditForm({
    trainingId,
    content,
    topic,
    setActiveTopic,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [attachmentToDelete, setAttachmentToDelete] = useState(null);
    const [isDeleteTopicModalOpen, setIsDeleteModalOpen] = useState(false);
    
    const [editTopic] = useEditTrainingTopicMutation();
    const [getAttachmentUploadUrl] = useLazyGetAttachmentContentUrlQuery(); 

    const [deleteTopic, {isLoading: deleteTopicLoading}] = useDeleteTopicMutation();
    const [deleteAttachment, {isLoading: deleteAttachmentLoading}] = useDeleteAttachmentContentMutation();
    
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const attachmentInputRef = useRef();
    const {enqueueSnackbar} = useSnackbar();

    const initialValues = { 
        title: '',
        attachments: [],
    };

    const formik = useFormik({
        validationSchema: topicAttachmentsSchema(t),
        initialValues,
        onSubmit: handleSubmit,
    });

    async function handleDeleteAttachment({attachmentId, isAlreadyExistent, index}) {
        try {
            if (isAlreadyExistent) {
                await deleteAttachment({
                    trainingId,
                    topicId: topic.id,
                    contentId: content.id,
                    attachmentId,
                }).unwrap();
            } else {
                const attachments = formik.values.attachments;
                const updatedAttachments = attachments.filter(item => item !== attachments[index]);
                formik.setFieldValue('attachments', updatedAttachments);
            }

            setAttachmentToDelete(null);

            enqueueSnackbar(t('TRAININGS_PAGE.DELETE_ATTACHMENT_SUCCESS'), {variant: 'success'});
        } catch (error) {
            enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.DELETE_ATTACHMENT_ERROR'), {variant: 'error'});
        }
    }

    async function uploadAttachment({
        data,
        topicId,
        contentId,
    }) {
        if (!data) {
            return;
        }
    
        const filteredData = data.filter(item => !item.id);
    
        const promises = filteredData.map(async (file) => {
            try {
                const params = {
                    ContentType: file?.type,
                    ContentLength: file?.size,
                    FileName: file?.name,
                    ContentDisposition: `inline; filename="${file?.name}"`,
                };
    
                if (file?.size > ATTACHMENT_MAX_SIZE_ALLOWED) {
                    enqueueSnackbar(t('TRAININGS_PAGE.ATTACHMENTS_ERROR', {fileName: file?.name}), {variant: 'error'});
                } else {
                    const attachments = await getAttachmentUploadUrl({trainingId, topicId, contentId, params}).unwrap();
                    await uploadAttachmentFile(attachments.url, attachments.fields, file, 'inline');
                }
    
            } catch (error) {
                enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.CREATE_ATTACHMENTS_ERROR'), {variant: 'error'});
            }
        });
    
        await Promise.all(promises);
    }

    function addAttachment(file) {
        if (file) {
            const updatedAttachments = [...formik.values.attachments, file];
            formik.setFieldValue('attachments', updatedAttachments);
        }
    }

    async function handleDeleteTopic() {
        try {
            await deleteTopic({
                trainingId,
                topicId: topic.id,
            }).unwrap();

            enqueueSnackbar(t('TRAININGS_PAGE.DELETE_TOPIC_SUCCESS'), {variant: 'success'});

            invalidateTopics();

            setIsDeleteModalOpen(false);
            setActiveTopic(null);
        } catch (error) {
            enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.DELETE_TOPIC_ERROR'), {variant: 'error'});
        } 
    } 

    function formSubmit(e)  {
        e.preventDefault();
        formik.handleSubmit();
    }

    async function handleSubmit(form) {
        setIsLoading(true);
        setIsEditing(false);
        try {            
            const {title, attachments} = form;

            const topicResponse = await editTopic({
                trainingId,
                topicId: topic.id,
                title,
            }).unwrap();
                
            const newAttachments = attachments.filter(attachment => !attachment.id);

            await uploadAttachment({
                topicId: topicResponse.id,
                contentId: content.id,
                data: newAttachments,
            });

            setActiveTopic(topicResponse);
            
            dispatch(invalidateContent(topicResponse.content));

            enqueueSnackbar(t('TRAININGS_PAGE.EDIT_TOPIC_SUCCESS'), {variant: 'success'});
        } catch (error) {
            enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.EDIT_TOPIC_ERROR'), {variant: 'error'});
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (content) {
            formik.setValues({
                title: topic.title,
                attachments: content.value,
            });
        }
    }, [content]);

    return (
        <Container maxWidth='md'>
            <form onSubmit={formSubmit} className={styles.form}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={styles.titleContainer}>
                            <FormikTextField
                                fullWidth
                                name='title'
                                label={t('TRAININGS_PAGE.TOPIC_TITLE')}
                                formik={formik}
                                disabled={!isEditing}
                            />

                            <Tooltip title={t(isEditing ? 'TRAININGS_PAGE.CANCEL' : 'TRAININGS_PAGE.EDIT_TOPIC')}>
                                <IconButton onClick={() => setIsEditing(prev => !prev)}>
                                    {isEditing ? <CloseIcon /> : <ModeEditIcon />}   
                                </IconButton>
                            </Tooltip>

                            {!isEditing && (
                                <Tooltip title={t('TRAININGS_PAGE.DELETE_TOPIC')}>
                                    <IconButton  onClick={() => setIsDeleteModalOpen(true)}>
                                        <DeleteIcon /> 
                                    </IconButton>
                                </Tooltip>
                            )}

                            {isDeleteTopicModalOpen && (
                                <ConfirmationModal
                                    title={t('TRAININGS_PAGE.DELETE_TOPIC')}
                                    description={t('TRAININGS_PAGE.DELETE_TOPIC_DESCRIPTION')}
                                    cancelText={t('TRAININGS_PAGE.CANCEL')}
                                    confirmText={t('TRAININGS_PAGE.DELETE_TOPIC')}
                                    handleClose={() => setIsDeleteModalOpen(null)}
                                    handleSubmit={handleDeleteTopic}
                                    loading={deleteTopicLoading}
                                />
                            )}
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        {isEditing && (
                            <div className={styles.selectFileContainer}>
                                <Button variant='contained' className={styles.addAttachmentButton} onClick={() => attachmentInputRef.current.click()}>
                                    {t('TRAININGS_PAGE.UPDATE_ATTACHMENT')}
                                </Button>

                                <Typography variant='body2'>{t('TRAININGS_PAGE.SELECT_ATTACHMENTS_FILE_TYPES')}</Typography>
                            </div>
                        )}

                        {formik.values.attachments.length === 0 ? (
                            <Typography>{t('TRAININGS_PAGE.ADD_LEAST_ONE_ATTACHMENT')}</Typography>
                        ) : (
                            <ul className={styles.attachmentsList}>
                                {formik.values.attachments.map((file, index) => {
                                    const isAlreadyExistent = !!file.id;
                                    let fileData;
                                    
                                    if (isAlreadyExistent) {
                                        fileData = {
                                            id: file.id,
                                            name: file.metadata.name,
                                            iconName: String(file.metadata.type).startsWith('image') ? 'Image' : 'FileDoc',
                                        };
                                    } else {
                                        fileData = {
                                            index,
                                            id: `${generateUniqueId()}-${file.name}`,
                                            name: file.name,
                                            iconName: String(file.type).startsWith('image') ? 'Image' : 'FileDoc',
                                        };
                                    }
                                    
                                    return (
                                        <li key={fileData.id} className={styles.attachmentsItem}>
                                            <div className={styles.attachmentDetails}>
                                                <SvgIcon 
                                                    name={fileData.iconName} 
                                                    className={styles.attachmentIcon}
                                                />
                                                <Typography>{fileData.name}</Typography>
                                            </div>

                                            <IconButton onClick={() => setAttachmentToDelete({id: fileData.id, isAlreadyExistent, index})}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}

                        <input
                            ref={attachmentInputRef}
                            id='attachment' 
                            name='attachment'
                            type='file'
                            accept={attachmentsAcceptedFiles} 
                            className={styles.attachmentInput}
                            onChange={(event) => {
                                const file = event.target.files[0];
                                addAttachment(file);
                            }}
                        />
                    </Grid>
      
                    <Grid item xs={12}>
                        <LoadingButton 
                            fullWidth
                            type='submit'
                            variant='contained'
                            disabled={formik.values.attachments.length === 0 || !isEditing}
                            loading={isLoading}
                        >
                            {t('TRAININGS_PAGE.EDIT_TOPIC')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>

            {!!attachmentToDelete && (
                <ConfirmationModal
                    title={t('TRAININGS_PAGE.DELETE_ATTACHMENT')}
                    description={t('TRAININGS_PAGE.DELETE_ATTACHMENT_DESCRIPTION')}
                    cancelText={t('TRAININGS_PAGE.CANCEL')}
                    confirmText={t('TRAININGS_PAGE.DELETE_ATTACHMENT')}
                    loading={deleteAttachmentLoading}
                    handleClose={() => setAttachmentToDelete(null)}
                    handleSubmit={() => handleDeleteAttachment({
                        attachmentId: attachmentToDelete.id,
                        isAlreadyExistent: attachmentToDelete.isAlreadyExistent,
                        index: attachmentToDelete?.index,
                    })}
                />
            )}
        </Container>
    );
}

import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import getCroppedImg, {readFile, upload} from '@src/utils/image-utils';
import Dropzone from 'react-dropzone';
import Cropper from 'react-easy-crop';
import {Button, Modal, Slider} from '@mui/material';
import {useDispatch} from 'react-redux';
import {accountsApi, useLazyGetAccountLogoUploadUrlQuery} from '@src/modules/accounts/accounts.api';
import {LoadingButton} from '@mui/lab';

import SvgIcon from '../SvgIcon';
import LogoAvatar from '../LogoAvatar';

import styles from './logo-upload.module.scss';

const acceptedFiles = {
    'image/*': [],
};

function LogoUpload({accountId, defaultAvatar, onReceiveNewImage, onClose}) {
    const [file, setFile] = useState(null);
    const [activeStep, setActiveStep] = useState(0);

    const handleModalClose = () => {
        onClose?.();
        setActiveStep(0);
    };

    const handleReceiveNewImage = (data) => {
        onReceiveNewImage(data);
        setActiveStep(0);
    };

    return (
        <div>
            {activeStep === 0 &&
                <DropzoneStep
                    setFile={setFile}
                    avatar={defaultAvatar}
                    setActiveStep={setActiveStep}
                />
            }
            <Modal
                open={activeStep === 1}
                onClose={handleModalClose}
            >
                <div className={styles.modalContainer}>
                    <CropImage
                        accountId={accountId}
                        file={file}
                        setActiveStep={setActiveStep}
                        onClose={handleModalClose}
                        onReceiveNewImage={handleReceiveNewImage}
                />
                </div>
            </Modal>
        </div>
    );
}

const DropzoneStep = ({setFile, avatar, setActiveStep}) => {
    const {t} = useTranslation();

    const onDrop = async files => {
        const imageDataUrl = await readFile(files[0]);
        setFile(imageDataUrl);
        setActiveStep(1);
    };

    return (
        <div className={styles.uploadContainer}>
            <Dropzone onDrop={onDrop} accept={acceptedFiles} maxFiles={1}>
                {({getRootProps, getInputProps}) => (
                    <div className={styles.dropzone} {...getRootProps()}>
                        <LogoAvatar avatar={avatar} />
                        <span className={styles.dropText}>{t('UPLOAD_AVATAR.CLICK_TO_CHANGE')}</span>
                        <input {...getInputProps()} />
                    </div>
                  )}
            </Dropzone>
        </div>

    );
};

const CropImage = ({accountId, file, setActiveStep, onReceiveNewImage, onClose}) => {
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [getUrl] = useLazyGetAccountLogoUploadUrlQuery();
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);
    const [loadingUpload, setLoadingUpload] = useState(false);

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            setLoadingUpload(true);
            const croppedImage = await getCroppedImg(
                file,
                croppedArea,
                rotation,
                {width: 520, height: 520},
            );

            const params = {
                ContentType: croppedImage.type,
                ContentLength: croppedImage.size,
            };

            const data = await getUrl({id: accountId, params}).unwrap();
            await upload(data.url, data.fields, croppedImage);

            const fr = new FileReader();
            fr.readAsArrayBuffer(croppedImage);
            fr.onload = function () {
                const blob = new Blob([fr.result]);

                const url = URL.createObjectURL(blob, {type: 'image/png'});

                onReceiveNewImage(url);
                
                dispatch(
                    accountsApi.util.updateQueryData('getById', {id: accountId}, (draftAccount) => {
                        draftAccount.logoUrl = url;
                    }),
                );
            };

            onClose?.();
        } finally {
            setLoadingUpload(false);
        }

    }, [file, croppedArea, rotation, getUrl, accountId, dispatch, onReceiveNewImage, onClose, t]);

    const onZoomChange = (zoom) => {
        setZoom(zoom);
    };

    const changeRotation = () => {
        if (rotation === 360) {
            setRotation(90);
        } else {
            setRotation(rotation + 90);
        }

    };

    return (
        <div className={styles.cropContainer}>
            <div className={styles.header}>
                <button onClick={() => setActiveStep(0)}>
                    <SvgIcon name='Previous' />
                </button>
                <div className={styles.text}>
                    <span>{t('UPLOAD_AVATAR.SAVE_AND_TURN')}</span>
                </div>
                <button onClick={onClose}>
                    <SvgIcon name='CloseCircle' />
                </button>
            </div>
            <div className={styles.cropper}>
                <Cropper
                    image={file}
                    crop={crop}
                    cropShape='round'
                    showGrid={false}
                    aspect={1}
                    rotation={rotation}
                    zoom={zoom}
                    onCropChange={setCrop}
                    onZoomChange={onZoomChange}
                    onCropComplete={onCropComplete}
                    objectFit='vertical-cover'
              />
            </div>
            <div className={styles.controls}>
                <Slider
                    value={zoom}
                    min={1}
                    step={0.1}
                    max={3}
                    onChange={({target}) => onZoomChange(target.value)}
                    aria-label='Default'
                    valueLabelDisplay='auto'
              />
                <div className={styles.cropButtons}>
                    <LoadingButton
                        disabled={loadingUpload}
                        loading={loadingUpload}
                        variant='contained'
                        onClick={showCroppedImage}
                    >
                        {t('UPLOAD_AVATAR.SAVE_AS_PROFILE_PICTURE')}
                    </LoadingButton>
                    <Button onClick={changeRotation} startIcon={<SvgIcon name='Rotate' />} className={styles.rotateBtn}>
                        {t('UPLOAD_AVATAR.TURN')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default LogoUpload;

import React, {useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {
    Box,
    Container,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {FormikTextField} from '@src/components/FormikFields';
import {useCreateMutation, useUpdateExpiryDateMutation} from '@src/modules/accounts/accounts.api';
import {accountTypes, createDemoAccountSchema} from '@src/modules/accounts/schema';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import * as moment from 'moment';
import {DateTimePicker} from '@mui/x-date-pickers';
import cx from 'classnames';

import styles from './demo-account-create.module.scss';

function DemoAccountCreate() {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const [create, {isLoading: isCreating}] = useCreateMutation();
    const [
        updateExpiryDate,
    ] = useUpdateExpiryDateMutation();

    const [showPassword, setShowPassword] = useState(false);

    const formik = useFormik({
        validationSchema: createDemoAccountSchema(t),
        initialValues: {
            name: '',
            subdomain: '',
            mu: '',
            responsibleName: '',
            re: '',
            rp: '',
            active: '',
            created: '',
            modules: {
                climateCheck: false,
            },
            timezone: null,
            expiryDate: null,
        },
        onSubmit: async (form) => {
            try {
                const result = await create({type: accountTypes.trainings, data: form}).unwrap();
                await updateExpiryDate({id: result.id, body: moment(form.expiryDate).toISOString()});
                enqueueSnackbar(t('DEMO_ACCOUNTS_PAGE.CREATE_SUCCESS'), {variant: 'success'});
                navigate(routes.DEMO_ACCOUNTS);
            } catch (error) {
                if (error?.status === 404) {
                    enqueueSnackbar(t('DEMO_ACCOUNTS_PAGE.NO_TEMPLATE_FOUND'), {variant: 'error'});
                } else {
                    enqueueSnackbar(t('DEMO_ACCOUNTS_PAGE.CREATE_ERROR'), {variant: 'error'});
                }
            }
        },
    });
    const formSubmit = (e) => {
        e.preventDefault();
        formik.handleSubmit();
    };

    const toggleShowPassword = () => {
        setShowPassword(old => !old);
    };

    return (
        <Container maxWidth='xl'>
            <h1>{t('DEMO_ACCOUNTS_PAGE.CREATE_TITLE')}</h1>
            <form onSubmit={formSubmit} autoComplete='off'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormikTextField
                            fullWidth
                            name='name'
                            label={t('DEMO_ACCOUNTS_PAGE.FORM.NAME')}
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', gap: 2}}>
                            <FormikTextField
                                fullWidth
                                name='subdomain'
                                label={t('DEMO_ACCOUNTS_PAGE.FORM.SUBDOMAIN')}
                                formik={formik}
                                onKeyUp={() => formik.setFieldValue('subdomain', formik.values['subdomain'])}
                            />
                            <Tooltip title={
                                <ul>
                                    <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.LOWER_CASE')}</li>
                                    <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.SPACES')}</li>
                                    <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.HYPHENS')}</li>
                                    <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.SPECIAL_CHARACTER')}</li>
                                    <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.MARKERS')}</li>
                                </ul>
                            }>
                                <InfoOutlinedIcon />
                            </Tooltip>
                        </Box>
                        {formik.values['subdomain'].length > 0 && (
                            <FormHelperText>
                                {t(
                                    'DEMO_ACCOUNTS_PAGE.FORM.SUBDOMAIN_HELPER_TEXT',
                                    {subdomain: `${formik.values['subdomain']}-demo`},
                                )}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <DateTimePicker
                            name='expiryDate'
                            data-qa='expiry-date'
                            value={formik.values.expiryDate}
                            className={cx(styles.datePicker, {
                                [styles.pickError]: formik.errors.expiryDate,
                            })}
                            label={t('DEMO_ACCOUNTS_PAGE.FORM.EXPIRATION_DATE')}
                            onChange={(date) => formik.setFieldValue('expiryDate', date)}
                            minDateTime={moment().add(1, 'day')}
                        />
                        <div>
                            {formik.errors.expiryDate && (
                                <span className={styles.errorMessage}>{formik.errors.expiryDate}</span>
                            )}
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='h6' component='h6' mt={1} mb={2}>
                            {t('DEMO_ACCOUNTS_PAGE.TRAINER_DATA')}
                        </Typography>
                        <FormikTextField
                            fullWidth
                            name='responsibleName'
                            label={t('DEMO_ACCOUNTS_PAGE.FORM.TRAINER_NAME')}
                            formik={formik}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormikTextField
                            fullWidth
                            name='re'
                            label={t('DEMO_ACCOUNTS_PAGE.FORM.TRAINER_EMAIL')}
                            formik={formik}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormikTextField
                            fullWidth
                            formik={formik}
                            name='rp'
                            label={t('PASSWORD')}
                            type={showPassword ? 'text' : 'password'}
                            autoComplete='new-password'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={toggleShowPassword}
                                            edge='end'
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} mt={2}>
                        <LoadingButton
                            type='submit'
                            loading={isCreating}
                            variant='contained'
                            fullWidth
                        >
                            {t('DEMO_ACCOUNTS_PAGE.SAVE')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}

export default DemoAccountCreate;

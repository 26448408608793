import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {Container, Grid, IconButton, Tooltip} from '@mui/material';
import {FormikTextField} from '@src/components/FormikFields';
import {LoadingButton} from '@mui/lab';
import {topicTextSchema} from '@src/modules/trainings/schema';
import {invalidateTopics, useDeleteTopicMutation, useEditContentMutation, useEditTrainingTopicMutation} from '@src/modules/trainings/trainings.api';
import {ConfirmationModal} from '@src/components/ConfirmationModal';

import TextEditor from '../../../../../components/TextEditor';

import styles from './text-edit-form.module.scss';

export function TextEditForm({trainingId, topic, content, setActiveTopic}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleteTopicModalOpen, setIsDeleteModalOpen] = useState(false);
    
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const [editTopic] = useEditTrainingTopicMutation();
    const [editTextContent] = useEditContentMutation();
    const [deleteTopic, {isLoading: deleteTopicLoading}] = useDeleteTopicMutation();

    const initialValues = {
        title: '',
        value: '',
    };

    const formik = useFormik({
        validationSchema: topicTextSchema(t),
        initialValues,
        onSubmit: handleSubmit,
    });

    async function handleDeleteTopic() {
        try {
            await deleteTopic({
                trainingId,
                topicId: topic.id,
            }).unwrap();

            enqueueSnackbar(t('TRAININGS_PAGE.DELETE_TOPIC_SUCCESS'), {variant: 'success'});

            invalidateTopics();

            setIsDeleteModalOpen(false);
            setActiveTopic(null);
        } catch (error) {
            enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.DELETE_TOPIC_ERROR'), {variant: 'error'});
        } 
    } 

    async function handleSubmit(form) {
        setIsLoading(true);
        setIsEditing(false);
        try {
            const {title, value} = form;
            const topicResponse = await editTopic({
                trainingId,
                topicId: topic.id,
                title,
            }).unwrap();

            await editTextContent({
                trainingId,
                topicId: topic.id,
                contentId: content.id,
                body: {
                    type: 'text',
                    value,
                },
            }).unwrap();
        
            setActiveTopic(topicResponse);

            enqueueSnackbar(t('TRAININGS_PAGE.EDIT_TOPIC_SUCCESS'), {variant: 'success'});
        } catch (error) {
            enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.EDIT_ERROR'), {variant: 'error'});
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (content) {
            formik.setValues({
                title: topic.title,
                value: content.value,
            });
        }
    }, [content]);

    return (
        <Container maxWidth='md'>
            <div className={styles.form}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={styles.titleContainer}>
                            <FormikTextField
                                fullWidth
                                name='title'
                                label={t('TRAININGS_PAGE.TOPIC_TITLE')}
                                formik={formik}
                                disabled={!isEditing}
                            />

                            <Tooltip title={t(isEditing ? 'TRAININGS_PAGE.CANCEL' : 'TRAININGS_PAGE.EDIT_TOPIC')}>
                                <IconButton onClick={() => setIsEditing(prev => !prev)}>
                                    {isEditing ? <CloseIcon /> : <ModeEditIcon />}   
                                </IconButton>
                            </Tooltip>

                            {!isEditing && (
                                <Tooltip title={t('TRAININGS_PAGE.DELETE_TOPIC')}>
                                    <IconButton onClick={() => setIsDeleteModalOpen(true)}>
                                        <DeleteIcon /> 
                                    </IconButton>
                                </Tooltip>
                            )}

                            {isDeleteTopicModalOpen && (
                                <ConfirmationModal
                                    title={t('TRAININGS_PAGE.DELETE_TOPIC')}
                                    description={t('TRAININGS_PAGE.DELETE_TOPIC_DESCRIPTION')}
                                    cancelText={t('TRAININGS_PAGE.CANCEL')}
                                    confirmText={t('TRAININGS_PAGE.DELETE_TOPIC')}
                                    handleClose={() => setIsDeleteModalOpen(null)}
                                    handleSubmit={handleDeleteTopic}
                                    loading={deleteTopicLoading}
                                />
                            )}
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        {isEditing ?
                            <TextEditor 
                                name='value'
                                initialValue={content.value}
                                onChange={(value) => formik.setFieldValue('value', value)}
                            /> : 
                            <div className={styles.content} dangerouslySetInnerHTML={{__html: content.value}} />
                        }
                    </Grid>
                  
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth 
                            type='button'
                            variant='contained'
                            loading={isLoading}
                            disabled={!isEditing}
                            onClick={formik.handleSubmit}
                        >
                            {t('TRAININGS_PAGE.EDIT_TOPIC')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Container, Typography} from '@mui/material';
import {ImportSpreadsheet} from '@src/modules/importUsers/components/Steps/ImportSpreadsheet';
import {AnalyzeImport} from '@src/modules/importUsers/components/Steps/AnalyzeImport';
import VerifyImport from '@src/modules/importUsers/components/Steps/VerifyImport';
import {maxUserReachedMessage, translationUsersImport, translationsUserImportErros} from '@src/modules/importUsers/utils';
import {Stepper} from '@src/modules/importUsers/components/Stepper';
import {routes} from '@src/utils/routes';
import {MaximumUsersWarning} from '@src/modules/importUsers/components/MaximumUsersWarning';
import {invalidateUsersAmount, useGetAccountUsersQuery, useGetByIdQuery} from '@src/modules/accounts/accounts.api';
import {useParams} from 'react-router-dom';
import {useConfirmUsersImportMutation, useLazyGetUsersImportUrlQuery} from '@src/modules/importUsers/importUsers.api';
import {uploadDocument} from '@src/modules/uploads/uploadAttachment';
import {useSnackbar} from 'notistack';
import {asyncTaskStatus} from '@src/modules/async-tasks/utils';
import {useGetAsyncTasksQuery} from '@src/modules/async-tasks/async-tasks.api';
import {useDispatch} from 'react-redux';
import {accountTypes} from '@src/modules/accounts/schema';

import styles from './import-users.module.scss';

export default function ImportUsers() {
    const {t} = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const [importId, setImportId] = useState(null);

    const {accountId} = useParams();
    const dispatch = useDispatch();

    const {enqueueSnackbar} = useSnackbar();

    const {data: accountUsers} = useGetByIdQuery(
        {id: `${accountTypes.trainings}/${accountId}`},
        {skip: !accountId},
    );
    const {data: usersAmount} = useGetAccountUsersQuery({id: `${accountTypes.trainings}/${accountId}`}, {skip: !accountId});
    const [getUrl] = useLazyGetUsersImportUrlQuery();
    const [confirmImport] = useConfirmUsersImportMutation();

    const {data: importResponse} = useGetAsyncTasksQuery(
        {
            asyncTaskId: importId,
            accountId,
        },
        {skip: !importId, pollingInterval: (activeStep !== 1) ? 0 : 2000},
    );

    const importData = useCallback(async (usersData) => {
        setActiveStep(1);

        const params = {
            ContentType: usersData[0].type,
            ContentLength: usersData[0].size,
            ContentDisposition: `inline; filename="${usersData[0].name}"`,
        };

        try {
            const usersImportData = await getUrl({
                id: accountId,
                params,
            }).unwrap();

            await uploadDocument(usersImportData?.url, usersImportData?.fields, usersData[0], 'inline');

            const body = {
                filePath: usersImportData?.fields?.key,
            };

            const confirmImportData = await confirmImport({id: accountId, body}).unwrap();

            setImportId(confirmImportData?.id);

        } catch {
            enqueueSnackbar(t('IMPORT_USERS.ERROR'), {variant: 'error'});
            setActiveStep(0);
        }
    }, [getUrl, t]);

    useEffect(() => {
        if (importResponse?.status === asyncTaskStatus.done) {
            setActiveStep(2);
            dispatch(invalidateUsersAmount(`${accountTypes.trainings}/${accountId}`));
        } else if (importResponse?.status === asyncTaskStatus.error) {
            if (importResponse.data.detail === maxUserReachedMessage) {
                setActiveStep(0);
                enqueueSnackbar(
                    t(
                        translationsUserImportErros.account_max_active_users, 
                        {freeUsersAmount: accountUsers?.max_users - usersAmount?.active},
                    ),
                    {variant: 'error'},
                );
            } else {
                setActiveStep(0);
                enqueueSnackbar(t('IMPORT_USERS.ERROR'), {variant: 'error'});
            }
        }
    }, [importResponse, t]);

    const steps = useMemo(() => ({
        0: <ImportSpreadsheet
            importData={importData}
        />,
        1: <AnalyzeImport />,
        2: <VerifyImport 
            translationsImportErros={translationsUserImportErros}
            translationImportType={translationUsersImport}
            route={routes.DEMO_ACCOUNTS}
            logs={importResponse?.data}
            importData={importData}
            importStatus={importResponse?.status}
        />,
    }), [importResponse]);

    const stepNames = [
        t('IMPORT_USERS.IMPORT'),
        t('IMPORT_USERS.ANALYZE'),
        t('IMPORT_USERS.VERIFY'),
    ];

    return (
        <Container maxWidth='xl' className={styles.container}>
            <header className={styles.header}>
                <Typography variant='h4' component='h1'>
                    {t('IMPORT_USERS.NAME')}
                </Typography>
            </header>

            <main className={styles.main}>
                {usersAmount?.active >= accountUsers?.max_users && activeStep === 0 ? (
                    <MaximumUsersWarning />
                ) : (
                    <>
                        <Stepper activeStep={activeStep} steps={stepNames} />
                        {steps[activeStep]}
                    </>
                )}
            </main>
        </Container>
    );
}

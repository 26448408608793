const config = {
    basePath: process.env.REACT_APP_OFFLINE === 'true' ?
        `http://${process.env.REACT_APP_API_DOMAIN_OFFLINE}/admin` :
        `https://${process.env.REACT_APP_API_DOMAIN}/v1/admin`,
    clientId: process.env.REACT_APP_OFFLINE === 'true' ?
        process.env.REACT_APP_ADMIN_CLIENT_OFFLINE :
        process.env.REACT_APP_ADMIN_CLIENT,
    accessToken: '@token',
    refreshToken: '@refreshToken',
    appDomainMask: process.env.REACT_APP_APP_DOMAIN,
};

export default config;

import React, {useCallback, useEffect} from 'react';
import {Grid, Paper, Container, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import logo from '@assets/imgs/logo.svg';
import {useFormik} from 'formik';
import {FormikPasswordTextField} from '@components/FormikFields';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import {useResetPasswordMutation} from '@src/modules/auth/auth.api';
import {newPasswordSchema} from '@src/modules/auth/schema';
import {useSnackbar} from 'notistack';
import {useSelector} from 'react-redux';

import styles from './insert-password.module.scss';

export default function InsertPassword() {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const session = useSelector((state) => state.auth.session);

    const [changePassword, {isLoading, isSuccess, data, error}] = useResetPasswordMutation();

    const handleSetPassword = useCallback((data) => {
        if (!session) {
            enqueueSnackbar(t('SESSION_EXPIRED'), {variant: 'error'});
            navigate(routes.FORGET_PASSWORD);
        } else {
            changePassword({session, password: data.password});
        }
        
    }, []);

    useEffect(() => {
        if (isSuccess) {
            if (data.state === 'success') {
                enqueueSnackbar(t('PASSWORD_CHANGE'), {variant: 'success'});
                navigate(routes.LOGIN);
            }
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            switch (error.status) {
            case 404:
                enqueueSnackbar(t('SESSION_EXPIRED'), {variant: 'error'});
                navigate(routes.FORGET_PASSWORD);
                break;
            default:
                enqueueSnackbar(t('CHANGE_PASSWORD_ERROR'), {variant: 'error'});
            }
            
        }
    }, [error]);

    const formik = useFormik({
        validationSchema: newPasswordSchema(t),
        initialValues: {
            password: '',
            confirmPassowrd: '',
        },
        onSubmit: handleSetPassword,
    });

    const formSubmit = e => {
        e.preventDefault();
        formik.handleSubmit();
    };

    return (
        <Container maxWidth='xs' className={styles.container}>
            <img src={logo} />
            <Paper className={styles.paper}>
                <Typography textAlign='center' className={styles.title}>
                    {t('NEW_PASSWORD')}
                </Typography>
                <Typography textAlign='center' color='textSecondary' className={styles.subtitle}>
                    {t('CREATE_NEW_PASSWORD')}
                </Typography>
                <form onSubmit={formSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormikPasswordTextField
                                fullWidth
                                name='password'
                                label={t('NEW_PASSWORD')}
                                formik={formik}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikPasswordTextField
                                fullWidth
                                name='confirmPassword'
                                label={t('CONFIRM_NEW_PASSWORD')}
                                formik={formik}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                type='submit'
                                loading={isLoading}
                                variant='contained'
                            >
                                {t('REQUEST')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container> 
    );
}

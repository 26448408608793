import {useGetMeQuery} from '@src/modules/admins/admins.api';
import {AuthSelectors} from '@src/modules/auth/auth.reducer';
import {useSelector} from 'react-redux';

export const useCan = () => {
    const loggedIn = useSelector(AuthSelectors.isLoggedIn);
    const {data: loggedUser} = useGetMeQuery(null, {skip: !loggedIn});

    const can = (permission, action) => {

        if (!loggedUser?.permissions) {
            return true;
        }

        if (loggedUser?.permissions[permission]?.includes('manage')) {
            return true;
        }

        if (loggedUser?.permissions[permission]?.includes(action)) {
            return true;
        }

        return false;
    };

    return can;
};

import React from 'react';
import AccountResources from '@src/modules/accounts/components/AccountResources';

export function ModulesCreateTab({formik}) {

    return (
        <>
            <AccountResources formik={formik} />
        </>
    );
}

export const asyncTaskStatus = {
    created: 'created',
    done: 'done',
    progress: 'progress',
    error: 'error',
};

export const reportFormat = {
    xlsx: 'xlsx',
    json: 'json',
};

export const importStatus = {
    done: 'done',
    error: 'error',
};

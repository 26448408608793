import React, {useEffect, useState} from 'react';
import {
    Box,
    CircularProgress,
    Container,
} from '@mui/material';
import {
    useGetByIdQuery,
    useGetAccountUsersQuery,
} from '@src/modules/accounts/accounts.api';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {timezonesAll} from '@src/modules/accounts/utils';
import * as moment from 'moment';

import styles from './account-edit.module.scss';
import {EditForm} from './components/EditForm';

export default function DemoAccountEdit(){

    const [initialValues, setInitialValues] = useState(null);
    const {t} = useTranslation();
    const {id} = useParams();

    const {data} = useGetByIdQuery(
        {id: `trainings/${id}`},
        {skip: !id},
    );

    const {data: accountUsers} = useGetAccountUsersQuery({id: `trainings/${id}`}, {skip: !id});

    useEffect(() => {
        if (!!id && data && accountUsers) {
            const {
                name,
                subdomain,
                max_users,
                responsible,
                modules,
                timezone,
                expiry_date,
                disabled,
            } = data;

            const selectedTimezone = timezonesAll.find((option) => option.value === timezone);

            setInitialValues({
                name: name,
                subdomain,
                mu: max_users,
                responsible: {
                    name: responsible?.name,
                },
                re: responsible ? responsible?.email : '',
                active: accountUsers?.active ? accountUsers?.active : '',
                created: accountUsers?.created ? accountUsers?.created : '',
                modules: {
                    climateCheck: modules?.climateCheck,
                },
                timezone: selectedTimezone,
                expiryDate: expiry_date ? moment(expiry_date) : null,
                disabled,
            });
        }
    }, [data, id, accountUsers]);
    
    return (
        <Container maxWidth='xl'>
            <Box className={styles.header}>
                <h1>{t('ACCOUNTS_PAGE.EDIT_TRAINING_TITLE')}</h1>
            </Box>

            {initialValues ? (
                <EditForm initialValues={initialValues} logoUrl={data?.logoUrl} />
            ) : (
                <Box
                    sx={{
                        height: 120,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >   
                    <CircularProgress color='inherit' />
                </Box>
            )}
        </Container>
    );
}

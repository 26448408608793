import cx from 'classnames';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {LoadingButton} from '@mui/lab';
import {Edit} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {Box, Button, CircularProgress, Container, Grid} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import PageHeader from '@src/components/PageHeader';
import {FormikTextField} from '@src/components/FormikFields';
import {CategoryTagsField} from '@src/components/CategoryTagsField';
import {trainingsSchema} from '@src/modules/trainings/schema';
import {MAX_DESCRIPTION_LENGTH} from '@src/modules/trainings/utils';
import {useEditTrainingMutation, useGetTrainingQuery, useGetTrainingsQuery, useLazyGetTrainingThumbnailUrlQuery} from '@src/modules/trainings/trainings.api';
import {routes} from '@src/utils/routes';
import {uploadImage} from '@src/modules/uploads/uploadImage';
import {blobToBase64, readFile} from '@src/utils/image-utils';
import Modal from '@src/components/Modal';
import {AccountsSelect} from '@src/modules/trainings/components/AccountsSelect';
import {useCan} from '@src/hooks/useCan';
import {permissionTypes, permissionResources} from '@src/hooks/usePrivateRoutes';

import {ImageCropper} from '../../../modules/trainings/components/ImageCropper';

import styles from './trainings-edit.module.scss';

export default function TrainingsEdit() {
    const [tags, setTags] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fileToCrop, setFileToCrop] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {trainingId} = useParams();
    const inputThumbnailRef = useRef(null);
    const {enqueueSnackbar} = useSnackbar();

    const {
        data: training,
        isLoading: isTrainingLoading,
    } = useGetTrainingQuery({trainingId}, {skip: !trainingId});
    const [editTraining] = useEditTrainingMutation();
    const [getUploadThumbnailUrl] = useLazyGetTrainingThumbnailUrlQuery();
    const {data: trainingsData} = useGetTrainingsQuery();
    const can = useCan();
    
    const listCategories = trainingsData?.map(value => value.categories);

    const tagsArrayMerge = useMemo(() => {
        if (listCategories){
            return listCategories.flat();
        }
    }, [listCategories]);

    const tagsNames = {};

    tagsArrayMerge?.forEach(item => {
        const lowerItem = item?.toLowerCase();
        const FormattedName = lowerItem?.charAt(0)?.toUpperCase() + lowerItem?.slice(1);
        tagsNames[FormattedName] = true;
    });

    const tagsOptions = Object.keys(tagsNames);

    const initialValues = {
        title: '',
        description: '',
        thumbnail: '',
        categories: [],
        allowedAccounts: [],
    };

    const formik = useFormik({
        validationSchema: trainingsSchema(t),
        initialValues,
        onSubmit: handleSubmit,
    });

    async function addFileToCrop(file) {
        if (file) {
            const fileUrl = await readFile(file);
            setFileToCrop(fileUrl);
        } else {
            setFileToCrop(null);
        }
    }

    async function uploadThumbnail({trainingId, thumbnail, getUploadData}) {
        try {
            const data = await getUploadData({
                trainingId,
                params: {
                    ContentType: thumbnail.type,
                    ContentLength: thumbnail.size,                
                },
            }).unwrap();
    
            await uploadImage({
                url: data.url,
                fields: data.fields,
                file: thumbnail,
            });
    
            return data;
        } catch (error) {
            throw new Error('TRAININGS_PAGE.THUMBNAIL_ERROR');
        }
    }

    function formSubmit(e)  {
        e.preventDefault();
        formik.handleSubmit();
    }

    async function handleSubmit(form) {
        setIsLoading(true);
        setEditMode(false);
        try {
            let base64Thumb = undefined;
            if (typeof form.thumbnail !== 'string') {
                await uploadThumbnail({
                    trainingId: training.id,
                    thumbnail: form.thumbnail,
                    getUploadData: getUploadThumbnailUrl,
                });
                base64Thumb = await blobToBase64(form.thumbnail);
            }
            
            const formattedCategories = form.categories.map(category => category?.toUpperCase());

            const body = {
                ...form,
                categories: formattedCategories,
            };

            delete body.thumbnail;
        
            await editTraining({trainingId: training.id, body, image: base64Thumb}).unwrap();
        
            enqueueSnackbar(t('TRAININGS_PAGE.EDIT_SUCCESS'), {variant: 'success'});

            navigate(routes.TRAININGS);
        } catch (error) {
            enqueueSnackbar(t(error.message || 'TRAININGS_PAGE.EDIT_ERROR'), {variant: 'error'});
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (training) {
            formik.setValues({
                title: training.title ?? '',
                description: training.description ?? '',
                thumbnail: training.thumbnail ?? '',
                categories: training.categories ?? [],
                allowedAccounts: training.allowedAccounts ?? [],
            });

            setThumbnailUrl(training.thumbnail);
            setTags(training.categories);
        }
    }, [training]);

    return (
        <Container maxWidth='xl'>
            <PageHeader
                title='TRAININGS_PAGE.EDIT'
                textTooltip={t('TRAININGS_PAGE.BACK')}
                toBack={() => navigate(routes.TRAININGS)} 
            />
            {isTrainingLoading ? ( 
                <Box sx={{height: 120, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <CircularProgress color='inherit' />
                </Box>
            ) : (
                <main>
                    <Container maxWidth='sm'>
                        <form onSubmit={formSubmit} className={styles.form}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormikTextField
                                        fullWidth
                                        name='title'
                                        label={t('TRAININGS_PAGE.TRAINING_TITLE')}
                                        formik={formik}
                                        disabled={!editMode}
                                    />
                                </Grid>
                            
                                <Grid item xs={12}>
                                    <FormikTextField
                                        name='description'
                                        label={t('TRAININGS_PAGE.DESCRIPTION')}
                                        formik={formik}
                                        fullWidth
                                        multiline
                                        rows={6}
                                        maxLength={MAX_DESCRIPTION_LENGTH}
                                        disabled={!editMode}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <CategoryTagsField
                                        name='categories'
                                        label={t('TRAININGS_PAGE.CATEGORIES')}
                                        formik={formik} 
                                        tags={tags}
                                        setTags={setTags}
                                        tagsOptions={tagsOptions}
                                        disabled={!editMode}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <AccountsSelect 
                                        formik={formik}
                                        disabled={!editMode}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <div 
                                        className={cx(
                                            styles.mediaInputContainer, {
                                                [formik.touched?.thumbnail && formik.errors?.thumbnail]: styles.mediaInputError,
                                            },
                                        )}
                                    >
                                        <label htmlFor='thumbnail' className={cx(styles.thumbnailLabel, {[styles.disabled]: !editMode})}>
                                            {thumbnailUrl ? 
                                                <img src={thumbnailUrl} className={styles.thumbnailImage} /> : 
                                                <>
                                                    <CameraAltOutlinedIcon sx={{fontSize: '2rem'}} />
                                                    {t('TRAININGS_PAGE.SELECT_THUMBNAIL')}
                                                </>
                                            }
                                        </label>
                                        <input
                                            ref={inputThumbnailRef}
                                            className={styles.thumbnailInput}
                                            type='file'
                                            id='thumbnail'
                                            name='thumbnail'
                                            accept='.png, .jpeg, .jpg'
                                            disabled={!editMode}
                                            onClick={(event) => {
                                                event.target.value = null;
                                                setThumbnailUrl(null);
                                                setFileToCrop(null);
                                                formik.setFieldValue('thumbnail', null);
                                            }}
                                            onChange={(event) => {
                                                const file = event.currentTarget.files[0];
                                                addFileToCrop(file);
                                            }}
                                        />
                                    </div>
                                </Grid>

                                {can(permissionResources.trainings, permissionTypes.edit) && <Grid item xs={12} mt={2}>
                                    {editMode ?
                                        <div className={styles.buttons}>
                                            <Button
                                                fullWidth
                                                variant='outlined'
                                                type='button'
                                                onClick={() => setEditMode(false)}
                                            >
                                                {t('TRAININGS_PAGE.CANCEL')}
                                            </Button>
                                            <LoadingButton
                                                fullWidth
                                                type='submit'
                                                loading={isLoading}
                                                variant='contained'
                                            >
                                                {t('TRAININGS_PAGE.SAVE')}
                                            </LoadingButton> 
                                        </div> :
                                        <LoadingButton 
                                            fullWidth
                                            startIcon={<Edit />} 
                                            variant='outlined' 
                                            color='secondary' 
                                            onClick={() => setEditMode(true)}
                                            loading={isLoading}
                                        >
                                            {t('TRAININGS_PAGE.EDIT_MODE')}
                                        </LoadingButton>
                                    }
                                </Grid>}
                            </Grid>
                        </form>
                    </Container>
                </main>
            )}
            {!!fileToCrop && (
                <Modal
                    title={t('TRAININGS_PAGE.CROP_THUMBNAIL')}
                    modalWidth={500}
                    onClose={() => {
                        setFileToCrop(null);
                        setThumbnailUrl(null);
                    }}
                >
                    <ImageCropper
                        onClose={() => {
                            setFileToCrop(null);
                            setThumbnailUrl(null);
                            formik.setFieldValue('thumbnail', null);
                        }}
                        setNewImageUrl={(value) => {
                            setFileToCrop(null);
                            setThumbnailUrl(value);
                        }}
                        setNewImageFile={(value) => {
                            setFileToCrop(null);
                            formik.setFieldValue('thumbnail', value);
                        }}
                        file={fileToCrop}
                    />
                </Modal>
            )}
        </Container>        
    );
}
